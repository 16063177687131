export function formatDateTime(dateString: string, offsetHours: number = 5.5): string {
    // If there's no timezone info, assume UTC by appending "Z"
    if (!dateString?.match(/(Z|[+-]\d{2}:\d{2})$/)) {
      dateString += 'Z';
    }
    
    const date = new Date(dateString);
    
    if (isNaN(date?.getTime())) {
      console.error('Invalid date string:', dateString);
    }
  
    // Adjust the time by the offset (in milliseconds)
    const adjustedTime = new Date(date?.getTime() + offsetHours * 60 * 60 * 1000);
  
    // Use UTC getters on the adjusted time to get consistent values
    let hours = adjustedTime?.getUTCHours();
    const minutes = adjustedTime?.getUTCMinutes().toString().padStart(2, '0');
    const ampm = hours < 12 ? 'AM' : 'PM';
    
    // Convert to 12-hour format (with 12 instead of 0)
    hours = hours % 12 || 12;
    
    const day = adjustedTime?.getUTCDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[adjustedTime?.getUTCMonth()];
    const year = adjustedTime?.getUTCFullYear();
    
    // Function to determine the correct day suffix
    const daySuffix = (d: number) => {
      if (d > 3 && d < 21) return 'th';
      switch (d % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
  
    return `${hours}:${minutes} ${ampm}, ${day}${daySuffix(day)} ${month} ${year}`;
  }
  