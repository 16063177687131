import React, {
  lazy,
  Suspense,
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  Box,
  Text,
  Slide,
  Skeleton,
  HStack,
  IconButton,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useSwipeable } from "react-swipeable";
import ReactPlayer from "react-player";
import PlacesTabs from "./PlaceTabs";
import useAxios from "../../hooks/useAxios";
import { useLocation, useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { fetchEntityDetails } from "../../services/apiService";
import { ToastBucketList } from "../ToastBucketList";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import ToggleTip from "../ToggleTip/ToggleTip";
import * as amplitude from '@amplitude/analytics-browser';
import LazyLoad from "react-lazyload";
// import { CSSTransition, TransitionGroup } from 'react-transition-group';
// import './StoryCarousel.css'; // Import the CSS file for transitions
import { motion } from "framer-motion";
import { getUserLocation } from "../../utilities/getUserLocation";
import StoryProgress from "./StoryProgress";
import useSmartNavigate from "../../hooks/useSmartNavigate";

interface Story {
  title: string;
  city: string;
  country: string;
  continent: string;
  location: {
    lat: number;
    long: number;
  };
  image_attributions?: any;
  description: string;
  images?: string[];
  videos?: string[];
  tags: string[];
  otherDetail?: any[];
  static_fields?: any[];
  follow_on_queries?: any;
  google_maps_url?: string;
  social_links?: any;
  booking_details?: any;
  travel_destination?: any;
  identity_tag?: any;
  place_metadata_id?: any;      
}
const defaultStories: Story[] = [
  {
    title: "",
    city: "",
    country: "",
    continent: "",
    location: { lat: 0, long: 0 },
    description: "",
    tags: ["", ""],
    images: [""],
  },
];

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

const LocationDetail = lazy(() => import("./LocationDetail"));

const StoryCarousel: React.FC = () => {
  const abortControllerRef = useRef<AbortController | null>(null);
  const preventAbortRef = useRef(false);
  const myAppUrl = process.env.REACT_APP_URL;
  const context: any = useContext(AppContext);
  const { fetchData } = useAxios();
  const apiUrl = process.env.REACT_APP_API_URL;
  const { likeEntities, setLikeEntities,
    setIsCollection,
    setCollectionLoading,
    stryIndex,
    setStryIndex,
    shareModal, collectionLoading, entitesDetail, setCollectionContent, setEntitesDetail, influencerData, setInfluencerData, setStoryState, storyState, userDetail, collectionContent } =
    context;
  const [storyIndex, setStoryIndex] = useState(stryIndex ?? 0);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [showNewPage, setShowNewPage] = useState(false);
  const [stories, setStories] = useState<Story[]>(defaultStories);
  const [key, setKey] = useState(0);
  const router = useNavigate();
  const navigate = useSmartNavigate();
  const routerLocation = useLocation();
  const [isLiked, setIsLiked] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showAddedToBucket, setShowAddedToBucket] = useState(false);
  const [swipeDirection, setSwipeDirection] = useState<string>('left');

  const renderMedia = () => {
    if (isImageStory) {
      return (
        <LazyLoad>

          {stories.map((story, index) => (story?.images?.map((image, i) => (
            <Image src={image} alt="story" visibility={"hidden"} w={0} h={0} />
          ))))}

        </LazyLoad>
      );
    } else if (isVideoStory) {
      return <video src={currentStory.videos![mediaIndex]} controls />;
    }
    return null;
  };
  const convertToStories1 = (item: any, image: any): any => {
    return {
      title: item.title,
      city: item.city,
      country: item.country,
      continent: item.continent,
      location: item?.location ?? { lat: 0, long: 0 },
      description: item.description,
      //changes
      images: item.images ? (item.images.length > 0 ? [image, ...item.images] : [item.image]) : [""],
      videos: undefined,
      image_attributions: item.image_attributions ?? undefined,
      tags: item.tags,
      static_fields: item?.static_fields,
      travel_destination: item?.travel_destination,
      otherDetail: item.dynamic_fields,
      follow_on_queries: item?.follow_on_queries,
      google_maps_url: item?.google_maps_url,
      social_links: item?.social_links,
      booking_details: item?.booking_details,
      identity_tag: item?.identity_tag,
      place_metadata_id: item?.place_metadata_id,     
    };
  };
  useEffect(() => {
    if (storyIndex) {
      setStryIndex(storyIndex);
    }
    // eslint-disable-next-line
  }, [storyIndex]);

  useEffect(() => {
    const handlePopState = () => {
      // When the user navigates back, set flag to prevent aborting the fetch request.
      preventAbortRef.current = true;
    };

    const handleBeforeUnload = () => {
      // When the user navigates away, set flag to prevent aborting the fetch request.
      preventAbortRef.current = true;
    };

    window.addEventListener('popstate', handlePopState);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);


  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  // Decode and parse "allEntities"
  const queryAllEntities = params.get("allEntities")
    ? JSON.parse(decodeURIComponent(params.get("allEntities") || ""))
    : [{ title: "", images: "" }];

  const queryContxt = params.get("context") ? JSON.parse(decodeURIComponent(params.get("context") || "")) : {};



  const initialEntityDetail = location.state?.initialEntityDetail ?? storyState?.initialEntityDetail ?? undefined;

  const contxt = location.state?.context ?? queryContxt ?? {};
  const str = location.state?.stories ?? undefined
  useEffect(() => {
    if (!storyState && location.state)
      setStoryState(location.state);
    console.log("storyState, ", storyState);
    // eslint-disable-next-line
  }, [location.state])

  // eslint-disable-next-line
  const allEntities = location.state?.allEntities ?? queryAllEntities ?? storyState?.allEntities ?? [{ title: "", images: "" }];
  const [loadingStates, setLoadingStates] = useState<boolean[]>(Array(allEntities.length).fill(true));

  const likeButtonRef = useRef<HTMLButtonElement>(null);
  const shareButtonRef = useRef<HTMLButtonElement>(null);
  const likeAndShareRef = useRef<HTMLDivElement>(null);
  const iButtonRef = useRef<HTMLDivElement>(null);

  const currentStory = stories[storyIndex] || defaultStories[0];
  const isVideoStory = currentStory.videos !== undefined;
  const isImageStory = currentStory.images !== undefined;

  const currentMedia = isVideoStory
    ? currentStory.videos![mediaIndex]
    : isImageStory
      ? currentStory.images![mediaIndex]
      : undefined;

  const handleNextMedia = () => {
    if (isVideoStory && currentStory.videos!.length - 1 > mediaIndex) {
      setMediaIndex(mediaIndex + 1);
    } else if (isImageStory && currentStory.images!.length - 1 > mediaIndex) {
      setMediaIndex(mediaIndex + 1);
    } else {
      handleNextStory();
    }
  };

  const handleNextStory = () => {
    setSwipeDirection('left');
    if (storyIndex < allEntities?.length - 1) {
      setStoryIndex(storyIndex + 1);
    } else {
      setStoryIndex(0); // Loop back to the first story
    }
    setMediaIndex(0);
  };

  const handlePreviousStory = () => {
    setSwipeDirection('right');
    if (storyIndex > 0) {
      setStoryIndex(storyIndex - 1);
      setMediaIndex(0);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (Math.abs(eventData.deltaX) > 50) { // Customize sensitivity here
        handleNextStory();
      }
    },
    onSwipedRight: (eventData) => {
      if (Math.abs(eventData.deltaX) > 50) { // Customize sensitivity here
        handlePreviousStory();
      }
    },
    onSwipedUp: (eventData) => {
      if (Math.abs(eventData.deltaY) > 50) { // Customize sensitivity here
        setShowNewPage(true);
      }
    },
    onTap: (event) => {
      // Access native event's target
      const target = event.event.target as Node;

      // Prevent onTap if the target is the like or share button
      if (
        (likeButtonRef.current && likeButtonRef.current.contains(target)) ||
        (shareButtonRef.current && shareButtonRef.current.contains(target)) ||
        (iButtonRef.current && iButtonRef.current.contains(target)) ||
        (likeAndShareRef.current && likeAndShareRef.current.contains(target))
      ) {
        return;
      }
      handleNextMedia();
    },
    // trackMouse: false,
    delta: 50, // Minimum swipe distance to trigger an action
    preventScrollOnSwipe: true, // Prevent scrolling while swiping
  });


  const handleTabClick = (index: number) => {
    setStoryIndex(index);
    setMediaIndex(0);
    setStryIndex(index);

  };
  const fetchColelction = async (query: any) => {

    setIsCollection(true);
    setCollectionLoading(true);
    const user_loc = getUserLocation().catch(() => null);
    fetch(apiUrl + "v3/collection/stream", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        title: query,
        user_detail: userDetail,
        is_collection_card: location.state?.isCollectionCard ?? false,
        token: localStorage.getItem("token"),
        user_loc: user_loc,
      })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response;
      })
      .then(response => {
        if (!response.body) {
          throw new Error('Response body is null');
        }
        const reader = response.body.getReader();
        let buffer = ""
        const readChunk = () => {
          reader.read().then(async ({ value }) => {
            const chunk = new TextDecoder('utf-8').decode(value);
            buffer += chunk;

            if (buffer === "🥚") {
              console.log('Done reading stream');
              return;
            }

            if (buffer.indexOf("✅") !== -1) {
              let ind = buffer.indexOf("✅")
              let comp_json = buffer.slice(0, ind)
              buffer = buffer.slice(ind + 1)

              console.log(`Received chunk: ${comp_json}`);
              const message = JSON.parse(comp_json);

              if (message.entities) {
                await setCollectionContent(Array(allEntities?.length).fill(defaultStories));
                await setInfluencerData(Array(allEntities.length).fill([]));
              }
              if (message.entities_details) {
                setCollectionContent((prevContent: any) => {
                  // Create a copy of the previous state
                  const updatedContent = [...prevContent];

                  // Update only the specific indices for matching titles
                  allEntities.forEach((entity: any, index: number) => {
                    const matchingDetail = message.entities_details.find(
                      (detail: any) => detail.title === entity.title
                    );
                    if (matchingDetail) {
                      updatedContent[index] = convertToStories1(matchingDetail.entity_detail, entity.images);
                      entity = { ...entity, location: matchingDetail?.entity_detail?.location };
                      // setLoadingStates((prevLoadingStates) =>
                      //   prevLoadingStates.map((state, i) =>
                      //     i === index ? false : state
                      //   )
                      // );
                      console.log(loadingStates);
                    }
                  });

                  return updatedContent; // Return the updated state
                });
              }
            }
            readChunk();
          });
        };
        readChunk();
      })
      .catch((error) => {
        console.error('Error fetching stream:', error);
      });
  };

  useEffect(() => {
    const iscollectionLoading = location.state?.iscollectionLoading ?? false;
    const query = location.state?.query ?? "";
    if (!collectionLoading && iscollectionLoading) {
      fetchColelction(query);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const initialIndex = location.state?.clickedIndex ?? 0;
    const iscollectionLoading = location.state?.iscollectionLoading ?? false;
    if (!iscollectionLoading) {
      if (!str) {
        if (allEntities) {

          const newLoadingStates = Array(allEntities.length).fill(true);
          newLoadingStates[initialIndex] = false;
          setLoadingStates(newLoadingStates);

          const formatStory = (entityDetail: any, images: any) => ({
            title: entityDetail.title,
            city: entityDetail.city,
            country: entityDetail.country,
            continent: entityDetail.continent,
            location: entityDetail.location,
            travel_destination: entityDetail.travel_destination,
            image_attributions: entityDetail.image_attributions ?? undefined,
            static_fields: entityDetail?.static_fields,
            description: entityDetail.description,
            images: entityDetail.images ? (entityDetail.images.length > 0 ? [images, ...entityDetail.images] : [images, entityDetail.image]) : [""],
            tags: entityDetail.tags,
            otherDetail: entityDetail.dynamic_fields ?? entitesDetail.otherDetail,
            follow_on_queries: entityDetail?.follow_on_queries,
            google_maps_url: entityDetail?.google_maps_url,
            social_links: entityDetail?.social_links,
            booking_details: entityDetail?.booking_details,
            identity_tag: entityDetail?.identity_tag,
            place_metadata_id: entityDetail?.place_metadata_id, 
          });

          // console.log("allEntities ", allEntities);
          // console.log("initialEntityDetail ", JSON.stringify(initialEntityDetail.entity));

          console.log("initialIndex ", initialIndex);
          setStoryIndex(initialIndex);
          let newStories = Array(allEntities?.length).fill(defaultStories);
          if (initialEntityDetail) {
            console.log("ting ting ting")
            const initialStory = formatStory(initialEntityDetail, allEntities[initialIndex].images);
            newStories[initialIndex] = initialStory;
          }
          setStories(newStories);



          const fetchOtherTitles = async () => {
            console.log("infetch detail");
            const currDetail = Array(allEntities?.length).fill(defaultStories);
            const updatedStories = [...stories];
            try {
              const fetchPromises = allEntities?.map(
                (entity: any, index: number) => {
                  // Check if the current story already has a title
                  if (
                    location.state?.videoHighlight || (initialEntityDetail?.title === allEntities[index]?.title)) {
                    console.log("index already fetched: ", index);
                    const formattedStory = formatStory(initialEntityDetail, entity.images);
                    updatedStories[index] = formattedStory;
                    currDetail[index] = formattedStory;
                    setStories([...updatedStories]);
                    setEntitesDetail(currDetail);
                    const location = initialEntityDetail?.location;
                    allEntities[index] = { ...allEntities[index], location };

                    setLoadingStates((prevLoadingStates) =>
                      prevLoadingStates.map((state, i) =>
                        i === index ? false : state
                      )
                    );
                    return Promise.resolve(stories[index]); // Already loaded, resolve immediately
                  } else {
                    // Initiate the API call for the current entity's title
                    return fetchEntityDetails(
                      entity?.title,
                      userDetail,
                      entity?.city ?? "",
                      entity?.country ?? "",
                      contxt,
                      entity?.entity_id ?? "",
                      entity?.place_metadata_id ?? "",
                      entity?.travel_destination ?? ""

                    )
                      .then((response) => {
                        // console.log("index already fetched: ", initialEntityDetail);
                        // console.log("index already fetched: now", stories[index]);
                        // if(index===initialIndex){
                        //   console.log("hehehhee ");
                        // }
                        if (response.status === 200) {
                          // If the response is successful, format the story data
                          const formattedStory = formatStory(response.data.entity, entity.images);
                          // Update the corresponding index in the copied stories array
                          updatedStories[index] = formattedStory;
                          currDetail[index] = formattedStory;
                          setStories([...updatedStories]);
                          setEntitesDetail(currDetail);
                          const location = response?.data?.entity?.location;
                          allEntities[index] = { ...allEntities[index], location };
                          console.log("hey ye: ", allEntities);

                          setLoadingStates((prevLoadingStates) =>
                            prevLoadingStates.map((state, i) =>
                              i === index ? false : state
                            )
                          );
                          console.log("index fetched: ", index);
                        }
                      })
                      .catch(() => {
                        // In case of an error during the API call, handle it here
                        // Optional: add a fallback or default story data if needed
                      });
                  }
                }
              );

              // Execute all promises independently and log the result
              Promise.allSettled(fetchPromises).then((results) => {
                console.log("All fetch operations have settled:", results);
              });
            } catch (error) {
              console.log(error);
            }
          };

          const curr = entitesDetail;

          if (curr.length > 0) {
            console.log("entity detail is ", entitesDetail);
            console.log("entity detail iieoeoons ", curr);



            // If all titles match, update the stories
            setStories(entitesDetail);
            setLoadingStates(Array(allEntities.length).fill(false));

          }
          else {
            setEntitesDetail([]);
            console.log("entity detail in else is ", entitesDetail);
            console.log("entity detail iieoeoons ", curr);

            fetchOtherTitles();
          }
        }
      }
      else {
        setStories(str);
      }
    }
    else {
      setStories(collectionContent);
      allEntities.forEach((entity: any, index: number) => {
        const location = collectionContent[index]?.location;
        if (location) {
          entity.location = location;
          console.log("prest", entity)
        }
      });

    }
    // eslint-disable-next-line
  }, [JSON.stringify(allEntities.map(({ location, ...rest }: { location?: unknown } & Record<string, any>) => rest)), initialEntityDetail, collectionContent]);

  // TypeScript
  useEffect(() => {
    const fetchVideos = async () => {
      if (!allEntities || allEntities.length === 0) return;
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      const { signal } = abortControllerRef.current;

      // Initialize the state with an empty 2D array
      setInfluencerData(Array(allEntities.length).fill([]));
      const isCollectionCard = location.state?.isCollectionCard ?? undefined;
      const user_loc = await getUserLocation().catch(() => null);
      allEntities.forEach(async (entity: any, index: number) => {
        try {
          const city = location.state?.city ?? entity?.city ?? "";
          const country = location.state?.country ?? entity?.country ?? "";

          const body = {
            query: {
              title: entity?.search_query ?? entity?.title + " " + city + " " + country,
              context: contxt ?? {}
            },
            context: {
              user: { details: userDetail?.traveler_details }
            },
            is_collection_card: isCollectionCard ?? undefined,
            user_loc: user_loc,
          };
          fetch(apiUrl + "v2/video-highlights/stream", {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(body),
            signal
          })
            .then(response => {
              if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
              }
              return response;
            })
            .then(response => {
              if (!response.body) {
                throw new Error('Response body is null');
              }
              const reader = response.body.getReader();
              let buffer = "";
              const readChunk = () => {
                reader.read().then(({ value }) => {
                  const chunk = new TextDecoder('utf-8').decode(value);
                  buffer += chunk;
                  if (buffer === "🥚") {
                    console.log('Done reading stream');
                    setInfluencerData((prevState: any) => {
                      const updatedData = [...prevState];
                      if (updatedData[index].length === 0) {
                        updatedData[index] = ["🥚"];
                      }
                      return updatedData;
                    });
                    return;
                  }
                  if (buffer.indexOf("✅") !== -1) {
                    let ind = buffer.indexOf("✅");
                    let comp_json = buffer.slice(0, ind);
                    buffer = buffer.slice(ind + 1);
                    const newData = JSON.parse(comp_json);
                    const filteredData = {
                      videos: newData.videos.filter(
                        (video: any) => video.title && video.video_url
                      )
                    };
                    setInfluencerData((prevState: any) => {
                      const updatedData = Array.isArray(prevState) ? [...prevState] : [];
                      if (typeof index === 'number' && index >= 0) {
                        const prevVideos = Array.isArray(updatedData[index]) ? updatedData[index] : [];
                        const updatedVideos = filteredData.videos.reduce((acc: any[], video: any) => {
                          const existingIndex = acc.findIndex(v => v.video_url === video.video_url);
                          if (existingIndex !== -1) {
                            acc[existingIndex] = video;
                          } else {
                            acc.push(video);
                          }
                          return acc;
                        }, [...prevVideos]);
                        updatedData[index] = updatedVideos;
                      }
                      return updatedData;
                    });
                  }
                  readChunk();
                }).catch(error => {
                  if ((error as any).name === 'AbortError') {
                    console.log('Previous request aborted');
                  } else {
                    console.error('Error reading stream:', error);
                  }
                });
              };
              readChunk();
            })
            .catch(error => console.error('Error:', error));
        } catch (error) {
          if ((error as any).name === 'AbortError') {
            console.log('Fetch aborted');
          } else {
            console.error('Error during API call:', error);
          }
        }
      });
    };

    if (influencerData?.length === 0 || influencerData[0]?.length === 0) {
      fetchVideos();
    }

    // Cleanup for allEntities changes: Only abort if not navigating back / unmounting via popstate.
    return () => {
      if (!preventAbortRef.current && abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // eslint-disable-next-line
  }, [JSON.stringify(allEntities.map(({ location, ...rest }: { location?: unknown } & Record<string, any>) => rest))]);





  useEffect(() => {
    window.scrollTo(0, 0);
    setKey((prevKey) => prevKey + 1);
  }, [storyIndex]);

  useEffect(() => {
    document.body.style.overflow = showNewPage ? "hidden" : "auto";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [showNewPage]);

  useEffect(() => {
    // console.log("check??");
    setIsLiked(false);
    for (let entity of likeEntities) {
      if (entity["title"] === currentStory?.title) {
        setIsLiked(true);
      }
    }
  }, [likeEntities, currentStory]);

  const handleLike = async () => {
    const token = localStorage.getItem("token");
    if (token && currentStory) {
      // console.log(props)
      const body = {
        image: currentStory?.images?.[0],
        city: currentStory?.travel_destination ?? currentStory?.city,
        country: currentStory?.country,
        title: currentStory?.title,
        tags: currentStory?.tags,
        description: currentStory?.description,
        location: { "lat": currentStory?.location?.lat, "long": currentStory?.location?.long },
        place_metadata_id: currentStory?.place_metadata_id??undefined,
      };
      // console.log("body ", body);

      if (!isLiked) {
        amplitude.track("liked", {
          type: "entity", title: currentStory?.title, location: {
            city: currentStory?.city,
            country: currentStory?.country
          }, page: "entity_detail"
        });
        await fetchData({
          method: "POST",
          url: "user/likeEntity",
          options: {
            data: { ...body, token: localStorage.getItem("token") },
            headers: { "Content-Type": "application/json" },
          },
          auth: false,
        });

        setLikeEntities([...likeEntities, body]);

        setShowAddedToBucket(true);

        setTimeout(() => {
          setShowAddedToBucket(false);
        }, 3000);
      } else {
        amplitude.track("disLiked", {
          type: "entity", title: currentStory?.title, location: {
            city: currentStory?.city,
            country: currentStory?.country
          }, page: "entity_detail"
        });
        await fetchData({
          method: "POST",
          url: "user/dislikeEntity",
          options: {
            data: { ...body, token: localStorage.getItem("token") },
            headers: { "Content-Type": "application/json" },
          },
          auth: false,
        });

        const tempLikeEntities = [];
        for (let entity of likeEntities) {
          if (!(entity["title"] === body?.title)) {
            tempLikeEntities.push(entity);
          }
        }
        setLikeEntities(tempLikeEntities);
      }
    }
  };
  const handleLikeButton = (e: React.MouseEvent) => {
    e.stopPropagation(); // Stop the event from bubbling up
    const token = localStorage.getItem("token");
    if (!token) {
      amplitude.track("login_triggered", { buttonName: "Like", page: "entity_detail" });
      onOpen();
    } else {
      setIsLiked(!isLiked);
      handleLike();
    }
  };
  const handleShare = useCallback(
    async (sharableUrl?: any) => {
      await fetch('https://tiny.rahee.ai/share', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: sharableUrl }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          if (data?.short_url)
            shareModal.handleShareClick(data?.short_url, currentStory?.title);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    // eslint-disable-next-line
    [shareModal]
  );

  return (
    <>

      <Box
        width="100%"
        height="100%"
        overflowY="auto"
        overflowX="hidden"
        position="absolute"
        bg="#040D10"
        color="white"
      >
        {renderMedia()}
        <HStack mt={4}>
          <Box position={"sticky"} w={"10vw"} bg={"#040D10"} zIndex={10} ml={4}  >
            <IconButton
              aria-label='Back'
              icon={<img src='/icons/Back_Icon_White.svg' alt='back' />}
              colorScheme='gray'
              size='lg'
              variant={'link'}
              onClick={() => {
                if (routerLocation.key === "default") {
                  navigate("/"); // Navigate to root if there is no history
                } else {
                  router(-1); // Navigate back in history
                }
              }}
            // borderRadius={"100px"}
            // Adjust button size for different screens

            />
          </Box>
          <Box w={"90vw"} zIndex={10} >
            <PlacesTabs
              storyIndex={storyIndex}
              onTabClick={handleTabClick}
              allEntities={allEntities}
            />
          </Box>
        </HStack>


        {/* Fullscreen overlay to prevent interactions when LocationDetail is open */}

        {showNewPage && (
          <Box
            position="fixed"
            top={0}
            left={0}
            width="100%"
            height="100%"
            bg="blackAlpha.700"
            zIndex={10}
            onClick={(e) => e.stopPropagation()} // Prevent clicks from reaching underlying elements
          />
        )}
        <motion.div
          key={storyIndex}
          initial={{
            x: swipeDirection === 'left' ? '100%' : '-100%', // Start outside of the viewport
            opacity: 0.5, // Slightly visible initially
          }}
          animate={{
            x: 0, // Move to the center
            opacity: 1, // Fully visible
          }}
          exit={{
            x: swipeDirection === 'left' ? '-100%' : '100%', // Exit outside of the viewport
            opacity: 0.5, // Fade out slightly
          }}
          transition={{
            x: { type: 'spring', stiffness: 300, damping: 30 }, // Spring-based smooth transition
            opacity: { duration: 0.3 }, // Control opacity fade timing
          }}
          style={{
            position: 'absolute', // Overlap elements for a smooth push
            width: "100%",
            height: "82vh"
          }}
        >

          <Box
            {...(!showNewPage ? handlers : {})}
            // ref={likeAndShareRef}
            position="absolute"
            cursor="pointer"
            my={4}
            mx={4}
            width="92%"
            // height="82%"
            h={'82vh'}
            borderRadius="12px"
            overflow="hidden"
            zIndex={showNewPage ? 0 : 1} // Ensure zIndex order
          >
            {showAddedToBucket && (
              <ToastBucketList />
            )}
            {/* <Skeleton
            isLoaded={!loadingStates[storyIndex]} // Show skeleton while loading is true
            width="100%"
            height="100%"
          > */}
            <Box position="relative" width="full" >
              <StoryProgress mediaIndex={mediaIndex} totalMedia={currentStory?.images?.length || currentStory?.videos?.length} />
            </Box>
            {isVideoStory ? (
              <Box
                position="absolute"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <ReactPlayer
                  url={currentMedia}
                  playing={true}
                  muted={false}
                  loop={false}
                  controls={false}
                  onEnded={handleNextMedia}
                  width="100%"
                  height="100%"
                  style={{ position: "absolute", top: 0, left: 0 }}
                  config={{
                    file: {
                      attributes: {
                        style: {
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                        },
                      },
                    },
                  }}
                />
              </Box>
            ) : (
              (
                <Image
                  src={currentMedia && currentMedia.length > 0 ? currentMedia : allEntities[storyIndex]?.images}
                  alt={allEntities[storyIndex]?.title}
                  width="100%"
                  height="100%"
                  objectFit="cover"
                />
              )
            )}

            <Box position="absolute" top="20px" right="10px" zIndex={100} ref={likeAndShareRef}>
              <HStack spacing={0} justifyContent="flex-end">
                <IconButton
                  aria-label="Like"
                  // boxShadow= {"0px 4px 4px 0px #00000040"}
                  ref={likeButtonRef}
                  icon={
                    <Image
                      src={isLiked ? "/icons/like.svg" : "/icons/white-heart.svg"}
                      alt="like"
                      borderRadius="full"
                      boxShadow={"0px 4px 4px 0px #00000040"}
                    />
                  }
                  variant="ghost"
                  size="lg"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLikeButton(e);
                  }}
                  borderRadius="full"
                  // dropShadow="0px 4px 10px  #00000040" // Customize shadow here
                  _hover={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  _focus={{ boxShadow: "none" }}
                />
                <IconButton
                  aria-label="Share"
                  ref={shareButtonRef}
                  icon={<Image src="/icons/white-share.svg" alt="share"
                    borderRadius="full"
                    boxShadow={"0px 4px 4px 0px #00000040"}
                  />}
                  variant="ghost"

                  onClick={() => {
                    const generateSharableUrl = () => {
                      const queryParams = new URLSearchParams({
                        allEntities: encodeURIComponent(JSON.stringify(
                          [allEntities[storyIndex]].map(entity => {
                            const { unsplash_query, entity_type, location, _id, conversation_id, ...rest } = entity;
                            return rest;
                          })
                        )), // Proper encoding for URL

                      });
                      console.log('aaaaaaaaaaaaaaa', allEntities[storyIndex]);
                      console.log('queryParams', queryParams.toString());

                      return `${myAppUrl}/story-carousel?${queryParams.toString()}`;
                    };

                    // Example usage
                    console.log('Json', currentStory);
                    const sharableUrl = generateSharableUrl();
                    amplitude.track('share', { type: 'entity', title: currentStory?.title, page: 'video_search', url: sharableUrl });
                    handleShare(sharableUrl)
                  }}
                  size="lg"
                  borderRadius="full"
                  _hover={{ bg: "transparent" }}
                  _active={{ bg: "transparent" }}
                  _focus={{ boxShadow: "none" }}
                />
              </HStack>
            </Box>

            {/* {((isVideoStory || isImageStory) && !loadingStates[storyIndex]) && ( */}

            {/* )} */}

            <Box
              position="absolute"
              bottom={0}
              width="100%"
              p={4}
              fontFamily="Poppins"
              bgGradient="linear(to-t, blackAlpha.800, transparent)">
              <Text
                fontSize="26.54px"
                fontWeight="500"
                lineHeight="29.66px"
                letterSpacing="-1.0290316343307495px"
                bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(192, 192, 192, 0.93) 95.69%)"
                bgClip="text"
                textAlign="left"
              >
                {allEntities[storyIndex]?.title}
              </Text>
              <Text
                fontSize="13px"
                fontWeight="500"
                lineHeight="16.95px"
                letterSpacing="0.3px"
                textAlign="left"
              >
                {currentStory?.city}
              </Text>
              <Box zIndex={3} borderRadius="12px">
                <Text
                  fontSize="14px"
                  fontWeight="500"
                  mt={10}
                  lineHeight="19px"
                  noOfLines={3}
                  letterSpacing="-0.7px"
                  textAlign="justify"
                  bgGradient="linear-gradient(180deg, #FFFFFF 14.74%, rgba(15, 15, 15, 0) 119.41%)"
                  bgClip="text"
                >
                  {currentStory?.description}
                </Text>
              </Box>
              <HStack
                spacing={0}
                position={"relative"}
                zIndex={11}
                justifyContent={"center"}
                animation={`${moveUpDown} 1s infinite`}
              >
                {/* <Text fontSize="13px" color="gray.400" fontWeight="300">
                  {"SWIPE UP FOR MORE "}
                </Text> */}

                <Image src="/icons/ArrowUp.svg" alt="swipe up" />
              </HStack>
              <Box
                position='absolute'
                right={0}
                bottom={3}
                ref={iButtonRef}
                onClick={(e) => e.stopPropagation()}
                mt={2}
              >
                <ToggleTip
                  link={
                    currentMedia && currentStory?.image_attributions?.[currentMedia]?.uri
                      ? currentStory?.image_attributions[currentMedia]?.uri
                      : currentMedia || allEntities[storyIndex]?.images || null
                  }
                  iconColor="grey.100"
                  linkColor="grey.100"
                />


              </Box>
            </Box>
            {/* </Skeleton> */}
          </Box>
        </motion.div>

        <Slide direction="bottom" in={showNewPage} style={{ zIndex: 11 }} transition={{ enter: { duration: 0.5 }, exit: { duration: 0.5 } }}>
          <Box p={4} bg="#000000" h="100dvh" overflowY="auto" opacity={showNewPage ? 1 : 0} transition="opacity 0.5s ease-in-out">
            <Suspense fallback={<Skeleton height="100vh" width="100vw" />}>
              <LocationDetail
                key={key}
                allEntities={allEntities}
                storyIndex={storyIndex}
                handleBackButton={() => setShowNewPage(false)}
                story={currentStory}
                influencerData={influencerData[storyIndex]}
                itinerary={location.state?.itinerary ?? false}
              />
            </Suspense>
          </Box>
        </Slide>
      </Box >

      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>

    </>
  );
};

export default StoryCarousel;
