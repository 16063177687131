import { HStack, VStack, Image, Text } from "@chakra-ui/react";

const Whatsapp: React.FC = () => {

    return (
        <VStack
            h="65px"
            mx={'5%'}
            px={'5%'}
            // pt={15} pb={15}
            justifyContent={'center'}
            borderRadius={'18px'}
            onClick={() => window.open('https://wa.me/919519651011?text=Send%20your%20favourite%20reels%20here%20and%20use%20them%20to%20build%20an%20itinerary%20when%20ready', '_blank')}
            // border="3px solid #00000012"
            bg="linear-gradient(92.93deg, #3CBA4A 5.79%, #88C88F 132.01%)"
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: "3px solid #00000012",
                borderRadius: '18px',
                zIndex: 1,
            }}
            zIndex={0}
            >
            <HStack >
                <HStack>
                    <Image
                        w={"20%"}
                        h={"100%"}
                        src="/icons/logos_whatsapp-icon.svg"
                        boxSize={8}
                    />
                    <HStack
                        w={'80%'} alignItems={'flex-start'}   
                        // h={"30%"}
                        // spacing={"-10px"}
                    >
                        <Text

                            fontFamily="Poppins"
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="17.22px"
                            letterSpacing="0px"
                            color={'#FFFFFFED'}

                        >
                           Plan a custom itinerary from your favourite reels
                        </Text>
                        <Image
                            // w={"10%"}
                            src="/icons/wastar.svg"
                            // boxSize={4 }
                        />
                    </HStack>
                </HStack>
                <Image
                    w={"10%"}
                    src="/icons/right_anchor.svg"
                    boxSize={4}
                />
            </HStack>
        </VStack>
    );
}
export default Whatsapp;