import React from 'react';
import '@fontsource/sacramento';
import { Box, Flex, Text, VStack, Button } from '@chakra-ui/react';
import ItineraryCard from './ItineraryCard';
// import data from './data.json'; // Ensure this path is correct

interface TripDetailProps {
  title: string;
  subtitle: string;
  description: string;
  destination: any;
  itinerary?: any;
}

const TripDetail: React.FC<TripDetailProps> = ({
  title,
  subtitle,
  description,
  destination,
  itinerary
}) => {

  // useEffect(() => {
  //   if (id) {
  //     // Convert ID to number if necessary
  //     const selectedId = parseInt(id, 10);
  //     const selectedDestination = data.destinations.find((dest) => dest.id === selectedId);
  //     if (selectedDestination) {
  //       setItinerary(selectedDestination.itinerarydetail);
  //     }
  //   }
  // }, [id]);

  return (
    <Box
      bg="none"
      color="white"
      p={4}
      rounded="lg"
      maxW={["auto", "auto", "inherit"]}
      mx="auto"
    >
      {/* Header Section */}
      <VStack align="start" mb={8}>
        <Flex justifyContent={'space-between'} alignItems={'center'} w={'full'}>
          <Box>
            <Text
              zIndex={2}
              fontFamily={'Sacramento'}
              fontSize={'36px'}
              ml={[0, 0, 12]}
              color={["#FFFFFFD4", "#FFFFFFD4", "#878787"]}
              textTransform={"lowercase"}
            >
              {subtitle}
            </Text>
            <Text
              mt={["-17px", "-17px", "-32px"]}
              fontSize={["32px", "32px", "48px"]}
              fontWeight="700"
              lineHeight={'39px'}
              // bg="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)"
              bg={'white'}
              bgClip="text">
              {title}
            </Text>
          </Box>
          <Box bg={'#2B2A2C'} px={4} py={3} borderRadius={'99px'} h={'fit-content'} display={['none', 'none', 'flex']}>
            <Button fontSize='13px' fontWeight='600' color='#AE7CFF' variant='link'>
              + Save Itinerary
            </Button>
          </Box>
        </Flex>
        <Text
          mt={"11px"}
          fontSize="md"
          color="#FFFFFFA1"
          noOfLines={5}
          textAlign={'left'}
        >
          {description}
        </Text>
        {destination?.days?.map((dayItinerary: any, dayIndex: number) => (
          <ItineraryCard
            key={dayIndex}
            dayItinerary={dayItinerary}
          />
        ))}

        <VStack w="full" mt={1} alignItems="flex-start" color={["#FFFFFFD4", "#FFFFFFD4", "#878787"]}>
          {[
            // { title: "Key Points", key: "key_points" },
            // { title: "Assumptions", key: "assumptions" },
            { title: "Important Information", key: "important_information" },
            { title: "Excluded Activities and Reasons", key: "excluded_activities_and_reasons" }
          ].map(({ title, key }) => (
            itinerary?.[key]?.length > 0 && (
              <Box key={key} bg={["#FFFFFF12", "#FFFFFF12", "none"]} w="full" p={4}>
                <Text fontSize="16px" mb={1}>{title}</Text>
                {itinerary?.[key].map((point: string, index: number) => (
                  <Box key={index} rounded="md" w="full" ml={2}>
                    <ul style={{ listStyleType: 'disc', paddingInlineStart: '10px' }}>
                      <li>
                        <Text fontSize="12px" color="#FFFFFFA1" noOfLines={5} textAlign="left">
                          {point}
                        </Text>
                      </li>
                    </ul>
                  </Box>
                ))}
              </Box>
            )
          ))}
          {itinerary?.budget_breakdown && (
            <Box bg={["#FFFFFF12", "#FFFFFF12", "none"]} w="full" p={4}>
              <Text fontSize="16px" mb={1}>Budget Breakdown (INR)</Text>
              {Object?.entries(itinerary?.budget_breakdown)?.map(([key, value]) => (
                <Box key={key} rounded="md" w="full" display="flex" justifyContent="space-between" >
                  <Text fontSize="md" color="#FFFFFFA1" textTransform="capitalize">
                    {key?.replace(/INR$/, "").replace(/([A-Z])/g, " $1").trim()}
                  </Text>
                  <Text fontSize="12px" color="#FFFFFFA1">
                    ₹{new Intl.NumberFormat("en-IN").format(value as number)}
                  </Text>
                </Box>
              ))}
            </Box>
          )}
        </VStack>

      </VStack>
    </Box>
  );
};

export default TripDetail;
