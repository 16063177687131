import { Box, Text, Image, VStack, Heading } from "@chakra-ui/react";

const DesktopWarning = () => {
  return (
    <Box bg={'black'} w={'100dvw'} h={'100dvh'}
      bgImage={'url(/images/bg_images/warning.png)'}
      bgPosition={'center'} bgRepeat={'no-repeat'} bgSize={'cover'}
      position="relative"
    >
      <Box
        position="absolute"
        top="0"
        left="0"
        w="100%"
        h="100%"
        bg="linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5))"
      />
      <VStack pt={'10vh'}
        w={'100%'}
        position={'absolute'}
        spacing={'27vh'}
        top={'10%'}
        left={'50%'} transform={'translate(-50%, -20%)'}
      >
        <VStack spacing={1}>
          <Image

            src="/icons/logo.svg" />
          <Heading
            fontFamily='Poppins'
            fontSize='26.97px'
            fontWeight='700'
            lineHeight='32.59px'
            letterSpacing='-0.34px'
            textAlign='left'
            color='#FFFFFFED'
            backdropFilter='blur(4px)'
            p={0}>
            Rahee AI
          </Heading>
        </VStack>
        <Text fontSize="2xl" fontWeight="bold" color="white" textAlign="center">
          This website is only available on mobile devices. <br />
          Please switch to a mobile device to view this site.
        </Text>
      </VStack>

    </Box>
  );
};

export default DesktopWarning;
