import { AppContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";
import { useCallback, useContext } from 'react';
import { transformMessage } from "../utilities/transformMessage";
import * as amplitude from "@amplitude/analytics-browser";
import { getUserLocation } from "../utilities/getUserLocation";


const useTripService = () => {
    const { fetchData } = useAxios();
    const context: any = useContext(AppContext);
    const { conversationId, setConversationId, setPreviousChat, setCurrItinerary, isItinerary, userDetail, setIsChatLoading, onBehalf, setOnBehalf, setCountryName, setTitle, previousChat, setItineraryQuery, itineraryQuery, setMustHave, setGoodToHave } = context;
    const fetchConversationResponse = useCallback(async (
        message?: any,
        query_confirmed?: boolean,
        fields_input?: any
    ) => {
        try {
            setIsChatLoading(true);
            const meessageId = window.crypto.randomUUID();
            const bucketListData = localStorage.getItem("bucketList");
            localStorage.removeItem("bucketList");
            const filteredEntries = bucketListData ? JSON.parse(bucketListData) : {};
            setCountryName(undefined);
            // console.log("messageId", new Date().toISOString());
            const mess = [{
                _id: meessageId,
                text: message,
                type: "text",
                author: "user",
                metadata: {
                    fields_input: fields_input
                },
                time: new Date().toISOString(),
                onBehalf: Object.keys(onBehalf || {}).length === 0 ? undefined : onBehalf

            }];
            setOnBehalf({});
            console.log("message", mess);
            if (previousChat.length === 0) {
                setMustHave([]);
                setGoodToHave([]);
                setItineraryQuery([]);
                setTitle(message);
                amplitude.track("itinerary_creation", {
                    query: message,
                });
            }
            setPreviousChat((prevChat: any) => [...prevChat, mess?.[0]]);
            setPreviousChat((prevChat: any) => [...prevChat, { text: "...", author: "assistant", type: "text" }]);
            const budget = itineraryQuery?.[itineraryQuery.length - 1]?.meta?.total_budget;

            // If query_confirmed is true, use streaming endpoint
            if (query_confirmed && budget) {
                const user_loc = await getUserLocation().catch(() => null);
                fetch(`${process.env.REACT_APP_API_URL}ai/v1/itinerary_generation`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${localStorage.getItem('token')}`
                    },
                    body: JSON.stringify({
                        user_id: userDetail?.user_id,
                        conversation_id: conversationId ?? "",
                        messages: mess ?? [],
                        bucketlist: filteredEntries,
                        query_confirmed: true,
                        user_loc: user_loc,
                    }),
                })
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        return response;
                    })
                    .then((response) => {
                        if (!response.body) {
                            throw new Error("Response body is null");
                        }
                        const reader = response.body.getReader();
                        let buffer = "";

                        const readChunk = () => {
                            reader.read().then(async ({ value, done }) => {
                                if (done) {
                                    setIsChatLoading(false);
                                    return;
                                }

                                const chunk = new TextDecoder("utf-8").decode(value);
                                buffer += chunk;

                                if (buffer === "🥚") {
                                    console.log("Done reading stream");
                                    setIsChatLoading(false);
                                    return;
                                }

                                if (buffer.indexOf("✅") !== -1) {
                                    let ind = buffer.indexOf("✅");
                                    let comp_json = buffer.slice(0, ind);
                                    buffer = buffer.slice(ind + 1);

                                    try {
                                        const message = JSON.parse(comp_json);

                                        // Update conversation ID if it's provided
                                        // if (message.conversation_id) {
                                        //     setConversationId(message.conversation_id);
                                        // }
                                        const transformedMessage = transformMessage(message.message);
                                        // Process message updates
                                        console.log("transformedMessage", transformedMessage);
                                        await setPreviousChat((prevChat: any) => {
                                            const newChat = [...prevChat];
                                            console.log("newChat", newChat);
                                            if (newChat.length > 0) {
                                                newChat[newChat.length - 1] = transformedMessage
                                            }
                                            setCurrItinerary(transformedMessage.itineraries);
                                            return newChat;
                                        });


                                        // console.log("Message:", comp_json);

                                        // Handle itinerary query if present
                                        if (message?.itinerary_query) {
                                            setItineraryQuery((prevQuery: any) => [...prevQuery, message.itinerary_query]);
                                        }




                                        // Handle query_completed status
                                        if (message?.query_completed !== undefined) {
                                            setPreviousChat((prevChat: any) => {
                                                const newChat = [...prevChat];
                                                if (newChat.length > 0) {
                                                    newChat[newChat.length - 1] = {
                                                        ...newChat[newChat.length - 1],
                                                        query_completed: message.query_completed
                                                    };
                                                }
                                                return newChat;
                                            });

                                        }
                                        // for status completed, fetch itinerary bookings
                                        if (message?.status === "completed") {
                                            const { data, statusCode } = await fetchData({
                                                method: "POST",
                                                url: `ai/v1/add_itinerary_bookings`,
                                                options: {
                                                    data: {
                                                        itinerary_id: message?.itinerary_id,
                                                    },
                                                    headers: { "Content-Type": "application/json" },
                                                },
                                                auth: true,
                                            });
                                            if (statusCode === 200) {
                                                const transformedMessage = transformMessage(data?.message);
                                                // Process message updates
                                                console.log("transformedMessage", transformedMessage);
                                                await setPreviousChat((prevChat: any) => {
                                                    const newChat = [...prevChat];
                                                    console.log("newChat", newChat);
                                                    if (newChat.length > 0) {
                                                        newChat[newChat?.length - 1] = transformedMessage
                                                    }
                                                    setCurrItinerary(transformedMessage?.itineraries);
                                                    return newChat;
                                                });

                                            }
                                        }
                                        setIsChatLoading(false);
                                    } catch (error) {
                                        console?.error("Error parsing message:", error, comp_json);
                                    }
                                }

                                readChunk();
                            }).catch(error => {
                                console.error("Error reading chunk:", error);
                                setIsChatLoading(false);
                            });
                        };

                        readChunk();
                    })
                    .catch((error) => {
                        console?.error("Error fetching stream:", error);
                        setIsChatLoading(false);
                    });
            } else {
                // Original non-streaming implementation
                const { data, statusCode } = await fetchData({
                    method: "POST",
                    url: `ai/v1/itinerary_conversation`,
                    options: {
                        data: {
                            user_id: userDetail?.user_id,
                            conversation_id: conversationId ?? "",
                            messages: mess ?? [],
                            bucketlist: filteredEntries,
                            query_confirmed: query_confirmed ?? false,
                            entity_follow_on: isItinerary ?? false
                        },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: true,
                });

                if (statusCode === 200) {
                    setConversationId(data?.conversation_id);

                    const transformedMessage = transformMessage(data?.message);
                    console.log(transformedMessage);

                    await setPreviousChat((prevChat: any) => {
                        const newChat = [...prevChat];
                        if (newChat.length > 0) {
                            newChat[newChat.length - 1] = { ...newChat[newChat.length - 1], ...transformedMessage };
                        } else {
                            newChat.push({ ...transformedMessage });
                        }
                        return newChat;
                    });

                    setPreviousChat((prevChat: any) => {
                        const newChat = [...prevChat];
                        if (newChat.length > 0) {
                            newChat[newChat.length - 1] = {
                                ...newChat[newChat.length - 1],
                                query_completed: data?.query_completed
                            };
                        }
                        return newChat;
                    });

                    if (data?.itinerary_query) {
                        setItineraryQuery((prevQuery: any) => [...prevQuery, {
                            ...data.itinerary_query,
                            message_id: data?.message?._id
                        }]);
                    }
                    setIsChatLoading(false);
                }
            }
        } catch (error) {
            setIsChatLoading(false);
            console.error("Fetch error:", error);
        }
        // eslint-disable-next-line
    }, [fetchData, setPreviousChat]);

    return { fetchConversationResponse };
};

export default useTripService;
