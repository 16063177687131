
export const transformMessage = (message: any) => {
    if (message.type === "itinerary" && message.itinerary) {
        const { itineraries, message: text } = message.itinerary;

        // Transform each itinerary: move `_id` to `plan.name`
        const transformedItineraries = itineraries.map((itinerary: any) => {
            const { _id, ...rest } = itinerary;
            return {
                ...rest,
                plan: {
                    ...itinerary.plan,
                    id: _id,
                },
            };
        });

        return {
            ...message,
            itineraries: transformedItineraries,
            text,
        };
    }
    else if (message.type === "entity" && message.entity) {
        const { entities, text } = message.entity;

        // Transform each itinerary: move `_id` to `plan.name`
        return {
            ...message,
            entities: entities,
            text: text,
        };
    }

    // Return the original message if type is not "itinerary"
    return message;
};