import axios, { AxiosError } from 'axios';
import { getUserLocation } from '../utilities/getUserLocation';

const apiUrl = process.env.REACT_APP_API_URL;

// FETCH STORY DETAIL
export const fetchEntityDetails = async (
    title: string,
    userDetail: any,
    city?: string,
    country?: string,
    context?: any,
    highlight_id?: string,
    place_metadata_id?: string,
    travel_destination?: string

) => {
    try {
        const user_loc = await getUserLocation().catch(() => null);
        const response = await axios.post(`${apiUrl}v1/entity`, {
            query: {
                title: title,
                city: city,
                country: country,
                context: context ?? {},
                travel_destination: travel_destination ?? ""
            },
            context: {
                user: {
                    details: userDetail.traveler_details
                }
            },
            highlight_id: highlight_id ?? "",
            place_metadata_id: place_metadata_id ?? "",
            user_loc : user_loc

        }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return response.data;

    } catch (error) {
        const axiosError = error as AxiosError;
        console.error('Fetch error:', axiosError);
        if (axiosError.response) {
            console.error('Error Response Body:', axiosError.response.data);
        }
    } finally {
        // Any cleanup code if needed
    }
};
