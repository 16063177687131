import { HStack, VStack, Image, Text } from "@chakra-ui/react";
import useSmartNavigate from "../../hooks/useSmartNavigate";

const TrendingVideos: React.FC = () => {
    const navigate = useSmartNavigate();

    return (
        <VStack
            h="65px"
            mx={'5%'}
            px={'5%'}
            // pt={15} pb={15}
            justifyContent={'center'}
            borderRadius={'18px'}
            onClick={() => navigate('/trending')}
            bgImage={"url('/images/Rectangle 53459 1.png')"}
            bgSize="cover"
            position="relative"
            _before={{
                content: '""',
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: "3px solid #00000012",
                borderRadius: '18px',
                zIndex: 1,
            }}
            zIndex={0}
        >
            <HStack
            // border="3px solid #00000012"
            >
                <HStack>
                    <Image
                        w={"20%"}
                        h={"100%"}
                        src="/icons/lady1.svg"
                        boxSize={8}
                    />
                    <HStack
                        w={'80%'} alignItems={'flex-start'}
                    // h={"30%"}
                    // spacing={"-10px"}
                    >
                        <Text

                            fontFamily="Poppins"
                            fontWeight="600"
                            fontSize="14px"
                            lineHeight="17.22px"
                            letterSpacing="0px"
                            color={'#FFFFFFED'}

                        >
                            Wondering where to go next? Let's find out!
                        </Text>

                    </HStack>
                </HStack>
                <Image
                    w={"10%"}
                    src="/icons/right_anchor.svg"
                    boxSize={4}
                />
            </HStack>
        </VStack>
    );
}
export default TrendingVideos;