import { Image, Text, VStack, useBreakpointValue, Box, HStack, IconButton, Button, Icon } from "@chakra-ui/react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useLoading } from "../../context/LoadingContext";
import { fetchEntityDetails } from "../../services/apiService";
import useSmartNavigate from "../../hooks/useSmartNavigate";
import { FaMapMarkerAlt } from "react-icons/fa";
interface AlternatingCardProps {
    activities: any;
}
const AlternatingCard: React.FC<AlternatingCardProps> = ({
    activities,
}) => {
    const isMobile = useBreakpointValue({ base: true, md: false });

    const context: any = useContext(AppContext);
    const {
        userDetail,
        setStryIndex,
        setInfluencerData,
        setPreviousChatLoading,
        setEntitesDetail,
    } = context;
    const { setIsLoading } = useLoading();
    const navigate = useSmartNavigate();


    const handleClick = async (placeName: any, city: any, image: any) => {
        // Handle navigation or entity fetch logic here
        try {
            setStryIndex(null);
            setInfluencerData([]);
            setIsLoading(true);
            setEntitesDetail([]);

            const initialEntityDetail = await fetchEntityDetails(
                placeName,
                userDetail,
                city ?? "",
                "",
                {},
                "",
            );

            console.log("response is:", JSON.stringify(initialEntityDetail.data));
            setPreviousChatLoading(true);

            navigate(`/story-carousel`, {
                state: {
                    clickedIndex: 0,
                    initialEntityDetail: initialEntityDetail.data.entity,
                    allEntities: [{ title: placeName, images: image ?? "" }],
                    city: "",
                    country: "",
                    videoHighlight: true,
                    itinerary: true
                }
            });
        }
        catch (error) {
            console.error('Fetch error:', error);
        }
        finally {
            setIsLoading(false);
        }
    }
    return (
        <VStack spacing={"36px"} align="stretch" w="full" mt={3} >
            {activities?.length > 0 && (
                <Text
                    fontFamily={'Poppins'}
                    fontSize={'16px'}
                    fontWeight={'600'}
                    color={'#FFFFFFD4'}
                    lineHeight={'19px'}
                    letterSpacing={"0%"}
                    // mb={2}
                    mb={-4}
                >
                    Activities for you
                </Text>
            )}
            {activities?.map((item: any, index: number) => (
                <Box key={index} fontFamily={'Poppins'}>
                    {item?.type !== "transport" ? (
                        <VStack w={'100%'} p={0} m={0} h={'100%'} spacing={'16px'}>
                            <HStack w={'100%'} alignItems={'flex-start'}>
                                <HStack
                                    spacing={'16px'}
                                    alignItems="center"
                                    w="95%"
                                >
                                    <Image
                                        src={item?.image}
                                        alt={item?.name}
                                        width="56px"
                                        height="56px"
                                        borderRadius="5.55px"
                                        objectFit="cover"
                                        flexShrink={0}
                                        onClick={() => {
                                            handleClick(item?.place_name, item?.city, item?.image);
                                        }}
                                    />
                                    <VStack spacing={'10px'} alignItems="flex-start" width="full" pt={isMobile ? 0 : 1}>
                                        <HStack w="full" justifyContent="space-between" alignItems="flex-start">
                                            <Text
                                                w={"90%"}
                                                fontFamily={'Poppins'}
                                                fontSize={'13px'}
                                                fontWeight={'600'}
                                                color={'#FFFFFFD4'}
                                                lineHeight={'133%'}
                                                cursor="pointer"
                                                noOfLines={2}
                                            >
                                                {item?.name}
                                            </Text>
                                        </HStack>
                                        <HStack
                                            w={"100%"}
                                            spacing={1}
                                            alignItems={"center"}
                                            // justifyContent={"space-between"}
                                            gap={2}
                                        >
                                            {item?.duration && (
                                                <Text
                                                    fontFamily={'Poppins'}
                                                    fontSize={'11px'}
                                                    fontWeight={'500'}
                                                    color={'#FFFFFF54'}
                                                    lineHeight={'13px'}
                                                    letterSpacing={"0%"}
                                                >
                                                    Duration: <Text as="span" fontWeight={'700'} color={'#FFFFFFED'}>{item?.duration}   </Text>
                                                </Text>
                                            )}
                                            {(item?.costINR && item?.costINR !== 0 && item?.costINR !== '0') && (
                                                <Text
                                                    fontFamily={'Poppins'}
                                                    fontSize={'11px'}
                                                    fontWeight={'500'}
                                                    color={'#FFFFFF54'}
                                                    lineHeight={'13px'}
                                                    letterSpacing={"0%"}
                                                >
                                                    <Text as="span" fontWeight={'700'} color={'#FFFFFFED'}> • </Text> Cost: <Text as="span" fontWeight={'700'} color={'#FFFFFFED'}>₹{item?.costINR}</Text>
                                                </Text>
                                            )}

                                        </HStack>

                                    </VStack>

                                </HStack>
                                <HStack>
                                    <IconButton
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleClick(item?.place_name, item?.city, item?.image);
                                        }}
                                        variant={'ghost'}
                                        aria-label='Expand'
                                        justifyContent={'flex-end'}
                                        boxSize={3}
                                        icon={<Image src='/icons/Cright.svg' alt='Expand' boxSize={3} />}

                                    />
                                </HStack>
                            </HStack>
                            <HStack w={'100%'} h={'45px'} justifyContent='space-between' alignItems='center'>
                                {item?.booking_link && (
                                    <Button
                                        w={"85%"}
                                        bg={"#FFFFFF0D"}
                                        variant={"ghost"}
                                        h={'100%'}
                                        rounded={'8px'}

                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            window.open(item?.booking_link, '_blank');
                                        }}
                                    >
                                        <Text
                                            fontFamily={'Poppins'}
                                            fontSize={'13px'}
                                            fontWeight={'600'}
                                            color={'#AE7CFF'}
                                            lineHeight={'17px'}
                                        >
                                            Book tickets
                                        </Text>
                                    </Button>
                                )}
                                {item?.booking_link ?
                                    (item?.mapslink && (
                                        <Button
                                            w={"12%"}
                                            bg={"#FFFFFF0D"}
                                            variant={"ghost"}
                                            h={'100%'}
                                            rounded={'8px'}
                                            p={0}
                                            m={0}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                window.open(item?.mapslink, '_blank');
                                            }}
                                        >
                                            <Image src='/icons/route1.svg' alt='map icon' boxSize={'5'} />

                                        </Button>
                                    )) :
                                    (item?.mapslink && (
                                        <Button
                                            w={"100%"}
                                            bg={"#FFFFFF0D"}
                                            variant={"ghost"}
                                            h={'100%'}
                                            rounded={'8px'}
                                            p={0}
                                            m={0}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                window.open(item?.mapslink, '_blank');
                                            }}
                                        >
                                            <HStack spacing={'9.33px'} alignItems={'center'} justifyContent={'center'} w={'100%'}>
                                                <Text
                                                    fontFamily="Poppins"
                                                    fontWeight="600"
                                                    fontSize="13px"
                                                    lineHeight="17px"
                                                    letterSpacing="0%"
                                                    color={"#AE7CFF"}
                                                >
                                                    View Direction
                                                </Text>
                                                <Image src='/icons/route1.svg' alt='map icon' boxSize={'5'} />
                                            </HStack>
                                        </Button>
                                    ))}

                            </HStack>
                        </VStack>
                    ) : (
                        <>
                            <VStack w={'100%'} spacing={'12.44px'}>
                                <HStack alignItems={"flex-start"} justifyContent={"flex-start"} w={'100%'} >
                                    <Text
                                        fontFamily="Poppins"
                                        fontWeight="600"
                                        fontSize="16px"
                                        lineHeight="19px"
                                        letterSpacing="0%"
                                        color="#FFFFFFD4"
                                    >
                                        {item?.name}
                                    </Text>
                                </HStack>
                                <VStack
                                    pt={"15.36px"}
                                    px={"17.5px"}
                                    pb={'12px'}
                                    w={'100%'}
                                    spacing={5}
                                    // justifyContent={"space-between"}                
                                    bg={'#FFFFFF08'}
                                    borderRadius={'12px'}
                                >
                                    <HStack
                                        w={"100%"}
                                        justifyContent={"space-between"}
                                        alignItems={"flex-start"}   >
                                        <VStack w={'90%'} alignItems={"flex-start"} spacing={0}>
                                            <HStack>
                                                <Icon as={FaMapMarkerAlt} w={'13.11px'} h={'13.11px'} color="#FFFFFFA1" />
                                                <Text
                                                    fontFamily="Poppins"
                                                    fontWeight="500"
                                                    fontSize="13px"
                                                    lineHeight="19px"
                                                    letterSpacing="0%"
                                                    color="#FFFFFFA1"

                                                >
                                                    {item?.from}
                                                </Text>
                                            </HStack>
                                            <HStack
                                                h={"10px"}
                                                ml={"6px"}
                                                borderLeft={'1px dashed #FFFFFF21'}
                                            />
                                            <HStack>
                                                <Icon as={FaMapMarkerAlt} w={'13.11px'} h={'13.11px'} color="#FFFFFFA1" />
                                                <Text
                                                    fontFamily="Poppins"
                                                    fontWeight="500"
                                                    fontSize="13px"
                                                    lineHeight="19px"
                                                    letterSpacing="0%"
                                                    color="#FFFFFFA1"

                                                >
                                                    {item?.to}
                                                </Text>
                                            </HStack>
                                        </VStack>
                                        {item?.directions_link && (
                                            <Button
                                                w={"10%"}
                                                variant={"ghost"}
                                                h={'100%'}
                                                rounded={'8px'}
                                                p={0}
                                                m={0}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    window.open(item?.directions_link, '_blank');
                                                }}
                                            >
                                                <Image src='/icons/route1.svg' alt='map icon' boxSize={'5'} />

                                            </Button>

                                        )}
                                    </HStack>
                                    <Box
                                        h={'auto'}
                                        w={'100%'} 
                                        borderTop={"1px solid #FFFFFF21"}
                                    />
                                    <HStack
                                        w={"100%"}
                                        spacing={1}
                                        alignItems={"center"}
                                        justifyContent={"flex-start"}
                                        gap={2}
                                    >
                                        {item?.duration && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'11px'}
                                                fontWeight={'500'}
                                                color={'#FFFFFF54'}
                                                lineHeight={'13px'}
                                                letterSpacing={"0%"}
                                            >
                                                Duration: <Text as="span" fontWeight={'700'} color={'#FFFFFFED'}>{item?.duration}   </Text>
                                            </Text>
                                        )}
                                        {(item?.costINR && item?.costINR !== 0 && item?.costINR !== '0') && (
                                            <Text
                                                fontFamily={'Poppins'}
                                                fontSize={'11px'}
                                                fontWeight={'500'}
                                                color={'#FFFFFF54'}
                                                lineHeight={'13px'}
                                                letterSpacing={"0%"}
                                            >
                                                <Text as="span" fontWeight={'700'} color={'#FFFFFFED'}> • </Text> Cost: <Text as="span" fontWeight={'700'} color={'#FFFFFFED'}>₹{item?.costINR}</Text>
                                            </Text>
                                        )}
                                    </HStack>

                                </VStack>
                            </VStack>
                            <Box
                                borderBottom={'1px solid #FFFFFF21'}
                                mt={4}
                            // mb={4}

                            />
                        </>
                        // <GenericItineraryCard
                        //     title={item?.name}
                        //     item?.from={item?.from}
                        //     item?.to={item?.to}
                        //     maps_route={item?.maps_route}
                        //     directions_link={item?.directions_link}
                        //     costINR={item?.costINR}
                        //     details={item?.description}
                        // />
                    )}

                    {/* {index < activities.length - 1 && (
                        <Box
                            borderBottom={'1px solid #FFFFFF21'}
                            mt={4}
                            // mb={4}
                            
                        />
                    )} */}
                </Box>
            ))}
        </VStack>
    );
};

export default AlternatingCard;