import { Heading, HStack, IconButton, Image, VStack } from "@chakra-ui/react";
// import { usenavigateSmart } from "react-router-dom";
import * as amplitude from "@amplitude/analytics-browser";
import Install from "./Install";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import useSmartNavigate from "../../hooks/useSmartNavigate";
// import CollectionSearch from "../Collection-flow/CollectionSearch";
// import SearchBar from "../SearchComponents/SearchBar";

interface HeaderMobileProps {
  isAuthenticated: boolean;
  onOpenLogin: () => void;
}

const HeaderMobile: React.FC<HeaderMobileProps> = ({ isAuthenticated, onOpenLogin }) => {
  const navigate = useSmartNavigate();
  const context: any = useContext(AppContext);
  const { rOpen, setLOpen, setIsCenterButtonClicked, setROpen } = context;
  // const location = useLocation();
  const handleBucketList = () => {
    setLOpen(false);
    setIsCenterButtonClicked(false);
    if (rOpen) {
      setROpen(false);
      navigate("/");
    } else {
      if (isAuthenticated) {
        amplitude.track('bucketList_clicked', { page: 'HomePage', buttonName: 'bucketList' });
        setROpen(true);
        navigate("/navigate");
      } else {
        amplitude.track("login_triggered", { buttonName: "bucketList", page: "HomePage" });
        onOpenLogin();
      }

    }
  }


  return (
    <VStack
      bg={["#29252D", "transparent", "transparent", "transparent"]}
      borderBottomRadius="33px"
      w="100dvw"
    >
      <HStack
        px={6}
        py={3}
        w={["100%", "85%", "85%", "85%"]}
        justifyContent="space-between"
      >
        {/* Logo and Title */}
        <HStack>
          <Image src="/icons/logo.svg" alt="logo" boxSize="18px" />
          <Heading
            fontFamily="Poppins"
            fontSize="16.78px"
            fontWeight="600"
            lineHeight="20.28px"
            letterSpacing="-0.21px"
            textAlign="left"
            onClick={() => navigate("/")}
            color="#FFFFFFED"
            cursor="pointer"
          >
            Rahee AI
          </Heading>
        </HStack>

        {/* Action Buttons */}
        <HStack spacing={[4, 6, 6, 6]}>
          <IconButton
            aria-label="saved-chat"
            icon={<Image src={"/icons/ListUnselected.svg"} boxSize={'5'} mx={'auto'} />}
            borderRadius="9.03px"
            color="white"
            boxSize={'38px'}
            bg={rOpen ? "#9B5CFF" : "#2E2B31"}
            display={["none", "block", "block", "block"]}
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
            onClick={handleBucketList} />
          <Install />

          {/* Saved Chat Button */}
          {/* <IconButton
            aria-label="saved-chat"
            icon={<img src="/icons/chat.svg" alt="chat" />}
            borderRadius="9.03px"
            color="white"
            boxSize={'38px'}
            bg="#2E2B31"
            _hover={{ bg: "transparent" }}
            _active={{ bg: "transparent" }}
            _focus={{ boxShadow: "none" }}
            onClick={() => {
              amplitude.track("button_clicked", { buttonName: "saved-chat" });
              if (isAuthenticated) {
                navigateSmart("/savedConversation");
              } else {
                amplitude.track("login_triggered", { buttonName: "saved-chat", page: "HomePage" });
                onOpenLogin();
              }
            }}
          /> */}

          {/* Profile or Login Button */}
          {isAuthenticated ? (
            <IconButton
              aria-label="log-in"
              icon={<img src="/icons/user2.svg" alt="user" />}
              borderRadius={["9.03px", '30px', '30px', '30px']}
              color="white"
              bg="#2E2B31"
              boxSize={'38px'}
              onClick={() => navigate("/profile")}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
            />
          ) : (
            <IconButton
              aria-label="log-in"
              icon={<img src="/icons/user.svg" alt="user" />}
              borderRadius="9.03px"
              color="white"
              bg="#2E2B31"
              onClick={() => {
                amplitude.track("login_triggered", { buttonName: "log-in", page: "HomePage" });
                onOpenLogin();
              }}
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
            />
          )}
        </HStack>
      </HStack>

    </VStack>
  );
};

export default HeaderMobile;
