import { Box, Spinner, Text, Image, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";


interface SavingComponentProps {
    status: string;
}

const SavingComponent: React.FC<SavingComponentProps> = ({ status }) => {
    const navigate = useNavigate();
    return (
        <Box
            position="fixed"
            left={0}
            top={0}
            w="100%"
            h="100%"
            zIndex={999999999}
            display="flex"
            flexDir="column"
            alignItems="center"
            justifyContent="center"
            bg="black"
            color="white"
        >
            {status === "saving" ? (
                <Spinner size="lg" color="white" />
            ) : (
                <Image src="/icons/tick.svg" alt="checkmark" zIndex={9999} boxSize="60px" />
            )}
            <Text mt={4}
                w={"60%"}
                fontFamily="Poppins"
                fontWeight="400"
                fontSize="16px"
                lineHeight="153%"
                letterSpacing="0%"
                textAlign="center"
            >
                {status === "saving"
                    ? "Please wait for a moment while we weave our magic..."
                    : "Itinerary Saved"}
            </Text>

            <Button
                mt={4}
                visibility={status === "saving" ? "hidden" : "visible"}
                variant={'ghost'}
                p={2}
                px={5}
                border={'1px solid #FFFFFF2B'}
                _hover={{ bg: 'transparent' }}
                borderRadius={'13px'}
                onClick={() => navigate('/navigate', { state: { index: 2 } })}
            >
                <Text
                    font-family="Poppins"
                    font-weight="400"
                    font-size="16px"
                    line-height="153%"
                    letter-spacing="0%"
                    text-align="center"
                    color={'#AE7CFF'}
                >
                    Go to saved itineraries
                </Text>
            </Button>
        </Box>
    );
};

export default SavingComponent;
