import useAxios from "../hooks/useAxios";
import { transformMessage } from '../utilities/transformMessage';

export const useConversationService = () => {
    const { fetchData } = useAxios();

    const getConversationById = async ({
        conversation_id,
        type
    }: any) => {
        try {
            const token = localStorage.getItem('token');
            const { data } = await fetchData({
                method: "POST",
                url: `ai/v2/get_conversation_by_id`,
                options: {
                    data: {
                        token,
                        conversation_id,
                        type
                    },
                    headers: { "Content-Type": "application/json" },
                },
                auth: false,
            });

            return data;
        } catch (error) {
            console.error('Error fetching conversation:', error);
            return null;
        }
    };

    const processConversationMessages = (messages: any[]) => {
        return messages.map(message => transformMessage(message));
    };

    return {
        getConversationById,
        processConversationMessages
    };
};