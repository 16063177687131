import {
	Box, SimpleGrid, VStack, Text, Image, HStack,
	IconButton, Button,
	useDisclosure
	//  useDisclosure
} from '@chakra-ui/react';
import React, { useContext } from 'react';
import { AppContext } from '../../context/AppContext';
import { fetchEntityDetails } from '../../services/apiService';
import { useLoading } from '../../context/LoadingContext';
import useSmartNavigate from '../../hooks/useSmartNavigate';
import ModalWithBackdrop from '../ModalWithBackdrop';

import Option from './Option';

interface ImageViewProps {
	image?: string;
	name?: string;
	address?: string;
	costINR?: string;
	booking_link?: string;
	linkUrl?: string;
	width?: string;
	height?: string;
	mapslink?: string;
	place_name?: string;
	city?: string;
	day?: any;
}

interface ImageGridProps {
	accommodation: any;
	day?: any;
}

const ImageView: React.FC<ImageViewProps> = ({
	image,
	name,
	address,
	costINR,
	booking_link = null,
	linkUrl,
	width,
	height,
	mapslink,
	place_name,
	city,
	day
}) => {

	console.log("day is herer", day);
	const context: any = useContext(AppContext);
	const {
		userDetail,
		setStryIndex,
		setInfluencerData,
		setPreviousChatLoading,
		setEntitesDetail,
	} = context;
	const { setIsLoading } = useLoading();
	const navigate = useSmartNavigate();
	// const { fetchData } = useAxios();
	const { isOpen, onOpen, onClose } = useDisclosure();
	// const url = window.location.href;
	// const itineraryId = url.split('/').pop();


	const handleClick = async (placeName: any, city: any) => {
		// Handle navigation or entity fetch logic here
		try {
			setStryIndex(null);
			setInfluencerData([]);
			setIsLoading(true);
			setEntitesDetail([]);

			const initialEntityDetail = await fetchEntityDetails(
				placeName,
				userDetail,
				city ?? "",
				"",
				{},
				"",
			);

			console.log("response is:", JSON.stringify(initialEntityDetail.data));
			setPreviousChatLoading(true);

			navigate(`/story-carousel`, {
				state: {
					clickedIndex: 0,
					initialEntityDetail: initialEntityDetail.data.entity,
					allEntities: [{ title: placeName, images: image }],
					city: "",
					country: "",
					videoHighlight: true,
					itinerary: true
				}
			});
		}
		catch (error) {
			console.error('Fetch error:', error);
		}
		finally {
			setIsLoading(false);
		}
	}
	// const handleChange = async () => {

	// 	onOpen();			
	// };
	return (
		<Box w='full' >
			<VStack align='start' spacing={'7px'} w='full' p={0}>
				<Image
					mt='20px'
					mb='10px'
					src={image}
					borderRadius='12px'
					objectFit='cover'
					w={width}
					h={height}
					onClick={() => {
						handleClick(place_name, city);
					}}
				/>



				<HStack w={'100%'} justifyContent='space-between' alignItems='center'>
					{name && (
						<Text
							w={"90%"}
							fontFamily={'Poppins'}
							fontSize={'13px'}
							fontWeight={'600'}
							color={'#FFFFFFD4'}
							lineHeight={'133%'}
						>
							{name}
						</Text>
					)}
					<IconButton
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							handleClick(place_name, city);
						}}
						variant={'ghost'}
						aria-label='Expand'
						justifyContent={'flex-end'}
						boxSize={3}
						icon={<Image src='/icons/Cright.svg' alt='Expand' boxSize={3} />}

					/>
				</HStack>
				<HStack w={'100%'} justifyContent='space-between' alignItems='center'>
					{address
						&& (
							<Text
								fontFamily={'Poppins'}
								fontSize={'11px'}
								fontWeight={'400'}
								color={'#FFFFFF54'}
								lineHeight={'13px'}
								letterSpacing={"0%"}
								noOfLines={1}
							>
								{address}
							</Text>
						)}
					{costINR && costINR !== '0'
						&& (
							<Text
								fontFamily={'Poppins'}
								fontSize={'11px'}
								fontWeight={'400'}
								color={'#FFFFFF54'}
								lineHeight={'13px'}
								noOfLines={1}
							>
								{costINR ? (
									<>
										Cost <Text
											as={'span'}
											fontWeight={'700'}
											color={'#FFFFFFED'}
										>
											₹{costINR}
										</Text>
									</>
								) : ''}
							</Text>
						)}
				</HStack>

				<HStack w={'100%'} h={'45px'} justifyContent='space-between' alignItems='center' mt={'11px'}>
					{booking_link && (
						<Button
							w={"75%"}
							bg={"#FFFFFF0D"}
							variant={"ghost"}
							h={'100%'}
							rounded={'8px'}

							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								window.open(booking_link, '_blank');
							}}
						>
							<Text
								fontFamily={'Poppins'}
								fontSize={'13px'}
								fontWeight={'600'}
								color={'#AE7CFF'}
								lineHeight={'17px'}
							>
								Book tickets
							</Text>
						</Button>
					)}
					{mapslink && (
						<Button
							w={"20%"}
							bg={"#FFFFFF0D"}
							variant={"ghost"}
							h={'100%'}
							rounded={'8px'}

							onClick={() => {
								window.open(mapslink, '_blank');
							}}
						>
							<Image src='/icons/route1.svg' alt='map icon' boxSize={'5'} />
						</Button>
					)}
				</HStack>
			</VStack>
			<VStack w={'full'} mt={'18px'} mb={'25px'}>
				<Button
					onClick={() => {
						onOpen();
					}}
					w={'full'}		
					variant={'link'}
					
				>
					<Text
						fontFamily="Poppins"
						fontWeight="600"
						fontSize="13px"
						lineHeight="19px"
						letterSpacing="0%"
						textAlign="center"
						textDecoration="underline"
						textDecorationStyle="solid"
						textDecorationThickness="0%"
						color={'#FFFFFFA1'}

					>
						Don’t like this hotel? Check other options
					</Text>
				</Button>
			</VStack>
			<ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
				<Option day={day} entity={
					{
						name: name,
						place_name: place_name,
						address: address,
						city: city,
						type: "accommodation",
					}
				}
					handleClose={onClose}
				/>
			</ModalWithBackdrop>
		</Box>
	);
};

const ImageGrid: React.FC<ImageGridProps> = ({ accommodation, day }) => {
	const isSingleImage = accommodation?.length === 1;

	return (
		<Box>
			{isSingleImage ? (
				<>
					<ImageView {...accommodation?.[0]} day={day} width='full' height='120px' />
					<Box
						borderBottom={'1px solid #FFFFFF21'}
						mt={[4, 4, 8]}
						mb={[4, 4, 8]}
						display={accommodation?.[0]?.name ? 'block' : 'none'}
					/>
				</>
			) : (
				<>
					<SimpleGrid columns={[2, 2, 6]} spacing={'20px'} mx={1}>
						{accommodation?.map((imageProps: any, index: number) => (
							<ImageView
								key={index}
								{...imageProps}
								day={day}
								width='175px'
								height='116px'
							/>
						))}

					</SimpleGrid>
					<Box
						borderBottom={'1px solid #FFFFFF21'}
						mt={[4, 4, 8]}
						mb={[4, 4, 8]}
						display={accommodation?.[0]?.name ? 'block' : 'none'}
					/>
				</>
			)}
		</Box>
	);
};

export default ImageGrid;
