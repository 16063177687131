import { Box, VStack, useDisclosure } from "@chakra-ui/react";
import Header from "./Header";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import useAuthRouteProtector from "../../hooks/useAuthRouteProtector";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Collection from "../../pages/Collection";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";
import CollectionSearch from "../Collection-flow/CollectionSearch";
import Whatsapp from "../Banner/Whatsapp";
import TrendingVideos from "../Banner/TrendingVideos";

const LandingPage: React.FC = () => {
  const { isAuthenticated } = useAuthRouteProtector();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const alreadyUser = useLocation().state?.alreadyUser;

  useEffect(() => {
    if (alreadyUser) {
      onOpen();
    }
    // eslint-disable-next-line
  }, [alreadyUser]);

  return (
    <>
      {/* Signin Modal */}
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>

      {/* Fixed container to prevent whole page scrolling */}
      <Box
        position="fixed"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflow="hidden"
        bg="black"
      >
        {/* Gradient overlay */}
        <Box
          position={"absolute"}
          top={0}
          left={0}
          w={"100%"}
          h={"80vh"}
          zIndex={1}
          bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
        />

        {/* Fixed Header */}
        <Box
          position="absolute"
          top={0}
          left={0}
          w="100%"
          zIndex={10}
        >
          <Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />
        </Box>

        {/* Scrollable Content Area */}
        <Box
          position="absolute"
          top={0}
          left={0}
          right={0}
          bottom={0}
          overflowY="auto"
          overflowX="hidden"
          zIndex={5}
          css={{
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              width: '6px',
              background: '#0a0a0a',
            },
            '&::-webkit-scrollbar-thumb': {
              background: '#333',
              borderRadius: '24px',
            },
            'scrollbarWidth': 'thin',
            'msOverflowStyle': 'none',
          }}
        >
          {/* Search Box Container */}
          <VStack
            pt="19%"  // Space for the fixed header
            w={"100%"}
            pb={4}
            px={'5%'}
            spacing={4}
            bg="#29252D"
            borderBottomRadius="33px"
            mt="0"
          >
            <Box
              bgImage={"url('/images/assest.svg')"}
              bgSize="cover"
              bgPosition="center"
              mt={0}
              h={"167px"}
              p={4}
              border={"2px solid #FFFFFF12"}
              w={"100%"}
              pt={6}
              borderRadius={'24px'}
              mx={3}
            >
              <CollectionSearch />
            </Box>
          </VStack>

          {/* Main content */}
          <VStack w="100%" spacing={6} pt={3} pb={20} mt={3}>
            <Whatsapp />
            <TrendingVideos />
            <Box pt={2}>
              <Collection />
            </Box>
          </VStack>

          {/* Add padding at the bottom for the footer */}
          <Box h="80px" />
        </Box>

        {/* Fixed Footer */}
        <Box
          position="absolute"
          bottom={0}
          left={0}
          width="100%"
          zIndex={10}
        >
          <ItineraryFooter />
        </Box>
      </Box>
    </>
  );
};

export default LandingPage;