export const getUserLocation = (): Promise<{ latitude: number; longitude: number }> => {
  return new Promise((resolve, reject) => {
    // Check if location is stored in localStorage first
    const storedLocation = localStorage.getItem('userLocation');
    if (storedLocation) {
      try {
        const parsedLocation = JSON.parse(storedLocation);
        if (parsedLocation && 
            typeof parsedLocation.latitude === 'number' && 
            typeof parsedLocation.longitude === 'number') {
          resolve(parsedLocation);
          return;
        }
      } catch (error) {
        // Invalid JSON in localStorage, will request new location
        localStorage.removeItem('userLocation');
      }
    }

    // Request user's location if not available in localStorage
    if (!navigator.geolocation) {
      reject(new Error("Geolocation is not supported by your browser."));
      return;
    }

    // Use getCurrentPosition consistently across all platforms
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const location = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        };
        
        // Store in localStorage for future use
        localStorage.setItem('userLocation', JSON.stringify(location));
        resolve(location);
      },
      (error) => {
        // Handle specific geolocation errors
        let errorMessage = "Unable to retrieve your location.";
        
        if (error.code === 1) {
          errorMessage = "Location access denied. Please enable location permissions.";
        } else if (error.code === 2) {
          errorMessage = "Location unavailable. Please try again later.";
        } else if (error.code === 3) {
          errorMessage = "Location request timed out. Please check your connection.";
        }
        
        reject(new Error(errorMessage));
      },
      {
        enableHighAccuracy: true,
        timeout: 15000,
        maximumAge: 0
      }
    );
  });
};