import {
  Box,
  Flex,
  VStack,
  Button,
  Text,
  HStack,
  IconButton,
  Image,
  useDisclosure,
  Spinner,
  Icon,
  Avatar,
} from "@chakra-ui/react";
import { keyframes } from "@emotion/react";
import { useState, Suspense, useEffect, useContext, useCallback } from "react";
import InfluencerId from "./InfluencerId";
import { BiSolidDislike } from "react-icons/bi";
import InfLocationDetail from "./InfLocationDetail";
import ReactPlayer from "react-player";

import { ToastBucketList } from "../ToastBucketList";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import { IoPlaySharp } from "react-icons/io5";
import useAxios from "../../hooks/useAxios";
import { AppContext } from "../../context/AppContext";
import { Element } from 'react-scroll';
import ToggleTip from "../ToggleTip/ToggleTip";
import * as amplitude from '@amplitude/analytics-browser';

const moveUpDown = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0); }
`;

interface InfluencersVideoProps {
  influencers: any;
  currentIndex: number;
  handleNext: () => void;
  handlePrevious: () => void;
  isLink?: boolean;
  isBucketList?: boolean;
}

const InfluencersVideo1: React.FC<InfluencersVideoProps> = ({
  influencers,
  currentIndex,
  handleNext,
  handlePrevious,
  isLink = false,
  isBucketList = false,
}) => {
  const myAppUrl = process.env.REACT_APP_URL;
  const context: any = useContext(AppContext);
  const { likeSearches, setLikeSearches, shareModal } = context;
  const { fetchData } = useAxios();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showScrollUp, setShowScrollUp] = useState(true);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showThumbnail, setShowThumbnail] = useState(true);
  const [playUrl, setPlayUrl] = useState("https://www.xyz.com/");
  const [isLiked, setIsLiked] = useState(false);
  const [showAddedToBucket, setShowAddedToBucket] = useState(false);


  const toShow = process.env.REACT_APP_ENV as string;

  useEffect(() => {
    setShowThumbnail(true);
    if (influencers && influencers[currentIndex]) {
      console.log("current index", currentIndex);
      // setThumbnail(influencers[currentIndex]?.preview_image);
      setPlayUrl(influencers[currentIndex]?.video_url);
    }
    // if (isLink) {
    //   // setThumbnail(influencers[currentIndex]?.preview_image);
    //   setPlayUrl(influencers[currentIndex]?.video_play_url?.media_url);
    // }
  }, [currentIndex, influencers]);

  const fetchVideo = async (video: string) => {
    console.log("passing this url", video);
    amplitude.track('video_play_clicked', { url: video, page: 'entity_video' });
    const { data, statusCode, error } = await fetchData({
      method: "POST",
      url: "v1/video_metadata",
      options: {
        data: {
          url: video,
          play_url_required: true,
        },
        headers: { "Content-Type": "application/json" },
      },
      auth: false,
    });
    if (statusCode === 200) {
      if (data) {
        await setPlayUrl(data?.["play_url"]);
        setShowThumbnail(false);
      }
    } else {
      console.log(error);
    }
  };
  const handleVideoClick = (video: string) => {
    // console.log("isPlaying", isPlaying);
    if (!isPlaying && (playUrl === "https://www.xyz.com/" || playUrl === influencers[currentIndex]?.video_url)
      // && influencers[currentIndex]?.video_play_url?.media_url===""
    ) {
      setShowThumbnail(true);
      setIsPlaying((prevState) => !prevState);
      if (playUrl === influencers[currentIndex]?.video_url && playUrl.includes("youtube")) {
        setShowThumbnail(false);
      }
      else
        fetchVideo(video);

    } else {
      setShowThumbnail(false);
      console.log("video", video);
      setIsPlaying((prevState) => !prevState);
      console.log("isPlaying", isPlaying);
    }
  };
  // useEffect(() => {
  // 	console.log("i was callllled")
  // 	setThumbnail(influencers[currentIndex]?.preview_image);
  // });

  //   const handlers = useSwipeable({
  //     onSwipedUp: handleSwipeUp,
  //     trackMouse: true,
  //   });

  const handleShareClick = useCallback(
    async (title?: any, url?: any) => {
      await fetch('https://tiny.rahee.ai/share', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ url: url }),
      })
        .then(response => response.json())
        .then(data => {
          console.log('Success:', data);
          if (data?.short_url)
            shareModal.handleShareClick(data?.short_url, title);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    },
    [shareModal]
  );

  useEffect(() => {
    setIsLiked(false);
    if (influencers.length > 0) {
      // Ensure influencers has content

      console.log(likeSearches);

      for (let video of likeSearches) {
        if (video["url"] === influencers[currentIndex]?.video_url || video["url"].includes(influencers[currentIndex]?.video_id) || video["url"].includes(influencers[currentIndex]?.video_play_url?.video_id)) {
          setIsLiked(true);
        }
      }
    }
  }, [likeSearches, currentIndex, influencers]); // Add influencers to dependencies

  const likeVideo = async (isLiked: boolean) => {
    const token = localStorage.getItem("token");

    if (token) {
      const body = {
        title: influencers[currentIndex]?.title,
        video_metadata_id: influencers[currentIndex]?.video_metadata_id ?? influencers[currentIndex]?.video_metadata_id,
        // city:
        //   influencers[currentIndex]?.city === ""
        //     ? undefined
        //     : influencers[currentIndex]?.city,
        // countries: influencers[currentIndex]?.country ?? {},
        description: influencers[currentIndex]?.description,
        tags: influencers[currentIndex]?.tags,
        url: influencers[currentIndex]?.video_url,
        previewImage: influencers[currentIndex]?.preview_image,
        place_metadata_id: influencers[currentIndex]?.place_metadata_id ?? undefined,
        location: influencers?.location,
        flightStartingPrice: influencers[currentIndex]?.flightStartingPrice,
      };

      try {
        if (isLiked) {
          // Dislike the influencer
          setIsLiked(false);
          amplitude.track('disLiked', {
            type: "video", title: influencers[currentIndex]?.title, location: {
              city: influencers[currentIndex]?.city === ""
                ? undefined
                : influencers[currentIndex]?.city, country: influencers[currentIndex]?.country ?? {}
            }, page: "entity_video", url: influencers[currentIndex]?.video_url
          });
          const { statusCode } = await fetchData({
            method: "POST",
            url: "user/dislikeSearch",
            options: {
              data: { ...body, token },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          });
          if (statusCode === 200) {
            const tempLikeVideos = [];
            for (let video of likeSearches) {
              if (!(video["url"] === body.url)) {
                tempLikeVideos.push(video);
              }
            }
            setLikeSearches(tempLikeVideos);
          }
          else {
            setIsLiked(true);
          }
        } else {
          // Like the influencer
          setIsLiked(true);
          amplitude.track('liked', {
            type: "video", title: influencers[currentIndex]?.title, location: {
              city: influencers[currentIndex]?.city === ""
                ? undefined
                : influencers[currentIndex]?.city, country: influencers[currentIndex]?.country ?? {}
            }, page: "entity_video", url: influencers[currentIndex]?.video_url
          });
          const { statusCode } = await fetchData({
            method: "POST",
            url: "user/likeSearch",
            options: {
              data: { ...body, token },
              headers: { "Content-Type": "application/json" },
            },
            auth: false,
          });
          if (statusCode === 200) {

            setLikeSearches([...likeSearches, body]);
            setShowAddedToBucket(true);
            setTimeout(() => {
              setShowAddedToBucket(false);
            }, 3000);
          }
          else {
            setIsLiked(false);
          }
        }
      } catch (error) {
        console.error("Error while liking/disliking the influencer:", error);
      }
    }
  };
  const handleLike = (isLiked: boolean) => {
    const token = localStorage.getItem("token");
    if (token) {
      likeVideo(isLiked);
    } else {
      amplitude.track("login_triggered", { buttonName: "Log-in", page: "entity_video" });
      onOpen();
    }
  };
  // if (!influencers || !influencers[currentIndex]) {
  //   return <Text>No influencer data available</Text>;
  // }

  // console.log(influencers[currentIndex]);
  const handleN = () => {
    setIsPlaying(false);
    setPlayUrl("https://www.xyz.com/");
    handleNext();
    // setShowThumbnail(true);
    // setVideoUrl(influencers[currentIndex]?.video_url);
  };
  const handleP = () => {
    setIsPlaying(false);
    setPlayUrl("https://www.xyz.com/");
    handlePrevious();
    // setShowThumbnail(true);
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const viewportHeight = 30;

      // console.log(`Scroll Position: ${scrollPosition}`);
      // console.log(`Viewport Height: ${viewportHeight}`);

      if (scrollPosition > viewportHeight) {
        console.log('Hiding');
        setShowScrollUp(false);
      } else {
        console.log('Showing ');
        setShowScrollUp(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>
      <Box overflowY={"auto"} overflowX={'hidden'}>
        <VStack
          id="story-container"
          width="100%"
          height="100vh"
          bg="black"
          overflowY="hidden"
          position={"relative"}
        >
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top="0"
            left="0"
            zIndex={10} // Ensure it's above the children
            bgGradient="linear-gradient(180deg, #111417 0%, rgba(7, 9, 11, 0) 30%)"
            pointerEvents="none" // Prevent interaction with overlay
          />
          <Box
            position="absolute"
            width="100%"
            height="100%"
            top="0"
            left="0"
            zIndex={10} // Ensure it's above the children
            bgGradient="linear-gradient(0deg, #111417 7.37%, rgba(7, 9, 11, 0) 80%)"
            pointerEvents="none" // Prevent interaction with overlay
          />
          <Box position="absolute" top={0} left={0} right={0} bottom={0}>
            <ReactPlayer
              zIndex={-1}
              url={playUrl}
              // light={showThumbnail ? influencers[currentIndex]?.preview_image : false}
              playing={
                // playUrl!== influencers[currentIndex]?.video_url && 
                isPlaying}
              muted={false} // Mute the video
              loop={true} // Loop the video
              width="100%" // Full width
              height="100vh" // Full height
              // onClickPreview={(e) => {e.stopPropagation();}}
              onClick={() => {
                console.log("clicked", isPlaying);
                handleVideoClick(influencers[currentIndex]?.video_url);
              }}
              style={{ position: "absolute", top: 0, left: 0 }}
              config={{
                file: {
                  attributes: {
                    style: {
                      width: "100%",
                      height: "100vh",
                      objectFit: "cover",
                    },
                  },
                },
              }}
            // fallback={
            //   <Image
            //     borderRadius={{ base: 0, lg: `calc(20px * 0.8)` }}
            //     src={influencers[currentIndex]?.preview_image}
            //     alt="influencer"
            //     height={{ base: `calc(800px * 0.8)`, lg: 'auto' }}
            //     w={{ 'lg': `calc(300px * 0.8)`, '2xl': '300px' }}
            //     minH={{ 'lg': `calc(485px * 0.8)`, '2xl': '600px' }}
            //   />
            // }
            />
            {showThumbnail && (
              <Box

                onClick={() => {
                  console.log("clicked", isPlaying);
                  handleVideoClick(influencers[currentIndex]?.video_url)
                }}>
                <Image
                  src={influencers[currentIndex]?.preview_image}
                  alt="Video Thumbnail"
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  objectFit="cover"
                  zIndex="5"

                />

                <Icon
                  position="absolute"
                  top="50%"
                  left="50%"
                  zIndex={20}
                  transform="translate(-50%, -50%)"
                  //  width="400px"
                  //  height="400px"

                  as={IoPlaySharp}
                  color="white"
                  boxSize="60px"
                  opacity={0.5}
                />
              </Box>
            )}

            {isPlaying && (playUrl === "https://www.xyz.com/"
              // || playUrl === influencers[currentIndex]?.video_url
            ) && (
                <Box
                  position="absolute"
                  top="50%"
                  left="50%"
                  zIndex={30}
                  transform="translate(-50%, -50%)"
                >
                  <Spinner size="xl" color="rgba(255, 255, 255, 0.40)" />
                </Box>)}

            <IconButton
              aria-label="prev"
              icon={<Image src="/icons/left_anchor.svg" alt="like" />}
              variant="ghost"
              size="lg"
              position="absolute"
              top="50%"
              left="5%"
              transform="translateY(-50%)"
              borderRadius="full"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "transparent" }}
              _focus={{ boxShadow: "none" }}
              _disabled={{ visibility: "hidden" }}
              isDisabled={currentIndex === 0}
              onClick={handleP}
              zIndex={10}
            />

            <IconButton
              aria-label="next"
              icon={<Image src="/icons/right_anchor.svg" alt="like" />}
              variant="ghost"
              size="lg"
              position="absolute"
              top="50%"
              right="5%"
              transform="translateY(-50%)"
              borderRadius="full"
              _hover={{ bg: "transparent" }}
              _active={{ bg: "none" }}
              _focus={{ boxShadow: "none" }}
              _disabled={{ visibility: "hidden" }}
              isDisabled={currentIndex === influencers?.length - 1}
              onClick={handleN}
              zIndex={10}
            />


            <VStack
              position="absolute"
              bottom={'30px'}
              width="100%"
              left={0}
              zIndex={99}
              spacing={6}


            >
              <VStack
                alignItems={"center"}
                justifyContent={"space-between"}
                w={"100vw"}
                px={3}
                py={8}
                position={"relative"}
              >
                {/* <Flex flexDir={"column"} my={4} alignItems={"flex-start"} >
                  <Box py={5} >
                    <InfluencerId infId={influencers?.[currentIndex]} />
                  </Box>
                  <Text
                    fontFamily="Poppins"
                    fontSize="20px"
                    fontWeight="700"
                    lineHeight="25px"
                    w={"68vw"}
                    color={"#FFFFFFED"}
                    noOfLines={3}
                    letterSpacing="0.23455415666103363px"
                    textAlign="left"
                  >
                    {influencers[currentIndex]?.["title"]}
                  </Text>
                </Flex> */}
                <Box display={'flex'} w={'100%'}>
                  <Avatar w={'37px'} h='37px' my='auto' mr={'10px'} />
                  <Box
                    py={'10px'}
                    display='flex'
                    flexDir={'column'}
                    justifyContent={'space-between'}>
                    <Text
                      fontFamily='Poppins'
                      fontSize='13px'
                      fontWeight='400'
                      lineHeight='18.51px'
                      mr='4px'
                      color='rgba(255, 255, 255, 0.63)'>
                      Video curated by
                    </Text>
                    <Text
                      color={'white'}
                      fontFamily='Poppins'
                      fontSize='13px'
                      fontWeight='700'
                      lineHeight='18.51px'>
                      {influencers[currentIndex]?.video_play_url?.author}
                    </Text>

                  </Box>
                </Box>
                <Flex
                  justifyContent={'space-between'}
                  alignItems={'flex-start'}
                  className='like-and-share'
                  width={'100%'}
                  zIndex={52}>
                  {/* <LikeAndShare /> */}
                  <Text
                    noOfLines={3}
                    color={'white'}
                    fontWeight={700}
                  // sx={{
                  // 	display: { base: 'inherit', md: 'none' },
                  // 	color: '#fff',
                  // 	// fontSize: '20px',
                  // 	fontWeight: 700,
                  // 	// lineHeight: '25px',
                  // 	// letterSpacing: '0.23455415666103363px',
                  // 	// textAlign: 'left',
                  // }}
                  >
                    {influencers[currentIndex]?.["title"]}
                  </Text>

                  <HStack spacing={0} zIndex={20} alignItems={'flex-start'} >
                    <IconButton
                      aria-label="Like"
                      // boxShadow={"0px 4px 4px 0px #00000040"}
                      icon={
                        <Image
                          src={
                            isLiked ? "/icons/like.svg" : "/icons/white-heart.svg"
                          }
                          borderRadius="full"
                          boxShadow={"0px 4px 4px 0px #00000040"}
                          alt="like"
                        />
                      }
                      variant="ghost"
                      size="lg"
                      borderRadius="full"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleLike(isLiked);
                      }}
                      _hover={{ bg: "transparent" }}
                      _active={{ bg: "transparent" }}
                      _focus={{ boxShadow: "none" }}
                    />
                    <IconButton
                      aria-label="Share"
                      icon={<Image src="/icons/white-share.svg" alt="share"
                        borderRadius="full"
                        boxShadow={"0px 4px 4px 0px #00000040"}
                      />}
                      variant="ghost"
                      // boxShadow={"0px 4px 4px 0px #00000040"}
                      size="lg"
                      borderRadius="full"
                      _hover={{ bg: "transparent" }}
                      _active={{ bg: "transparent" }}
                      _focus={{ boxShadow: "none" }}
                      onClick={(e) => {
                        e.stopPropagation();
                        const title = influencers[currentIndex]?.["title"];
                        const link = `${myAppUrl}/video-detail?link=${influencers[currentIndex]?.["video_url"]}`;
                        amplitude.track('share', { type: 'video', title: title, page: 'entity_video', url: link });
                        handleShareClick(title, link);
                      }}
                    />
                  </HStack>
                </Flex>
              </VStack>
              {showScrollUp && (<HStack
                spacing={0}
                position={"absolute"}
                bottom={0}
                zIndex={11}
                justifyContent={"center"}
                animation={`${moveUpDown} 1s infinite`}
              >
                {/* {influencers?.[currentIndex]?.['highlights']?.length > 0 ? (
                  <Text fontSize="13px" color="white" fontWeight="500">
                    SCROLL UP FOR MORE
                  </Text>)
                  : (
                    <Text fontSize="13px" color="white" fontWeight="500">
                      {"     "}
                    </Text>
                  )} */}
                {influencers?.[currentIndex]?.['highlights']?.length > 0 && (
                  <Button
                    bg="inherit"
                    variant="link"
                  //  onClick={handleSwipeUp}
                  >
                    <Image src="/icons/ArrowUp.svg" alt="swipe up" />
                  </Button>
                )}
                {/* <ScrollLink to="infLocationDetail" smooth={true} duration={500}>
                  <Button
                    bg="inherit"
                    variant="link"
                  //  onClick={handleSwipeUp}
                  >
                    <Image src="/icons/ArrowUp.svg" alt="swipe up" />
                  </Button>
                </ScrollLink> */}
              </HStack>)}





            </VStack>
          </Box>
          <Box
            mt={2}
            position="absolute"
            left={0}
            zIndex={99}
            bottom={3}
          >
            {toShow === "DEV" && (<IconButton
              position={"relative"}
              aria-label="info"
              icon={<BiSolidDislike />}
              colorScheme="gray"
              onClick={() => { }

              }
              variant={"link"}
            />)}
          </Box>
          <Box
            mt={2}
            position="absolute"
            right={0}
            bottom={3}
          >
            <ToggleTip link={influencers[currentIndex]?.video_url || '#'} />
          </Box>
        </VStack>
        <Box
          pt={"6"}
          bg="#000000"
          h={"100vh"}
          overscroll={"none"}
          overflowX={"hidden"}
        >
          <Suspense fallback={<div>Loading...</div>}>
            <Element name="infLocationDetail">
              <InfLocationDetail
                influencerData={influencers[currentIndex]}
              />
            </Element>
          </Suspense>
        </Box>
        {showAddedToBucket && <ToastBucketList />}
      </Box>
    </>
  );
};

export default InfluencersVideo1;
