import { Button, Image, Text, Flex } from "@chakra-ui/react";

interface ItineraryProps {
    image: string;
    title: string;
    dates: string;
    number_of_people: number;
    onEdit: () => void;
    onDelete: () => void; // New prop for delete functionality
}

const ItineraryCardLayout: React.FC<ItineraryProps> = ({ image, title, dates, number_of_people, onEdit, onDelete }) => {
    return (
        <Flex flexDir={'column'} w={'90vw'} >
            <Flex alignItems="flex-start">
                <Image
                    src={image}
                    borderRadius="7.09px"
                    boxSize={'56.03px'}
                    border="1.06px solid #313030"
                    mr={4}
                />
                <Flex flexDir={"column"} gap={2}>
                    <Text
                        color={'#FFFFFFD4'}
                        fontFamily="Poppins"
                        fontSize="20px"
                        fontWeight="700"
                        lineHeight="26.94px"
                        letterSpacing="-0.5611507892608643px"
                        textAlign="left"
                    >{title}</Text>
                    <Text
                        fontFamily="Poppins"
                        fontSize="13px"
                        fontWeight="500"
                        lineHeight="16px"
                        color={'#FFFFFFA1'}

                    >{dates} • {number_of_people} {number_of_people > 1 ? 'Adults' : 'Adult'}</Text>
                    <Flex mt={2}>
                        <Button
                            borderRadius={'99px'}
                            w={'68px'}
                            fontWeight={'700'}
                            bg={'#2B2A2C'}
                            color={'#AE7CFF'}
                            size="sm"
                            onClick={onEdit}
                            mr={2}
                        >
                            + Edit
                        </Button>
                        <Button
                            borderRadius={'99px'}
                            w={'68px'}
                            fontWeight={'700'}
                            bg={'#2B2A2C'}
                            color={'#FF7C7C'}
                            size="sm"
                            onClick={onDelete}
                        >
                            Delete
                        </Button>
                    </Flex>
                </Flex>
            </Flex>

        </Flex>
    );
};

export default ItineraryCardLayout;