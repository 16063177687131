import React, { useContext, useEffect } from 'react';
import { 
	// Box, Flex, 
	VStack } from '@chakra-ui/react';
import SearchBox from '../components/SearchBox';
// import { pixels } from '../theme';
// import CardSlider from '../components/CardSlider';
// import HomePageFooter from '../components/HomePageFooter';
import SearchPageSection from './SearchPageSection';
// import SideBar from '../components/SideBar';
import { AppContext } from '../context/AppContext';

const SearchPage: React.FC = () => {
	const { setIp }: any = useContext(AppContext);

	useEffect(
		() => {
			const fetchIp = async () => {
				try {
					const response = await fetch('https://api.ipify.org?format=json');
					const data = await response.json();
					setIp(data.ip);
				} catch (error) {
					console.error('Error fetching the IP address:', error);
				}
			};

			fetchIp();
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);
	return (
		<VStack
			overflow={'auto'}
			// flexDir={'column'}
			// alignItems={'center'}
			bg='linear-gradient(118deg, #272529 5.68%, #111012 59.38%)'
			// bg={'red'}
			// pt={{
			// 	'base': 0,
			// 	'lg': `65px`,
			// 	'2xl': pixels['65pixels'],
			// }}
			// pb={pixels['150pixels']}
			w='100%'
			h={"100%"}
			>
			<SearchBox suggestionBackground='black' />
			<SearchPageSection />

		</VStack>
	);
};

export default SearchPage;
