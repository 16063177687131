import {
  Box,
  Flex,
  Text,
  SkeletonText,
  HStack,
  Heading,
  VStack,
  Image,
} from "@chakra-ui/react";
import Header from "./Header";
import InputField from "./InputField";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import ChatCard from "./ChatCard";
// import { useNavigate } from "react-router-dom";
import { formatDateTime } from "../../utilities/dateTimeFormat";
import { fetchEntityDetails } from "../../services/apiService";
import { useLoading } from "../../context/LoadingContext";
import { useNavigate } from "react-router-dom";
import useSmartNavigate from "../../hooks/useSmartNavigate";

const Chat: React.FC = () => {
  // const { navigateSmart } = useNavigationHistory();
  // const navigate = useNavigate();
  const context: any = useContext(AppContext);
  const {
    chatEntries,
    isChatLoading,
    title,
    setPreviousChatLoading,
    setChatEntries,
    setTitle,
    setCurrChatId,
    currChatId,
    setOnBehalf,
    entitesDetail,
    setEntitesDetail,
    setInfluencerData,
    userDetail,
    setStryIndex,
  } = context;
  const router = useNavigate();
  const navigate = useSmartNavigate();
  const { setIsLoading } = useLoading();
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Scroll to bottom when chatEntries change
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chatEntries]);

  const handleNewChatButton = () => {
    setChatEntries([]);
    setTitle("");
    setCurrChatId("");
    setOnBehalf({});
    navigate("/chat");
  };

  const handleChatButton = () => {
    navigate("/navigate", {
      state: { index: 3 }
    });
  };

  const handleCardClick = async (
    clickedIndex: number,
    clickedTitle: string,
    allEntities: any[],
    entity: any
  ) => {
    setStryIndex(null);
    const secondLastMessage = chatEntries[chatEntries.length - 2]?.text;
    const lastMessage = chatEntries[chatEntries.length - 1]?.text;
    const contxt = {
      query: secondLastMessage,
      answer: lastMessage,
    };
    // Fetch entity details for the clicked title
    console.log("clickedTitle is:", clickedTitle);

    const curr = entitesDetail;

    if (curr.length > 0) {
      const allMatch = allEntities.every((entity: any, index: number) => {
        console.log("entity title ", entity.title);
        console.log("curr title index ", curr[index]?.title);

        return entity.title === curr[index]?.title;
      });
      console.log("Entity detail in chat is ", entitesDetail);

      if (allMatch) {
        console.log("matched");
        const initialEntityDetail = allEntities.find(
          (entity) => entity.title === clickedTitle
        );
        setPreviousChatLoading(true);
        navigate("/story-carousel", {
          state: {
            clickedIndex: clickedIndex,
            initialEntityDetail: initialEntityDetail,
            allEntities: allEntities,
          }
        });
      } else {
        console.log("not matched");
        setInfluencerData([]);
        setIsLoading(true);
        setEntitesDetail([]);
        try {
          console.log("userDetail not matched", userDetail);

          const initialEntityDetail = await fetchEntityDetails(
            clickedTitle,
            userDetail,
            entity?.city ?? "",
            entity?.country ?? "",
            contxt,
            entity?.highlight_id ?? "",
            entity?.place_metadata_id ?? "",
            entity?.travel_destination ?? ""
          );

          // console.log("response is:", JSON.stringify(initialEntityDetail.data));
          setPreviousChatLoading(true);
          navigate("/story-carousel", {
            state: {
              clickedIndex: clickedIndex,
              initialEntityDetail: initialEntityDetail.data.entity,
              allEntities: allEntities,
            }
          });
        } catch (error) {
          console.error("Error fetching entity details:", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      console.log("new one");
      setIsLoading(true);
      try {
        setInfluencerData([]);
        setEntitesDetail([]);
        console.log("userDetail new one", userDetail);

        const initialEntityDetail = await fetchEntityDetails(
          clickedTitle,
          userDetail,
          entity?.city ?? "",
          entity?.country ?? "",
          contxt,
          entity?.highlight_id ?? "",
          entity?.place_metadata_id ?? "",
          entity?.travel_destination ?? ""
        );

        // console.log("response is:", JSON.stringify(initialEntityDetail.data));
        setPreviousChatLoading(true);
        navigate("/story-carousel", {
          state: {
            clickedIndex: clickedIndex,
            initialEntityDetail: initialEntityDetail.data.entity,
            allEntities: allEntities,
          }
        });
      } catch (error) {
        console.error("Error fetching entity details:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex bg={"#FFFFFF"} h="100%">
      {/* Header remains fixed */}
      <Box
        bg={"#FFFFFF"}
        position={"fixed"}
        top={0}
        left={0}
        w={"100%"}
        zIndex={5}
      >
        <Header
          backIconSrc="/icons/arrow_back.svg"
          chatIconSrc="/icons/chat_filled.svg"
          addIconSrc="/icons/plus_outline.svg"
          handleBackButton={() => {
            router(-1);
          }}
          chatButtonOnClick={handleChatButton}
          addButtonOnClick={handleNewChatButton}
          bgColor="#2F2F320D"
          borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />

        {/* Chat Topic Bar */}
        <Flex
          borderTop={"1px solid #3A3C4221"}
          mt={"5px"}
          pt={"8px"}
          pb={"7px"}
          pl={"18px"}
          boxShadow={"0px 7px 7px 0px #00000008"}
        >
          <Text
            fontSize={"11px"}
            fontFamily={"500"}
            letterSpacing={"-0.47px"}
            color={"#16181BA1"}
            noOfLines={1}
          >
            {title}
          </Text>
        </Flex>
      </Box>

      <Box bg={"#FFFFFF"} position={"fixed"} w="100%" h="100%">
        <Box
          flex={1}
          mt="18%"
          overflowY="auto"
          pt={4}
          pb={"13%"}
          // mb={"2%"}
          maxH={"82%"}
        // maxH="calc(100vh - 21vh)"
        >
          <Box mt={"20px"}>
            {chatEntries?.map((entry: any, index: number) => (
              <Flex
                justifyContent={
                  entry?.author === "user" ? "flex-end" : "flex-start"
                }
                key={index} // Added key here
                mb={2} // Margin between entries
              >
                {isChatLoading &&
                  entry?.author === "assistant" &&
                  entry?.text === "..." ? (
                  <SkeletonText
                    height="40px"
                    width="40%"
                    borderRadius="8px"
                    p={"10px"}
                    pb={"50px"}
                    noOfLines={3}
                  />
                ) : (
                  <Flex
                    direction={"column"}
                    maxW={"100vw"}
                    overflowX={"hidden"}
                  >
                    {/* For the Text */}

                    <Flex
                      mr={"12px"}
                      ml={"12px"}
                      justifyContent={
                        entry.author === "user" ? "flex-end" : "flex-start"
                      }
                    >
                      <Box
                        borderRadius={"8px"}
                        maxW={"227px"}
                        mx={1}
                        bg={
                          entry.author === "assistant" ? "#E8E9FF" : "#E0F0EA"
                        }
                      >
                        {entry.onBehalf && (
                          <HStack
                            borderRadius={"9px"}
                            bg={"#F9FAFC"}
                            h="72px"
                            w={"100%"}
                          >
                            <Image
                              src={entry.onBehalf.imageUrl}
                              alt={entry.onBehalf.title}
                              borderRadius={"12px 0px 0px 12px"}
                              objectFit={"cover"}
                              h={"72px"}
                              w={"35%"}

                            />
                            <Box py={"32px"} color={"#424242"} w={"65%"} >
                              <VStack
                                spacing={0}
                                alignItems={"flex-start"}
                                ml={"2%"}
                                mt={0}
                              >
                                <Heading
                                  fontSize="12px"
                                  letterSpacing={"-0.5px"}
                                  fontWeight={700}
                                  noOfLines={2}
                                >
                                  {entry.onBehalf.title}
                                </Heading>
                                <Heading
                                  fontSize={"11px"}
                                  letterSpacing={"-0.5px"}
                                  fontWeight={400}
                                >
                                  {entry.onBehalf.subtitle}
                                </Heading>
                              </VStack>
                            </Box>
                          </HStack>
                        )}

                        {entry?.text && (
                          <Text
                            fontFamily={"Poppins"}
                            color={"#16181B"}
                            fontSize={"14px"}
                            py={"10px"}
                            px={"12px"}
                            lineHeight={"19px"}
                            fontWeight={700}
                          >
                            {entry?.text}
                          </Text>
                        )}
                      </Box>
                    </Flex>
                    {entry?.type && entry?.type === "entity" && (
                      <Box pt="12px" pb={"12px"} w={"100%"} minW={"80dvw"}>
                        <Swiper
                          slidesPerView={"auto"}
                          loop={true}
                          breakpoints={{
                            320: {
                              slidesPerView: 1.2,
                            },
                            350: {
                              slidesPerView: 1.3,
                            },
                            360: {
                              slidesPerView: 1.35,
                            },
                            375: {
                              slidesPerView: 1.4,
                            },
                            400: {
                              slidesPerView: 1.5,
                            },
                            425: {
                              slidesPerView: 1.6,
                            },
                            480: {
                              slidesPerView: 1.7,
                            },
                            540: {
                              slidesPerView: 2.1,
                            },
                            640: {
                              slidesPerView: 3.2,
                            },
                            768: {
                              slidesPerView: 2.2,
                            },
                            1024: {
                              slidesPerView: 2.8,
                            },
                            1280: {
                              slidesPerView: 3.2,
                            },
                            1536: {
                              slidesPerView: 3.6,
                            },
                            1920: {
                              slidesPerView: 4,
                            },
                          }}
                        >
                          {entry.entities?.map((entity: any, index: number) => (
                            <SwiperSlide key={index}>
                              <ChatCard
                                imageSrc={entity.image}
                                altText=""
                                city={entity.city}
                                country={entity.country}
                                title={entity.title.replace(/<[^>]+>/g, "")}
                                buttonText="View Details"
                                onCardClick={() =>
                                  handleCardClick(
                                    index,
                                    entity.title,
                                    entry.entities?.map((h: any) => ({
                                      title: h.title,
                                      images: h.image,
                                      city: h?.city ?? "",
                                      country: h?.country ?? "",
                                      _id: entry?._id,
                                      conversation_id: currChatId,
                                      place_metadata_id:
                                        h?.place_metadata_id ?? "",
                                      search_query: h?.search_query ?? "",
                                      travel_destination:
                                        h?.travel_destination ?? "",
                                    })) || [],
                                    entity
                                  )
                                }
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Box>
                    )}
                    <Flex
                      justifyContent={
                        entry.author === "user" ? "flex-end" : "flex-start"
                      }
                    >
                      <Text
                        color={"#B5B5B5"}
                        fontSize={"11px"}
                        mt={entry.author === "user" ? 1 : entry.entities ? -3 : 1}
                        mx={1}
                        mr={"14px"}
                        ml={"14px"}
                        letterSpacing={"-0.47px"}
                      >
                        {formatDateTime(entry?.time)}
                      </Text>
                    </Flex>
                  </Flex>
                )}
              </Flex>
            ))}
            <div ref={bottomRef} />
          </Box>
        </Box>
      </Box>

      {/* Input Field */}
      <Box
        position={"fixed"}
        bottom={"10px"}
        bg={"white"}
        px={"31px"}
        py={3}
        w={"full"}
      >
        <InputField placeholder="Type here..." />
      </Box>
    </Flex>
  );
};

export default Chat;
