import { HStack, VStack, Heading, Image, Text, Checkbox, Box } from "@chakra-ui/react";

interface EntityCardProps {
    entities: any;
    onCheckboxChange: (entity: any, checked: boolean) => void;
}

const EntityCard: React.FC<EntityCardProps> = ({
    entities,
    onCheckboxChange
}) => {

    // const handleCheckIconClick = () => {
    //     console.log("CheckIcon clicked");
    //     // Add any additional logic here
    // };

    return (
        <>
            {entities.map(({ title, continent, country, city, image, verified, checked }: any) => (
                <HStack
                    key={title} // Add a key prop to each element in the list
                    spacing={4}
                    p={2}
                    w={'full'}
                    borderRadius="lg"
                    alignItems="flex-start"
                    justifyContent={"flex-start"}

                >
                    <HStack justify="space-between" w="full" alignItems="flex-start">

                        <HStack
                            w={"95%"}
                            alignItems={"flex-start"}
                            spacing={6}
                        >
                            <Box
                                w="64px"
                                h="64px"
                            >
                                <Image
                                    src={image}
                                    alt={title}
                                    objectFit={"cover"}
                                    w="100%"
                                    h="100%"
                                    borderRadius="8px"
                                />
                            </Box>

                            <VStack
                                flex={1}
                                alignItems="flex-start"
                                spacing={1}
                            >
                                <Heading
                                    fontFamily="Poppins"
                                    fontWeight="700"
                                    fontSize="14px"
                                    lineHeight="19px"
                                    letterSpacing="0%"
                                    color={"#FFFFFFED"}
                                >
                                    {title}
                                </Heading>
                                <Text
                                    fontFamily="Poppins"
                                    fontWeight="500"
                                    fontSize="12px"
                                    lineHeight="17px"
                                    letterSpacing="0%"
                                    textDecoration="underline"
                                    textDecorationStyle="solid"
                                    textDecorationThickness="0%"
                                    color={'#FFFFFFA1'}
                                >
                                    {[city, country].filter(Boolean).join(", ")}
                                </Text>
                            </VStack>
                        </HStack>
                        <Box w={"5%"}>
                            <Checkbox
                                size="lg"
                                colorScheme={""}
                                // variant={"outline"}             
                                bg="#373636"
                                // border= "1px solid" 
                                borderColor={"transparent"}
                                _checked={{
                                    // background:'#373636',
                                    borderRadius: "none",
                                    borderColor: "#6B46C1",
                                    // color: "white",
                                }}
                                isChecked={verified || checked}
                                onChange={(e) =>
                                    onCheckboxChange(
                                        { title, continent, country, city, image, verified, checked },
                                        e.target.checked
                                    )
                                }
                            />
                        </Box>

                    </HStack>
                </HStack>
            ))}
        </>
    );
};

export default EntityCard;