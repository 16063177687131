import React, { useContext } from 'react';
import { Box
	// , Flex, Skeleton, useDisclosure
 } from '@chakra-ui/react';
// import { pixels } from '../theme';
// import CityFlightDetail from '../components/CityFlightDetail';
import TopHighlights from '../components/TopHighlights';
import ImageSlider from '../components/ImageSlider';
// import VideoTags from '../components/VideoTags';
// import ScrollNextButton from '../components/ScrollNextButton';
// import ModalWithBackdrop from '../components/ModalWithBackdrop';
// import CountryPageDetail from '../components/CountryPageDetail';
import { AppContext } from '../context/AppContext';
// import VideoHeader from '../components/VideoHeader';
// import { Link } from 'react-scroll';
// import TrMapLeaflet from '../components/TrMapLeaf/TrMapLeaflet';

const SearchPageSection: React.FC<any> = () => {
	const {
		searchDetail,
		// searchDetailLoading,
		// videoHighlightDetail,
		// currentVideoIndex,
	}: any = useContext(AppContext);

	// const {
	// 	isOpen: isOpenDetailsModal,
	// 	onOpen: onOpenDetailsModal,
	// 	onClose: onCloseOpenDetailsModal,
	// } = useDisclosure();

	return (


		<Box w={'100%'} h={'100%'} overflow={'auto'} >
			<Box position={'relative'}>
				<ImageSlider items={searchDetail?.videos} />
			</Box>

			<Box w={'100%'} h={'100%'} position={"relative"} mt={10}>
				<TopHighlights />
			</Box>
		</Box>




	);
};

export default SearchPageSection;
