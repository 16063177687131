import {
    Box,
    SimpleGrid,
    Heading,
    useDisclosure
} from "@chakra-ui/react";
import DestinationCard from "../components/Collection-flow/DestinationCard";

import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import { useLoading } from "../context/LoadingContext";
import * as amplitude from "@amplitude/analytics-browser";
import { getUserLocation } from "../utilities/getUserLocation";
import ModalWithBackdrop from "../components/ModalWithBackdrop";
import SigninComponent from "../components/SigninComponent";
import { transformMessage } from "../utilities/transformMessage";
import useSmartNavigate from "../hooks/useSmartNavigate";

interface Story {
    title: string;
    city: string;
    country: string;
    continent: string;
    location: {
        lat: number;
        long: number;
    };
    image_attributions?: any;
    static_fields?: any[];
    follow_on_queries?: any;
    description: string;
    images?: string[];
    videos?: string[];
    tags: string[];
    otherDetail?: any[];
    google_maps_url?: string;
    social_links?: any;
    booking_details?: any;
    travel_destination?: any;
    identity_tag?: any;
    place_metadata_id?: any;      
}
const defaultStories: Story[] = [
    {
        title: "",
        city: "",
        country: "",
        continent: "",
        location: { lat: 0, long: 0 },
        description: "",
        tags: ["", ""],
        images: [""],
    },
];


const Collection: React.FC = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    // const toShow = process.env.REACT_APP_ENV as string;
    const navigate = useSmartNavigate();
    const { setIsLoading } = useLoading();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const context: any = useContext(AppContext);
    const {
        setIsCollection,
        setChatEntries,
        setTitle,
        setCurrChatId,
        setOnBehalf,
        setInfluencerData,
        setCollectionLoading,
        setCollectionContent,
        setStryIndex,
        userDetail,
    } = context;


    const destinations = [
        {
            imageSrc: "/images/Iceland.png",
            subheadingText: "Northern lights spots",
            headingText: "Iceland",
            query: "Show me best spots to view Northern Lights in Iceland",
        },
        {
            imageSrc: "/images/Japan2.webp",
            subheadingText: "Cherry blossom",
            headingText: "Japan",
            query: "Best places to see cherry blossoms in Japan",
        },
        {
            imageSrc: "/images/Philipines.png",
            subheadingText: "Pristine beaches",
            headingText: "Philippines",
            query: "Best islands and beaches to visit in Philippines in April",
        },
        {
            imageSrc: "/images/Himalayas.png",
            subheadingText: "Epic treks",
            headingText: "Himalayas",
            query: "Best beginner treks in the Himalayas in April",
        },

        {
            imageSrc: "/images/NZ.png",
            subheadingText: "Autumn colours",
            headingText: "New Zealand",
            query: "Best things to do in New Zealand during autumn in April",
        },
        {
            imageSrc: "/images/Asia.png",
            subheadingText: "Luxury resorts",
            headingText: "North India",
            query: "Find me the 6 most bespoke, hidden luxury resorts in North India",
        },
    ];

    const convertToStories1 = (item: any, image: any): any => {
        return {
            title: item.title,
            city: item.city,
            country: item.country,
            continent: item.continent,
            location: item?.location ?? { lat: 0, long: 0 },
            description: item.description,
            images: item.images
                ? item.images.length > 0
                    ? [image, ...item.images]
                    : [item.image]
                : [""],
            videos: undefined,
            tags: item.tags,
            image_attributions: item.image_attributions ?? undefined,
            static_fields: item?.static_fields,
            otherDetail: item.dynamic_fields,
            follow_on_queries: item?.follow_on_queries,
            google_maps_url: item?.google_maps_url,
            social_links: item?.social_links,
            booking_details: item?.booking_details,
            travel_destination: item?.travel_destination,
            identity_tag: item?.identity_tag,  
            place_metadata_id: item?.place_metadata_id,                     
        };
    };


    const handleCollectionCard = async (
        query: any,
        isCollectionCard?: boolean
    ) => {
        const user_loc = await getUserLocation().catch(() => null);
        let allEntities: any[] = [];
        setStryIndex(null);
        setIsCollection(true);
        setCollectionLoading(true);
        setIsLoading(true);

        fetch(apiUrl + "v3/collection/stream", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: query,
                user_detail: userDetail,
                is_collection_card: isCollectionCard ?? false,
                token: localStorage.getItem("token"),
                user_loc: user_loc,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then((response) => {
                if (!response.body) {
                    throw new Error("Response body is null");
                }
                const reader = response.body.getReader();
                let buffer = "";
                const readChunk = () => {
                    reader.read().then(async ({ value }) => {
                        const chunk = new TextDecoder("utf-8").decode(value);
                        buffer += chunk;

                        if (buffer === "🥚") {
                            console.log("Done reading stream");
                            setIsLoading(false);
                            return;
                        }

                        if (buffer.indexOf("✅") !== -1) {
                            let ind = buffer.indexOf("✅");
                            let comp_json = buffer.slice(0, ind);
                            buffer = buffer.slice(ind + 1);

                            // console.log(`Received chunk: ${comp_json}`);
                            const message = JSON.parse(comp_json);
                            if (message.data) {
                                // const meessageId = window.crypto.randomUUID();
                                const mess = message.data?.payload_message;
                                await setChatEntries(mess);
                                console.log("message", mess);
                                setTitle(query);
                                setOnBehalf({});
                                const data = message?.data?.query_response_data;
                                setCurrChatId(data?.conversation_id);

                                const transformedMessage = transformMessage(data?.message);
                                console.log("hery", transformedMessage);

                                await setChatEntries((prevEntries: any) => [...prevEntries, transformedMessage]);
                                if (transformedMessage?.entities?.length === 0) {
                                    navigate("/chat");
                                    setIsLoading(false);
                                } else {
                                    allEntities = transformedMessage.entities.map((entity: any) => ({
                                        ...entity,
                                        images: entity.image,
                                        _id:transformedMessage?._id,
                                        conversation_id:data?.conversation_id

                                    }));
                                    console.log("allEntities", allEntities);
                                    await setCollectionContent(
                                        Array(allEntities?.length).fill(defaultStories)
                                    );
                                    await setInfluencerData(Array(allEntities.length).fill([]));
                                    const contxt = {
                                        query: query,
                                        answer: transformedMessage.answer,
                                    };
                                    navigate("/story-carousel", {
                                        state: {
                                            clickedIndex: 0,
                                            isCollectionCard: isCollectionCard ?? false,
                                            iscollectionLoading: true,
                                            allEntities: allEntities, // pass the array with title and image
                                            query: query, // pass the array with title and image
                                            context: contxt,
                                        },
                                    });
                                    setIsLoading(false);
                                }
                            }
                            if (message.entities_details) {
                                setCollectionContent((prevContent: any) => {
                                    // Create a copy of the previous state
                                    const updatedContent = [...prevContent];

                                    // Update only the specific indices for matching titles
                                    allEntities.forEach((entity, index) => {
                                        const matchingDetail = message.entities_details.find(
                                            (detail: any) => detail.title === entity.title
                                        );
                                        if (matchingDetail) {
                                            updatedContent[index] = convertToStories1(
                                                matchingDetail.entity_detail,
                                                entity.images
                                            );
                                        }
                                    });

                                    return updatedContent; // Return the updated state
                                });
                            }
                        }
                        readChunk();
                    });
                };
                readChunk();
            })
            .catch((error) => {
                console.error("Error fetching stream:", error);
                setIsLoading(false);
            });
    };



    return (

        <Box
            // mt={"21%"}
            minH="100%"
            w="100vw"
            // onScroll={() => setShowSuggestedBox(false)}
            overflow="hidden"
        >


            <Box position="relative" zIndex={1} px="12px">
                {/* {toShow === "DEV" && */}
                {/* } */}
                <Heading
                    fontFamily="Poppins"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="21.28px"
                    letterSpacing="0px"
                    color={"#FFFFFF"}
                    mb={5}
                    ml={1}
                >
                    Browse trending destinations
                </Heading>
                <Box>
                    <SimpleGrid
                        columns={2}
                        spacing={3}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {destinations.map((destination, index) => (
                            <DestinationCard
                                key={index}
                                onClick={async () => {
                                    amplitude.track("collection_clicked", {
                                        type: "card",
                                        query_name: destination.query,
                                        logged: localStorage.getItem("token") ? true : false,
                                    });
                                    if (localStorage.getItem("token")) {
                                        handleCollectionCard(destination.query, true);
                                    }
                                    else {
                                        onOpen();
                                    }
                                }}
                                imageSrc={destination.imageSrc}
                                headingText={destination.headingText}
                                subheadingText={destination.subheadingText}
                            />
                        ))}
                    </SimpleGrid>
                </Box>
            </Box>

            <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>

        </Box>
    );
};

export default Collection;
