import {
    Box,
    Input,
    InputGroup,
    InputLeftElement,
    Image,
    useDisclosure,
    HStack,
    Heading,
    Text,
    VStack
} from "@chakra-ui/react";
import * as amplitude from "@amplitude/analytics-browser";
import { useContext, useEffect, useRef, useState } from "react";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import { AppContext } from "../../context/AppContext";
import { getUserLocation } from "../../utilities/getUserLocation";
import { useLoading } from "../../context/LoadingContext";
import { useNavigate } from "react-router-dom";
import { transformMessage } from "../../utilities/transformMessage";
import useSmartNavigate from "../../hooks/useSmartNavigate";

interface Story {
    title: string;
    city: string;
    country: string;
    continent: string;
    location: {
        lat: number;
        long: number;
    };
    image_attributions?: any;
    static_fields?: any[];
    follow_on_queries?: any;
    description: string;
    images?: string[];
    videos?: string[];
    tags: string[];
    otherDetail?: any[];
    google_maps_url?: string;
    social_links?: any;
    booking_details?: any;
    travel_destination?: any;
    identity_tag?: any;
    place_metadata_id?: any;      
}
const defaultStories: Story[] = [
    {
        title: "",
        city: "",
        country: "",
        continent: "",
        location: { lat: 0, long: 0 },
        description: "",
        tags: ["", ""],
        images: [""],
    },
];

const CollectionSearch = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    const [showSuggestedBox, setShowSuggestedBox] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [searchTerm, setSearchTerm] = useState("");
    const context: any = useContext(AppContext);
    const { setIsLoading } = useLoading();
    // Add this near the other useState declarations
    const [showSearchBar, setShowSearchBar] = useState(false);
    const navigate = useSmartNavigate();
    const {
        setIsCollection,
        setChatEntries,
        setTitle,
        setCurrChatId,
        setOnBehalf,
        setInfluencerData,
        setCollectionLoading,
        setCollectionContent,
        setStryIndex,
        userDetail,
        isCenterButtonClicked,
    } = context;

    const inputRef = useRef<HTMLInputElement>(null);



    const suggestions = [
        "Vegan cafés in North Goa",
        "Adventure activities in Australia",
        "Unique culinary experiences in Japan",
    ];
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const convertToStories1 = (item: any, image: any): any => {
        return {
            title: item.title,
            city: item.city,
            country: item.country,
            continent: item.continent,
            location: item?.location ?? { lat: 0, long: 0 },
            description: item.description,
            images: item.images
                ? item.images.length > 0
                    ? [image, ...item.images]
                    : [item.image]
                : [""],
            videos: undefined,
            tags: item.tags,
            image_attributions: item.image_attributions ?? undefined,
            static_fields: item?.static_fields,
            otherDetail: item.dynamic_fields,
            follow_on_queries: item?.follow_on_queries,
            google_maps_url: item?.google_maps_url,
            social_links: item?.social_links,
            booking_details: item?.booking_details,
            travel_destination: item?.travel_destination,
            identity_tag: item?.identity_tag,
        };
    };
    const handleCollectionCard = async (
        query: any,
        isCollectionCard?: boolean
    ) => {
        const user_loc = await getUserLocation().catch(() => null);
        let allEntities: any[] = [];
        setStryIndex(null);
        setIsCollection(true);
        setCollectionLoading(true);
        setIsLoading(true);

        fetch(apiUrl + "v3/collection/stream", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                title: query,
                user_detail: userDetail,
                is_collection_card: isCollectionCard ?? false,
                token: localStorage.getItem("token"),
                user_loc: user_loc,
            }),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response;
            })
            .then((response) => {
                if (!response.body) {
                    throw new Error("Response body is null");
                }
                const reader = response.body.getReader();
                let buffer = "";
                const readChunk = () => {
                    reader.read().then(async ({ value }) => {
                        const chunk = new TextDecoder("utf-8").decode(value);
                        buffer += chunk;

                        if (buffer === "🥚") {
                            console.log("Done reading stream");
                            setIsLoading(false);
                            return;
                        }

                        if (buffer.indexOf("✅") !== -1) {
                            let ind = buffer.indexOf("✅");
                            let comp_json = buffer.slice(0, ind);
                            buffer = buffer.slice(ind + 1);

                            // console.log(`Received chunk: ${comp_json}`);
                            const message = JSON.parse(comp_json);
                            if (message.data) {
                                // const meessageId = window.crypto.randomUUID();
                                const mess = message.data?.payload_message;
                                await setChatEntries(mess);
                                console.log("message", mess);
                                setTitle(query);
                                setOnBehalf({});
                                const data = message?.data?.query_response_data;
                                setCurrChatId(data?.conversation_id);

                                const transformedMessage = transformMessage(data?.message);
                                console.log("hery", transformedMessage);

                                await setChatEntries((prevEntries: any) => [...prevEntries, transformedMessage]);
                                if (!transformedMessage?.entities) {
                                    navigate("/chat");
                                    setIsLoading(false);
                                } else {
                                    allEntities = transformedMessage.entities.map((entity: any) => ({
                                        ...entity,
                                        images: entity.image,
                                        _id: transformedMessage?._id,
                                        conversation_id: data?.conversation_id

                                    }));
                                    console.log("allEntities", allEntities);
                                    await setCollectionContent(
                                        Array(allEntities?.length).fill(defaultStories)
                                    );
                                    await setInfluencerData(Array(allEntities.length).fill([]));
                                    const contxt = {
                                        query: query,
                                        answer: transformedMessage.answer,
                                    };
                                    navigate("/story-carousel", {
                                        state: {
                                            clickedIndex: 0,
                                            isCollectionCard: isCollectionCard ?? false,
                                            iscollectionLoading: true,
                                            allEntities: allEntities, // pass the array with title and image
                                            query: query, // pass the array with title and image
                                            context: contxt,
                                        },
                                    });
                                    setIsLoading(false);
                                }
                            }
                            if (message.entities_details) {
                                setCollectionContent((prevContent: any) => {
                                    // Create a copy of the previous state
                                    const updatedContent = [...prevContent];

                                    // Update only the specific indices for matching titles
                                    allEntities.forEach((entity, index) => {
                                        const matchingDetail = message.entities_details.find(
                                            (detail: any) => detail.title === entity.title
                                        );
                                        if (matchingDetail) {
                                            updatedContent[index] = convertToStories1(
                                                matchingDetail.entity_detail,
                                                entity.images
                                            );
                                        }
                                    });

                                    return updatedContent; // Return the updated state
                                });
                            }
                        }
                        readChunk();
                    });
                };
                readChunk();
            })
            .catch((error) => {
                console.error("Error fetching stream:", error);
                setIsLoading(false);
            });
    };

    const handleSearch = () => {
        if (searchTerm.trim()) {
            amplitude.track("collection_clicked", {
                type: "search_query",
                query_name: searchTerm,
                logged: localStorage.getItem("token") ? true : false,
            });
            handleCollectionCard(searchTerm);
        }
    };
    useEffect(() => {
        setShowSearchBar(isCenterButtonClicked);
    }, [isCenterButtonClicked]);
    useEffect(() => {
        if (showSearchBar && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showSearchBar]);
    return (
        <>
            <VStack w="100%">
                <HStack justifyContent="space-between" w="100%">
                    <Heading
                        w={"70%"}
                        fontFamily="Poppins"
                        fontSize="18px"
                        fontWeight="700"
                        lineHeight="23.94px"
                        letterSpacing="0px"
                        textAlign="left"

                        color="#FFFFFF">
                        {/* Ask our  <Text as="span" color="#9B5CFF">AI</Text> anything for your next trip */}
                        Can’t decide <Text as="span" color="#9B5CFF"> what to do</Text> on your trip?
                    </Heading>
                    <Image src="/icons/stars.svg" mr={3} alt="assest" />
                </HStack>
                {/* <HStack justifyContent={'flex-start'} w={'100%'} mt={2}>
                    <TrPrimaryButton
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsCenterButtonClicked(true);
                            setShowSearchBar(true);
                        }}
                        display={showSearchBar || isCenterButtonClicked ? "none" : "block"}
                        w={"46%"}
                        fontSize={"13px"}
                        fontFamily={"Poppins"}
                        letterSpacing={"0.5px"}
                        lineHeight={'13px'}
                        h={"40px"}
                        px={2}
                        mt={3}
                        fontWeight={"600"}
                    >
                        Ask Rahee
                    </TrPrimaryButton>
                </HStack> */}
                <Box zIndex={99} w={'100%'} mt={3}>
                    <InputGroup >
                        <InputLeftElement
                            // pl={6}
                            mt={1}
                            children={
                                <Image
                                    boxSize={"18px"}
                                    src={"/icons/Star.png"}
                                    alt="search"
                                    cursor="pointer"
                                    onClick={handleSearch} // Trigger search on icon click
                                />
                            }
                        />
                        <Input
                            ref={inputRef}
                            py={"2%"}
                            id="search"
                            placeholder="Ask Rahee for ideas..."
                            _placeholder={{ color: "#FFFFFF54" }}
                            bg="#242127"
                            color="#FFFFFF"
                            borderRadius="12px"
                            border={"1px solid #FFFFFF12"}
                            // boxShadow={"0px 1px 1px rgba(255, 255, 255, 0.32)"}
                            h={"48px"}
                            pl={9}
                            onFocus={(e) => {
                                if (localStorage.getItem("token")) {
                                    setShowSuggestedBox(true);
                                } else {
                                    (e.target as HTMLInputElement).blur();
                                    onOpen();
                                }
                            }}
                            onBlur={() => setTimeout(() => setShowSuggestedBox(false), 200)}
                            // Controlled input
                            onChange={handleInputChange}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleSearch();
                                    (e.target as HTMLInputElement).blur(); // Hide keyboard on Enter key press
                                }
                            }}
                        />
                        {/* <InputRightElement
                            py={"25px"}
                            pr={"15px"}
                            mt={"1px"}
                            children={
                                <Image
                                    boxSize={"18px"}
                                    src={"/icons/Chat_send.png"}
                                    alt="search"
                                    cursor="pointer"
                                    onClick={handleSearch} // Trigger search on icon click
                                />
                            }
                        /> */}
                    </InputGroup>
                    {showSuggestedBox && (
                        <Box
                            position={"relative"}
                            bg="#242127"
                            color="gray.200"
                            zIndex={99999}
                            // w={"93vw"}
                            p={2}
                            borderRadius="12px"
                            border="1px solid #FFFFFF12"
                            maxH="auto"
                            overflowY="auto"
                        >
                            {suggestions.map((suggestion, index) => (
                                <Box
                                    key={index}
                                    p={2}
                                    fontSize={"md"}
                                    // _hover={{ bg: "#3a3a3a", cursor: "pointer" }}
                                    onClick={() => {
                                        amplitude.track("video_search_clicked", {
                                            type: "suggested_query",
                                            query_name: suggestion,
                                            page: "Homepage",
                                        });
                                        handleCollectionCard(suggestion, true);
                                    }}
                                >
                                    {suggestion}
                                </Box>
                            ))}
                        </Box>
                    )}
                </Box>
            </VStack>
            <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>
        </>
    );
};

export default CollectionSearch;