import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";

interface HistoryEntry {
  path: string;
  state?: any;
}

const useSmartNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Retrieve history stack from sessionStorage
  const getHistoryStack = (): HistoryEntry[] => {
    return JSON.parse(sessionStorage.getItem("historyStack") || "[]");
  };

  // Update history stack with state
  const updateHistoryStack = (path: string, state?: any) => {
    const stack = getHistoryStack();
    const entry: HistoryEntry = { path, state };

    if (!stack.some((item) => item.path === path) && path !== "/") {
      stack.push(entry);
      sessionStorage.setItem("historyStack", JSON.stringify(stack));
    }
  };

  // Smart navigation with home fallback for empty history
  const smartNavigate = (path: string, options: { state?: any } = {}) => {
    const stack = getHistoryStack();

    console.log("stack", stack);

    const index = stack.findIndex((item) => item.path === path);
    // console.log("index", -(stack.length - 1 - index));  
    if (index !== -1) {
      // ✅ Fix: Type assertion to correctly navigate back
      navigate(-(stack.length - 1 - index) as number);
    } else {
      navigate(path, options);
      updateHistoryStack(path, options.state);
    }
  };

  // Sync back button behavior
  useEffect(() => {
    const stack = getHistoryStack();
    if (stack?.length > 0 && (stack[stack.length - 1].path !== location.pathname)) {
      stack.pop(); // Remove last page when navigating back
      console.log("am i here")
      sessionStorage.setItem("historyStack", JSON.stringify(stack));
    }
  }, [location]); // Runs on route change

  useEffect(() => {
    const handlePopState = () => {
      const stack = getHistoryStack();
      const currentPath = location.pathname;

      // Check if the current path exists in the history stack
      const index = stack.findIndex((item) => item.path === currentPath);
      if (index === -1) {
        // If not found, add it to the stack
        updateHistoryStack(currentPath);
      }
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [location]);


  return smartNavigate;
};

export default useSmartNavigate;
