import React from 'react';
import { Box, Text, Flex, Icon, Button, HStack, Image } from '@chakra-ui/react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import BookingCard from '../BookingCard';
import ImageGrid from './ImageGrid';
import AlternatingCard from './AlternatingCard';

interface Booking {
  bookingPlatform?: string;
  BookingName?: string;
  BookingAddress?: string;
  BookingCity?: string;
  bookingOf?: string;
  BookingImage?: string;
  checkInDate?: string;
  checkOutDate?: string;
}

interface GenericItineraryCardProps {
  title?: string;
  subtitle?: string;
  details?: string;
  locationFrom?: string;
  locationTo?: string;
  LinkText?: string;
  onLinkButtonClick?: () => void;
  Booking?: Booking; // Single Booking object
  accommodation?: any;
  maps_route?: any;
  costINR?: any;
  activities?: any;
  directions_link?: string;
  day?: any;
}

const GenericItineraryCard: React.FC<GenericItineraryCardProps> = ({
  title,
  subtitle,
  details,
  locationFrom,
  locationTo,
  LinkText,
  onLinkButtonClick,
  Booking,
  accommodation,
  maps_route,
  costINR,
  activities,
  directions_link,
  day
}) => {

  // console.log("day in generic card", day);  
  return (
    <Box >
      {/* Conditionally render the title */}
      {title && (
        <Text
          fontFamily={'Poppins'}
          fontSize={'16px'}
          fontWeight={'medium'}
          color={'#FFFFFFD4'}
          lineHeight={'24px'}
          mb={1}
        >
          {title}
        </Text>
      )}

      {/* Conditionally render the subtitle */}
      {subtitle && (
        <Text
          fontFamily={'Poppins'}
          fontSize={'12px'}
          fontWeight={'normal'}
          color={'#FFFFFFA1'}
          lineHeight={'18px'}
          mt={2}
          noOfLines={2}
        >
          {subtitle}
        </Text>
      )}
      {details && (
        <Text
          fontFamily={'Poppins'}
          fontSize={'12px'}
          fontWeight={'medium'}
          color={'#FFFFFFA1'}
          lineHeight={'19px'}
          mt={'auto'}
        >
          {details}
        </Text>
      )}

      {accommodation && (

        <ImageGrid
        day={day} 
          accommodation={accommodation}
        />


      )}

      {/* Conditionally render the details */}


      {/* Conditionally render the location information */}
      {(locationFrom || locationTo) && (
        <HStack w={'full'} justifyContent={'space-between'} alignItems={'flex-start'}>
          <Box mt={4} ml={'0'}>
            {locationFrom && (
              <>
                <Box mt={-2}>
                  {maps_route?.duration && (
                    <Text
                      fontFamily={'Poppins'}
                      fontSize={'12px'}
                      fontWeight={'normal'}
                      color={'#FFFFFFA1'}
                      lineHeight={'18px'}
                      mb={2}
                    >{`Time Taken: ${maps_route?.duration}`}</Text>
                  )}
                  {costINR &&
                    (typeof costINR === 'string'
                      ? costINR !== '0' && costINR.trim() !== '0'
                      : costINR !== 0) && (
                      <Text
                        fontFamily={'Poppins'}
                        fontSize={'12px'}
                        fontWeight={'normal'}
                        color={'#FFFFFFA1'}
                        lineHeight={'18px'}
                        mb={2}
                      >
                        {`Cost: ₹ ${costINR}`}
                      </Text>
                    )}
                </Box>
                <Flex alignItems="center" ml={2} >
                  <Icon as={FaMapMarkerAlt} boxSize={4} color="#FFFFFFA1" />
                  <Text
                    fontFamily={'Poppins'}
                    fontSize={'12px'}
                    fontWeight={'normal'}
                    color={'#FFFFFFA1'}
                    lineHeight={'18px'}
                    ml={2}
                  >
                    {locationFrom}
                  </Text>
                </Flex>
                {locationTo && (
                  <Box ml={4} borderLeft="1px dashed" borderColor="#FFFFFF21" height="10px" />
                )}
              </>
            )}
            {locationTo && (
              <Flex alignItems="center" ml={2} >
                <Icon as={FaMapMarkerAlt} boxSize={4} color="#FFFFFFA1" />
                <Text
                  fontFamily={'Poppins'}
                  fontSize={'12px'}
                  fontWeight={'normal'}
                  color={'#FFFFFFA1'}
                  lineHeight={'18px'}
                  ml={2}
                >
                  {locationTo}
                </Text>
              </Flex>
            )}
          </Box>
          {directions_link && (
            <Image
              maxW={'auto'}
              src="/icons/route.svg"
              alt="map icon"

              onClick={() => {
                window.open(directions_link, '_blank');
              }}
              boxSize={7}
            />
          )}
        </HStack>
      )}
      {/* Conditionally render the link button */}
      {LinkText && (
        <Button
          fontFamily={'Poppins'}
          fontSize={'12px'}
          fontWeight={600}
          lineHeight={'18px'}
          variant="link"
          maxH={'19px'}
          mt={4}
          px={["0", "0", "32px"]}
          py={["0px", "0px", "20px"]}
          bg={["none", "none", "#282629"]}
          borderRadius={["0", "0", "8px"]}
          color="#AE7CFF"
          onClick={onLinkButtonClick}
        >
          {LinkText}
        </Button>
      )}
      {activities?.length > 0 && (<AlternatingCard
        activities={activities}
      />)}
      {/* Conditionally render the Booking booking */}
      {Booking && (
        <Box mt={4}>
          <BookingCard
            bookingOf={Booking.bookingOf}
            bookingPlatform={Booking.bookingPlatform}
            BookingName={Booking.BookingName}
            BookingAddress={Booking.BookingAddress}
            BookingCity={Booking.BookingCity}
            BookingImage={Booking.BookingImage}
            checkInDate={Booking.checkInDate}
            checkOutDate={Booking.checkOutDate}
            onViewClick={() => console.log(`View Button Clicked for ${Booking.BookingName}`)}
            onDownloadClick={() => console.log(`Download Button Clicked for ${Booking.BookingName}`)}
          />
        </Box>
      )}

      {/* Dashed bottom border */}


    </Box>
  );
};

export default GenericItineraryCard;
