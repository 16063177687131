import { useState} from "react";
import {
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";

import MapComponent from "../TrMapLeaf/TrMapLeaflet";
import React from "react";
import VideoHighlightsCard from "./VideoHighlightsCard";

interface VideoHighlightsItemProps {
  highlights?: any[];
}

export const VideoHighlights: React.FC<VideoHighlightsItemProps> = ({
  highlights,
}) => {
  // export const VideoHighlights:React.FC=()=>{
  const [highLightIndex, setHighlightIndex] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleMap = (index: React.SetStateAction<number>) => {
    setHighlightIndex(index);
    onOpen();
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent zIndex={9999999} mt={"0"} p={0} height={"100%"}>
          <ModalBody p={0} m={0} height={"100%"}>
            <MapComponent
              showCloseButton={true}
              defaultIndex={highLightIndex}
              defaultFullscreen={true}
              // isMobile={true}
              dismissMap={() => {
                console.log("ok");
                onClose();
              }}
              marks={
                highlights
                  ? highlights?.map((highlight: any) => ({
                      latitude:
                        highlight &&
                        highlight?.location &&
                        isNaN(highlight?.location)
                          ? parseFloat(highlight?.location?.lat)
                          : 0,
                      longitude:
                        highlight &&
                        highlight?.location &&
                        isNaN(highlight?.location)
                          ? parseFloat(highlight?.location?.lng??highlight?.location?.long) 
                          : 0,
                      title: highlight?.title,
                      previewImage: highlight?.image,
                    }))
                  : []
              }
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <>
        <SimpleGrid columns={2} spacing={5} rowGap={8}>
          {highlights?.map((highlight: any, index: number) => (
            <VideoHighlightsCard
              key={index}
              handleMap={() => handleMap(index)}
              // likedhighlights={likedhighlights}
              highlight={highlight}
            />
          ))}
        </SimpleGrid>
      </>
    </>
  );
};
