import React, { useContext, useState } from 'react';
import { Box, Divider, VStack, Text, Spinner, Center, useToast } from '@chakra-ui/react';
// import data from './data.json'; // Import the JSON data
// import SearchBar from '../SearchComponents/SearchBar';
import ItineraryCardLayout from './ItineraryCardLayout';
import { AppContext } from '../../context/AppContext';
import useAxios from '../../hooks/useAxios';
import useSmartNavigate from '../../hooks/useSmartNavigate';


const ListItinerary: React.FC = () => {
	const navigate = useSmartNavigate();
	const context: any = useContext(AppContext);
	const { itinerary, setItinerary, setConversationId } = context;
	const { fetchData } = useAxios();
	const [isDeleting, setIsDeleting] = useState(false);
	const toast = useToast();

	// Extract the itineraries array from the imported data
	const itineraries: any[] = itinerary;

	const handleEdit = (itinerary: any) => {
		setConversationId(itinerary?.conversation_id);								// Set the conversation ID	
		navigate(`/itineraries/${itinerary?.id}`, { state: { itinerary: itinerary } });
		console.log('itin', itinerary);
		console.log(`Edit button clicked for itinerary with id: ${itinerary?.id}`);
	};

	const handleDelete = async (itinerary: any) => {
		try {
			setIsDeleting(true);
			const { statusCode } = await fetchData({
				method: "POST",
				url: `ai/v1/unsave_itinerary`,
				options: {
					data: {
						token: localStorage.getItem('token'),
						itinerary_id: itinerary?.id,
						conversation_id: itinerary?.conversation_id
					},
					headers: { "Content-Type": "application/json" },
				},
				auth: true,
			});

			// Update the itinerary list after successful deletion
			if (statusCode === 200) {
				setItinerary(itineraries.filter(item => item.id !== itinerary.id));
				toast({
					title: "Itinerary deleted",
					status: "success",
					duration: 3000,
					isClosable: true,
				});
			}
		} catch (error) {
			toast({
				title: "Failed to delete itinerary",
				status: "error",
				duration: 3000,
				isClosable: true,
			});
			console.error("Error deleting itinerary:", error);
		} finally {
			setIsDeleting(false);
		}
	}

	return (
		<Box mt={4} position="relative">
			{isDeleting && (
				<Center
					position="fixed"
					top="0"
					left="0"
					right="0"
					bottom="0"
					bg="rgba(0, 0, 0, 0.7)"
					backdropFilter="blur(4px)"
					zIndex={99999999999999}
				>
					<Box textAlign="center">
						<Spinner
							thickness="2px"
							speed="0.65s"
							emptyColor="gray.200"
							// color="purple.500"
							size="xl"
						/>
						<Text mt={4} color="white" fontWeight="medium">Deleting itinerary...</Text>
					</Box>
				</Center>
			)}

			{/* <SearchBar
                onSearch={() => { }}
            /> */}
			{itineraries.length > 0 ? (
				<VStack spacing={'20px'} w={"-moz-fit-content"} mt={'32px'}>
					{itineraries.map((itinerary) => (
						<React.Fragment key={itinerary?.id}>
							<ItineraryCardLayout
								image={itinerary?.trip_itinerary?.image}
								title={itinerary?.trip_itinerary?.title}
								dates={itinerary?.trip_itinerary?.dates}
								number_of_people={itinerary?.trip_itinerary?.number_of_people}
								onEdit={() => handleEdit(itinerary)}
								onDelete={() => handleDelete(itinerary)} />
							<Divider
								my={2} // Margin Y for spacing
								border={'1px solid #FFFFFF21'} />
						</React.Fragment>
					))}
				</VStack>
			) : (
				<Text
					color="rgba(255, 255, 255, 0.45)"
					textAlign="center"
					fontFamily="Poppins"
					fontSize="14px"
					fontStyle="normal"
					fontWeight="500"
					lineHeight="133%"
					mt={"50%"}
				>
					✨ Your itinerary list is looking a little empty! Start adding
					your favourite itinerary 🌍.
				</Text>
			)}
		</Box>
	);
};

export default ListItinerary;