import React, { useState, useEffect } from 'react';
import { IconButton, Link, Box } from '@chakra-ui/react';
import { FaInfo } from "react-icons/fa6";

interface ToggleTipProps {
  link: string;
  iconColor?: string; // Optional color prop for the icon
  linkColor?: string; // Optional color prop for the link
}

const ToggleTip: React.FC<ToggleTipProps> = ({ link, iconColor = "gray.500", linkColor = "teal.500" }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Function to show the link
  const handleToggle = () => {
    setIsVisible(true);
  };

  // Auto-hide the link after 3 seconds
  useEffect(() => {
    if (isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, 3000); // Auto-hide after 3 seconds
      return () => clearTimeout(timer); // Cleanup the timer on unmount
    }
  }, [isVisible]);

  return (
    <Box position="relative" display="inline-block" zIndex={1000}>
      {/* Icon Button */}
      <IconButton
        colorScheme="gray"
        icon={<FaInfo />}
        aria-label="Toggle link"
        onClick={handleToggle}
        variant={"link"}
        _hover={{ bg: 'transparent' }}
        size="md"
        // color={iconColor} // Apply the icon color
      />

      {/* Link Box */}
      {isVisible && (
        <Box
          position="absolute"
          top="-25px" // Adjust this to position the link correctly
          right="0"
          bg="grey.800"
          fontSize={"12px"}
          p={2}
          boxShadow="md"
          borderRadius="md"
          zIndex={1100}
        >
          <Link
            href={link}
            isExternal
            color={linkColor} // Apply the link color
            textDecoration="underline"
            onClick={() => setIsVisible(false)} // Hide link after clicking
          >
            Link
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default ToggleTip;
