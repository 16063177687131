import React, { useContext, useEffect, useState } from 'react';
import {
    Box,
    VStack,
    Skeleton,
    // HStack,
} from "@chakra-ui/react";
import ConversationCard from "./ConversationCard";
// import Header from "./Header";
// import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import useAxios from '../../hooks/useAxios';

const SavedConversation: React.FC = () => {


    // State to store conversations and loading state
    // const { navigateSmart } = useNavigationHistory();
    const [conversations, setConversations] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const context: any = useContext(AppContext);

    const { fetchData } = useAxios();
    const { savedConversation, setSavedConversation } = context;

    // Function to fetch conversations from API

    const fetchChatResponse = async (token: any) => {
        try {
            const { data } = await fetchData({
                method: "POST",
                url: `ai/v2/find_all_conversations_for_a_user`,
                options: {
                    data: { token: token },
                    headers: { "Content-Type": "application/json" },
                },
                auth: false,
            });
            if (data) {
                setConversations(data?.conversations);
                setSavedConversation(data?.conversations);


            }
        } catch (error) {
            console.error('Fetch error:', error);
        } finally {
            setLoading(false); // Stop loading once the API call is complete
        }
    };


    // useEffect to fetch conversations when the component is mounted
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            if (savedConversation) {
                setConversations(savedConversation);
                setLoading(false);
            }
            fetchChatResponse(token);  // Fetch conversations with the token
        }
        // eslint-disable-next-line
    }, []);  // Empty dependency array means this runs once when the component is first rendered

    return (
        <VStack minW={"100%"} >
            {/* <Box
                position={'fixed'}
                top={0}
                left={0}
                w={'100vw'}
                h={'50vh'}
                zIndex={'2'}
                // bg={"linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"}
            /> */}
            {/* Header */}
            {/* <HStack
                bg={'#29252D'}
                position={"fixed"}
                top={0}
                left={0}
                w={"100vw"}
                h={'10vh'}
                // pt={"-10px"}
                zIndex={10}
            >
                <Box position={'relative'} w={'100%'}>
                    <Header
                        backIconSrc="/icons/Back_Icon_White.svg"
                        heading="Saved Conversations"
                        addIconSrc="/icons/plus_outline.svg"
                        handleBackButton={() => { navigateSmart(-1) }}
                        addButtonOnClick={() => {
                            setChatEntries([]);
                            setTitle('');
                            setCurrChatId('');
                            setOnBehalf({});
                            navigateSmart('/chat');
                        }}
                        bgColor="#2E2B31"
                    />
                </Box>
            </HStack> */}

            {/* Skeleton for the entire content while loading */}
            <VStack flexDir={'column'} spacing={'20px'} zIndex={5} pt={5}  w={'100%'} h={'100%'} >
                {/* Search Bar */}
                {/* <SearchBar onSearch={() => { }} /> */}
                {/* Conversation List */}
                <Skeleton isLoaded={!loading} fadeDuration={1} startColor="gray.500" endColor="gray.300">
                    <VStack spacing={4} w={"100%"} alignItems={'center'} justifyContent={'center'}>
                        {conversations?.map((conversation, index) => (
                            <ConversationCard
                                key={index} // Still using index as the key since there's no unique id
                                title={conversation.title} // Accessing the title from the conversation object
                                image={conversation.imageUrl} // Accessing the imageUrl from the conversation object
                                conversation_id={conversation.conversation_id}
                                type={conversation.type}
                            />
                        ))}
                    </VStack>

                </Skeleton>
            </VStack>
        </VStack >
    );
};

export default SavedConversation;
