import {
  Flex,
  IconButton,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SavedPlaces from "./SavedPlaces";
import SavedVideos from "./SavedVideos";

const BucketCardDetail = () => {
  const router = useNavigate();
  const location = useLocation();

  const country = location.state?.country;
  const city = location.state?.city;

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      window.scrollTo(0, 0); // Reset scroll position when navigating back
    };
  }, []);

  const handleBackButton = () => {
    router(-1);
  };

  return (
    <Box
      bg="#111012"
      direction="column"
      w="100%"
      h="100dvh"
      overflowX="hidden"
    >
      {/* Header Section */}
      <Flex
        position={"sticky"}
        top={0}
        left={0}
        zIndex={30}
        bg="#111012"
        alignItems={"flex-start"}
        visibility={["visible", "visible", "hidden"]}
      >
        <IconButton
          aria-label="Back"
          icon={<img src="/icons/Back_Icon_White.svg" alt="back" />}
          colorScheme="gray"
          size="lg"
          variant="link"
          py={8}
          onClick={handleBackButton}
        />
        <Flex direction="column" pb={5}>
          <Text
            fontFamily="Poppins"
            fontSize="36px"
            fontWeight="600"
            lineHeight="40.23px"
            letterSpacing="-2.054992914199829px"
            textAlign="left"
            bgGradient="linear-gradient(180deg, rgba(255, 255, 255, 0.93) 53.49%, rgba(57, 55, 55, 0.93) 95.69%)"
            bgClip="text"
            pb={1}
            pt={5}
          >
            {city}
          </Text>
          <Text
            fontFamily="Poppins"
            fontSize="13px"
            fontWeight="500"
            lineHeight="16.95px"
            letterSpacing="0.3px"
            textAlign="left"
            color="#9D9D9D"
          >
            {country}
          </Text>
        </Flex>
      </Flex>

      {/* Tab Navigation */}
      <Tabs variant="unstyled" defaultIndex={0}>
        <TabList gap={3} ml={3} mt={"-11%"} pt={2} bg="#111012" pos={"fixed"} top={"auto"} w={"100%"} pb={2} zIndex={21}>
          <Tab
            height={"33px"}
            width={"fit-content"}
            fontSize={"0.9em"}
            borderRadius={"53px"}
            border={"1px solid grey"}
            lineHeight={0}
            py={0}
            color={"white"}
            _selected={{ border: "1.5px solid white" }}
          >
            Saved videos
          </Tab>
          <Tab
            height={"33px"}
            width={"fit-content"}
            fontSize={"0.9em"}
            borderRadius={"53px"}
            border={"1px solid grey"}
            lineHeight={0}
            py={0}
            color={"white"}
            _selected={{ border: "1.5px solid white" }}
          >
            Saved places
          </Tab>
        </TabList>

        <TabPanels mt={"10%"} p={0}>
          <TabPanel>
            <SavedVideos data={[country, city]} />
          </TabPanel>
          <TabPanel>
            <SavedPlaces data={[country, city]} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default BucketCardDetail;