import { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Radio,
  RadioGroup,
  useColorModeValue,
  VStack,
  Text,
  SimpleGrid,
  Checkbox,
  CheckboxGroup,
  Flex,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tag,
  TagCloseButton,
  TagLabel,
  Wrap,
} from "@chakra-ui/react";
import { AppContext } from "../../context/AppContext";
import useTripService from "../../services/tripService";

interface TravelFormProps {
  message_id?: string;
}
const TravelForm: React.FC<TravelFormProps> = ({
  message_id
}) => {
  const [formData, setFormData] = useState<{
    travellingWith: string;
    numberOfPeople: string;
    monthOfTravel: string;
    numberOfDays: any;
    totalBudget: string;
    typeOfActivities: string[];
  }>({
    travellingWith: "",
    numberOfPeople: "",
    monthOfTravel: "",
    numberOfDays: "",
    // tripOccasion: "Not Provided",
    totalBudget: "Not Provided",
    typeOfActivities: [],
  });

  const context: any = useContext(AppContext);
  const { itineraryQuery } = context;
  const { fetchConversationResponse } = useTripService();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [activityOptions, setActivityOptions] = useState<string[]>([]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleRadioChange = (value: any, name: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [name]: value.toLowerCase(),
    }));
  };
  const handleActivitiesChange = (selectedActivities: string[]) => {
    setFormData((prevState) => ({
      ...prevState,
      typeOfActivities: selectedActivities,
    }));
  };

  // Add a function to remove an activity
  const removeActivity = (activity: any) => {
    setFormData((prevState) => ({
      ...prevState,
      typeOfActivities: prevState.typeOfActivities.filter(item => item !== activity)
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const requiredFields = ["numberOfPeople", "monthOfTravel", "numberOfDays", "travellingWith"];
    const fields_input = {
      number_of_people: formData?.numberOfPeople,
      month_of_travel: formData?.monthOfTravel,
      number_of_days: formData?.numberOfDays,
      travelling_with: formData?.travellingWith,
      // trip_occasion: formData?.tripOccasion,
      total_budget: formData?.totalBudget,
      type_of_activities: formData?.typeOfActivities,
    };
    for (let field of requiredFields) {
      if (!formData[field as keyof typeof formData]) {
        alert(`${field} is required`);
        return;
      }
    }
    const queryIndex = itineraryQuery?.findIndex((query: any) => query.message_id === message_id);
    if (queryIndex !== -1) {
      itineraryQuery[queryIndex].meta = {
        number_of_people: formData?.numberOfPeople,
        month_of_travel: formData?.monthOfTravel,
        number_of_days: formData?.numberOfDays,
        travelling_with: formData?.travellingWith,
        total_budget: formData?.totalBudget,
        type_of_activities: formData?.typeOfActivities.join(","),
      };
    }
    fetchConversationResponse("Create a trip with the above preferences", false, fields_input);
    setIsSubmitted(true);
  };
  const dayOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];

  const peopleOptions = [1, 2, 3, 4, 5, 6, 7, 8, "9+"];

  useEffect(() => {
    if (itineraryQuery) {
      const queryIndex = itineraryQuery?.findIndex((query: any) => query?.message_id === message_id);
      if (queryIndex === -1) return;
      setActivityOptions(itineraryQuery?.[queryIndex]?.meta?.type_of_activities?.enums ?? []);
      setFormData((prevState) => ({
        ...prevState,
        numberOfPeople:
          itineraryQuery?.[queryIndex]?.meta
        ?.number_of_people ?? "",
        monthOfTravel:
          itineraryQuery?.[queryIndex]?.meta?.month_of_travel ??
          "",
        travellingWith:
          itineraryQuery?.[queryIndex]?.meta?.travelling_with ??
          "",
        numberOfDays: itineraryQuery?.[queryIndex]?.meta?.number_of_days,
        totalBudget:
          itineraryQuery?.[queryIndex]?.meta?.total_budget ??
          "Not Provided",
        typeOfActivities:
          typeof itineraryQuery?.[queryIndex]?.meta?.type_of_activities?.selected === 'string'
        ? itineraryQuery?.[queryIndex]?.meta?.type_of_activities?.selected.split(",")
        : Array.isArray(itineraryQuery?.[queryIndex]?.meta?.type_of_activities?.selected)
          ? itineraryQuery?.[queryIndex]?.meta?.type_of_activities?.selected
          : [],
      }));

    }
  }, [itineraryQuery]);

  return (
    <Box
      mt={3}
      mr={"12px"}
      ml={"18px"}
      p={4}
      bg={"#E8E9FF"}
      borderRadius="8px"

      maxW={"227px"}
    >
      <VStack spacing={2} align="stretch">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
          {/* Origin/Destination */}

          <FormControl isRequired>
            <FormLabel
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
            >
              Number of People
            </FormLabel>
            <Select
              name="numberOfPeople"
              placeholder="Select number of travelers"
              value={formData?.numberOfPeople}
              onChange={handleChange}
              size="md"
              borderRadius="8px"
              border="none"
              background="white"

              _focus={{ boxShadow: "outline" }}
            >
              {peopleOptions?.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
            >
              Trip Duration
            </FormLabel>
            <Select
              name="numberOfDays"
              placeholder="Select duration"
              value={formData?.numberOfDays}
              onChange={handleChange}
              size="md"
              borderRadius="8px"
              border="none"
              background="white"

              _focus={{ boxShadow: "outline" }}
            >
              {dayOptions?.map((option) => (
                <option key={option} value={option}>
                  {option} days
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
            >
              Travelling With
            </FormLabel>
            <RadioGroup
              onChange={(val) => handleRadioChange(val, "travellingWith")}
              value={formData?.travellingWith?.toLowerCase()} // Normalize to lowercase
            >
              <Box bg="white" px={4} py={3} borderRadius="8px">
                <SimpleGrid columns={2} spacingX={6} spacingY={2}>
                  <Radio
                    value="partner"
                    colorScheme="gray"
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    letterSpacing="-0.47px"
                  >
                    Partner
                  </Radio>
                  <Radio
                    value="family"
                    colorScheme="gray"
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    letterSpacing="-0.47px"
                  >
                    Family
                  </Radio>
                  <Radio
                    value="friends"
                    colorScheme="gray"
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    letterSpacing="-0.47px"
                  >
                    Friends
                  </Radio>
                  <Radio
                    value="solo"
                    colorScheme="gray"
                    fontFamily="Poppins"
                    fontWeight="500"
                    fontSize="14px"
                    lineHeight="17px"
                    letterSpacing="-0.47px"
                  >
                    Solo
                  </Radio>
                </SimpleGrid>
              </Box>
            </RadioGroup>
          </FormControl>

          <FormControl isRequired>
            <FormLabel
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
            >
              Month of Travel
            </FormLabel>
            <Select
              name="monthOfTravel"
              placeholder="Select month"
              value={formData?.monthOfTravel.toLowerCase()}
              onChange={handleChange}
              fontFamily="Poppins"
              fontWeight="500"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
              borderRadius="8px"
              border="none"
              background="white"

              _focus={{ boxShadow: "outline" }}
            >
              <option value="january">January</option>
              <option value="february">February</option>
              <option value="march">March</option>
              <option value="april">April</option>
              <option value="may">May</option>
              <option value="june">June</option>
              <option value="july">July</option>
              <option value="august">August</option>
              <option value="september">September</option>
              <option value="october">October</option>
              <option value="november">November</option>
              <option value="december">December</option>
            </Select>
          </FormControl>

          <FormControl>
            <FormLabel
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
            >
              Type of Activities
            </FormLabel>
            <Popover placement="bottom" closeOnBlur={true}>
              <PopoverTrigger>
                <Button
                  w="full"
                  textAlign="left"

                  borderRadius="8px"
                  fontWeight="500"
                  fontSize="14px"
                  justifyContent="flex-start"
                  h={formData?.typeOfActivities?.length === 0 ? "40px" : "auto"}
                  p="8px"
                  overflow="hidden"
                  whiteSpace="normal"
                  flexWrap="wrap"
                  position="relative"
                  bg={"white"}

                >
                  {formData?.typeOfActivities?.length === 0 ? (
                    <Box>
                      <Text fontFamily="Poppins"
                        fontWeight="500"
                        fontSize="14px"
                        lineHeight="17px"
                        letterSpacing="-0.47px"
                        color={"#16181B"}
                      >
                        Select one or more</Text>
                    </Box>
                  ) : (
                    <Wrap spacing={1}>
                      {formData?.typeOfActivities?.map((activity) => (
                        <Tag
                          key={activity}
                          size="sm"
                          borderRadius="full"
                          backgroundColor="#E8E9FF"
                        >
                          <TagLabel>{activity?.charAt(0)?.toUpperCase() + activity?.slice(1)}</TagLabel>
                          <TagCloseButton
                            onClick={(e) => {
                              e.stopPropagation();
                              removeActivity(activity);
                            }}
                          />
                        </Tag>
                      ))}
                    </Wrap>
                  )}
                </Button>
              </PopoverTrigger>
              <PopoverContent width="220px">
                <PopoverBody>
                  <CheckboxGroup
                    value={formData?.typeOfActivities}
                    onChange={handleActivitiesChange}
                  >
                    <Flex direction="column" gap={2}>
                      {activityOptions?.map((activity) => (
                        <Checkbox key={activity} value={activity}>
                          {activity?.charAt(0)?.toUpperCase() + activity?.slice(1)}
                        </Checkbox>
                      ))}
                    </Flex>
                  </CheckboxGroup>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </FormControl>



          {/* Traveler Info */}

          <FormControl gridColumn={{ md: "span 2" }}>
            <FormLabel
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
            >
              Total Budget
            </FormLabel>
            <Input
              name="totalBudget"
              placeholder="What's your budget?"
              value={
                formData?.totalBudget === "Not Provided"
                  ? ""
                  : formData?.totalBudget
              }
              onChange={handleChange}
              color={"#16181B"}
              fontFamily="Poppins"
              fontWeight="500"
              fontSize="14px"
              lineHeight="17px"
              letterSpacing="-0.47px"
              borderRadius="8px"
              border="none"
              background="white"

              _focus={{ boxShadow: "outline" }}
            />
          </FormControl>
        </SimpleGrid>

        <Button
          bg={"#16181B"}
          // size="lg"
          isDisabled={isSubmitted}
          mt={1}
          width="full"
          onClick={handleSubmit}
          _hover={{ bg: useColorModeValue("gray.700", "gray.500") }}
          borderRadius="8px"
        >
          <Text
            fontFamily="Poppins"
            fontWeight="600"
            fontSize="14px"
            lineHeight="17px"
            letterSpacing="-0.47px"
            color="#FFFFFF"
          >
            Create Itinerary
          </Text>
        </Button>
      </VStack>
    </Box>
  );
};

export default TravelForm;
