import { Box, Heading, VStack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import EntityCard from "./EntityCard";

interface CardsProps {
    mustHave: any;
    goodToHave: any;
    setMustHave: (entities: any[]) => void;
    setGoodToHave: (entities: any[]) => void;
}

const Cards: React.FC<CardsProps> = ({
    mustHave,
    goodToHave,
    setMustHave,
    setGoodToHave
}) => {
    const [mergedEntities, setMergedEntities] = useState<any[]>([]);

    const mergeAndSortEntities = () => {
        const merged = Array.from(
            new Set([...mustHave, ...goodToHave?.map((entity: any) => ({ ...entity, checked: false }))])
        );


        const sorted = merged?.sort((a, b) => a?.city?.localeCompare(b?.city));

        const groupedByCity: any[] = [];
        sorted?.forEach((entity) => {
            const cityGroup = groupedByCity?.find(group => group?.city === entity?.city);
            if (cityGroup) {
                cityGroup?.entities?.push(entity);
            } else {
                groupedByCity.push({
                    city: entity?.city,
                    entities: [entity]
                });
            }
        });

        console.log(groupedByCity);
        setMergedEntities(groupedByCity);
    };

    useEffect(() => {
        mergeAndSortEntities();
        // eslint-disable-next-line
    }, [mustHave, goodToHave]);

    const handleCheckboxChange = (entity: any, checked: boolean) => {
        if (checked) {
            setMustHave([...mustHave, { ...entity, checked: true }]);
            setGoodToHave(goodToHave?.filter((e: any) => e?.title !== entity?.title));
        } else {
            setGoodToHave([...goodToHave, { ...entity, checked: false }]);
            setMustHave(mustHave?.filter((e: any) => e?.title !== entity?.title));
        }
    };

    return (
        <>
            {mergedEntities && mergedEntities?.length > 0 && (
                <VStack w={'full'} spacing={5}>
                    {mergedEntities?.map((entity: any) => (
                        <VStack borderRadius={"32px"}
                            w={'full'}
                            alignItems="flex-start"
                            bg={'#FFFFFF08'}
                            px={5}
                            py={7}
                            spacing={5}
                        >


                            <VStack key={entity.city}
                                w={'full'}
                                alignItems={"flex-start"}
                                spacing={5}
                            >
                                <Heading
                                    fontFamily={"Poppins"}
                                    fontWeight={700}
                                    fontSize={"16px"}
                                    lineHeight={"13px"}
                                    letterSpacing={"-0.3px"}
                                    color="#FFFFFFD4"
                                >
                                    {entity?.city}
                                </Heading>
                                <Box
                                    w={'full'}
                                    borderTop={'1px solid #FFFFFF21'}
                                />
                                <EntityCard
                                    entities={entity?.entities}
                                    onCheckboxChange={handleCheckboxChange}
                                />
                            </VStack>
                        </VStack>
                    ))}
                </VStack>
            )}
        </>
    );
};

export default Cards;