import React, { useState, useEffect } from 'react';
import {
	Flex,
	Box,
	Text,
	Divider,
	HStack,
	Image,
	VStack,
	Heading,
	Button,
} from '@chakra-ui/react';
// import TraverseBlackLogo from './TraverseBlackLogo';
import SocialAuthButton from './Buttons/SocialAuthButtons';
import { pixels } from '../theme';
import PhoneNumberInput from './PhoneNumberInput';
// import TrPrimaryButton from './TrPrimaryButton';
import useAxios from '../hooks/useAxios';
import { useNavigate } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import VerifyCode from './VerrifyCode';
import { useToast } from '@chakra-ui/react';
import * as amplitude from '@amplitude/analytics-browser';

interface LoginFields {
	phone_number: string;
}
interface SigninComponentProps {
	onClose: () => void;
}

const validateFields = (values: LoginFields) => {
	const errors: Partial<LoginFields> = {};

	if (!values.phone_number) {
		errors.phone_number = 'Phone number is required';
	} else if (!isValidPhoneNumber(values.phone_number)) {
		errors.phone_number = 'Invalid phone number';
	}

	return errors;
};

const SigninComponent: React.FC<SigninComponentProps> = ({ onClose }) => {
	const navigate = useNavigate();
	const { loading, fetchData } = useAxios();
	const { loading: loading2, fetchData: fetchData2 } = useAxios();
	const initLoginFields: LoginFields = { phone_number: '' };
	const [loginFields, setLoginFields] = useState<LoginFields>(initLoginFields);
	const [errors, setErrors] = useState<Partial<LoginFields>>({});
	const [loginMethod, setLoginMethod] = useState<string>('phone');

	const [serverError, setServerError] = useState<string>('');
	const [showVerifyCodeForm, setShowVerifyCodeForm] = useState<boolean>(false);

	const toast = useToast();
	const toShow = process.env.REACT_APP_ENV as string;
	const handleSkipToHomepage = () => {
		navigate('/'); // Navigate to the homepage
		onClose(); // Close the modal
	};

	useEffect(() => {
		const handleAuthCallback = () => {
			const token = localStorage.getItem('token');
			if (token) {
				// alert(token);
				// localStorage.removeItem('token');
				// navigate('/');
			}
		};

		window.addEventListener('storage', handleAuthCallback);

		return () => {
			window.removeEventListener('storage', handleAuthCallback);
		};
	}, [navigate]);

	const setPhoneValue = (value: string) => {
		setErrors({ ...errors, phone_number: '' });
		setLoginFields({ ...loginFields, phone_number: value });
	};

	const callbackActionAfterLogin = (token: string) => {
		amplitude.track('login', { status: 'fail', loginMethod: 'phone' });
		if (token) {
			localStorage.setItem('token', token);

			// Track successful login event
			// amplitude.setDeviceId(deviceInfo.device);
			// amplitude.getInstance().setUserProperties({
			// 	deviceType: deviceInfo.device.type,
			// });
			// // amplitude.setOS(deviceInfo.os.name);
			// amplitude.setOSVersion(deviceInfo.os.version);

			amplitude.track('login', { status: 'success', loginMethod: loginMethod });
		}
		window.location.reload();
	};

	const login = async () => {
		const formErrors = validateFields(loginFields);

		if (Object.keys(formErrors).length === 0) {
			const { statusCode, error } = await fetchData({
				method: 'POST',
				url: 'login/phone/request-code',
				options: {
					data: loginFields,
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (statusCode === 401) {
				navigate('/signup');
			}
			else if (statusCode === 200) {
				setServerError('');
				setShowVerifyCodeForm(true);
			}
			else if (statusCode === 404) {
				navigate('/signup');
			}
			else {
				setServerError(JSON.stringify(error));
			}
		} else {
			setErrors(formErrors);
		}
	};

	const socialAuth = async (name: string) => {
		try {
			const response = await fetchData2({
				method: 'GET',
				url: `authorize/${name}`,
				options: {
					headers: { 'Content-Type': 'application/json' },
				},
				auth: false,
			});
			if (response?.data?.auth_url) {
				window.location.href = response.data.auth_url;

			} else {
				setServerError('Failed to get authorization URL');
			}
		} catch (error) {
			setServerError('Failed to get authorization URL');
		}
	};
	const handleFail = () => {
		toast({
			title: 'Invalid OTP',
			description: 'The OTP you entered is incorrect. Please try again.',
			status: 'error', // Displays the toast with error styling
			duration: 5000, // Toast will disappear after 5 seconds
			isClosable: true, // Adds a close button to the toast
		});
	};

	return (
		<Flex
			w='100dvw'
			bgImage={[`url('/images/bg_images/loginBg.jpg')`, `url('/images/bg_images/loginBG.png')`, `url('/images/bg_images/loginBG.png')`]}
			bgPosition={'center'}
			bgRepeat={'no-repeat'}
			bgSize={'cover'}
			justifyContent={'center'}
			alignItems={'center'}
			zIndex={5}
			h={'100vh'}
			position="relative"
		>
			<Box
				// display={{ base: 'flex', lg: 'none' }} // Visible only on mobile
				h={'100%'}
				w={'100%'}
				position={'absolute'}
				top={0}
				zIndex={-1}
				bottom={0}
				bg='linear-gradient(180deg, rgba(0, 0, 0, 0) 36.62%, rgba(0, 0, 0, 0.93) 92.61%), linear-gradient(180deg, rgba(0, 0, 0, 0.63) 6.48%, rgba(0, 0, 0, 0) 48.59%)'
			/>
			<VStack
				w='100%'
				h={['90vh', '87vh', '85vh', '83vh']}
				py={[6, 4, 3, 2]}
				justifyContent={'space-between'}>
				<VStack >
					<Image src='/icons/logo.svg' alt='logo' w='42.27px' />
					<VStack alignItems='center' m={-2} p={0}>
						<Heading
							fontFamily='Poppins'
							fontSize={['26.97px']}
							fontWeight='700'
							lineHeight='32.59px'
							letterSpacing='-0.34px'
							textAlign='left'
							color='#FFFFFFED'
							p={0}>
							Rahee AI
						</Heading>
						<Heading
							fontFamily='Poppins'
							fontSize='11px'
							mt={-2}
							fontWeight='500'
							lineHeight='13.2px'
							letterSpacing='-2%'
							textAlign='center'
							color='#EEEEEEA1'>
							SIGN IN TO CONTINUE
						</Heading>
					</VStack>
				</VStack>
				<Box>
					{!showVerifyCodeForm ? (
						<VStack spacing={[4, 3, 2]}>
							{toShow === "DEV" && (<SocialAuthButton
								onClick={() => {
									setLoginMethod('google');
									socialAuth('google')
								}}
								colorScheme='#4889f4'
								iconUrl='/icons/google.svg'
								name='Sign in with Google'
								isLoading={loading2}
							/>)}

							{toShow === "DEV" && (<SocialAuthButton
								colorScheme='linear-gradient(90deg, rgba(93, 84, 202, 0.99) 0%, rgba(167, 79, 148, 0.99) 51%, rgba(238, 75, 96, 0.99) 100%)'
								iconUrl='/icons/instagram.svg'
								name='Instagram'
								onClick={() => {
									setLoginMethod('instagram');
									socialAuth('instagram')
								}}
								isLoading={loading2}
							/>)}

							{toShow === "DEV" && (<HStack borderColor='trGrey'>
								<Divider w='118px' borderColor='white' />
								<Text
									mx={pixels['10pixels']}
									textStyle='sm'
									whiteSpace='nowrap'
									color='white'>
									or
								</Text>
								<Divider w='118px' borderColor='white' />
							</HStack>
							)}
							<PhoneNumberInput
								isInvalid={!!errors.phone_number}
								isDisable={loading || loading2}
								value={loginFields.phone_number}
								onChange={(value) => setPhoneValue(value)}
							/>
							<Button
								display={'flex'}
								justifyContent={'space-between'}
								w={'100%'}
								h={['58px', '53px', '44.8px']}
								borderRadius={'99px'}
								border='1px solid #FFFFFF21'
								px={'24px'}
								onClick={login}
								bg={'#FFFFFF'}>
								<Text
									fontFamily='Poppins'
									fontSize='13px'
									fontWeight='400'
									lineHeight='18px'
									textAlign='left'
									color={'#16181B'}>
									Login using Phone / Whatsapp
								</Text>
								<Image src={'/icons/arrow-right.svg'} alt={'arrow'} />
							</Button>
							<Button
								onClick={handleSkipToHomepage}
								mt={['34px', '30px', '0']}
								_hover={{ bg: 'none' }}
								variant={'Link'}>
								<Text
									fontFamily='Poppins'
									fontSize='13px'
									fontWeight='500'
									lineHeight='18px'
									textAlign='center'
									color={'#FFFFFFA1'}>
									Skip to Homepage
								</Text>
							</Button>
						</VStack>
					) : (
						<VerifyCode
							onVerifySuccess={callbackActionAfterLogin}
							phone_number={loginFields.phone_number}
							onFail={handleFail}
						/>
					)}
				</Box>
			</VStack>
		</Flex>
	);
};

export default SigninComponent;
