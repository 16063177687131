import { Box, Flex } from "@chakra-ui/react";
import { useEffect, useState } from "react";

interface StoryProgressProps {
    mediaIndex: any;
    totalMedia: any;
    duration?: any;
}

const StoryProgress: React.FC<StoryProgressProps> = ({ mediaIndex, totalMedia, duration = 3000 }) => {

    const [progress, setProgress] = useState(0);

    useEffect(() => {
        setProgress(100); // Reset progress when mediaIndex changes

        // const interval = setInterval(() => {
        //     setProgress((prev) => (prev < 100 ? prev + 1 : 100));
        // }, duration / 100); // Smooth animation

        // return () => clearInterval(interval);
    }, [mediaIndex, duration]);

    return (
        <Flex
            position="absolute"
            top="10px"
            left="0"
            right="0"
            px={4}
            gap={2}
            zIndex={9999} // Ensures visibility
            justifyContent="center"
        >
            {[...Array(totalMedia)].map((_, index) => (
                <Box
                    key={index}
                    flex="1"
                    height="2px"
                    borderRadius="full"
                    background="rgba(255, 255, 255, 0.3)"
                    overflow="hidden"
                    position="relative"
                >
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        height="100%"
                        width={
                            index < mediaIndex
                                ? "100%" // Fully filled for previous items
                                : index === mediaIndex
                                    ? `${progress}%` // Animating progress
                                    : "0%"
                        }
                        background="white"
                        // transition="width 0.1s linear"
                    />
                </Box>
            ))}
        </Flex>
    );
};

export default StoryProgress;
