import {
    Box,
    HStack,
    Badge,
    IconButton,
    Collapse,
    useDisclosure,
    Stack,
    useBreakpointValue,
    Text,
    BoxProps
} from '@chakra-ui/react';
import { MinusIcon, ChevronDownIcon } from '@chakra-ui/icons';
import React, { useCallback, memo } from 'react';
import GenericItineraryCard from './GenericItineraryCard';

// Define strong TypeScript interfaces
interface Transportation {
    description: string;
    from: string;
    to: string;
    maps_route?: string;
    costINR?: number;
}

interface Activity {
    // Add appropriate fields based on your data structure
    name: string;
    [key: string]: any;
}

interface DayItinerary {
    day: number;
    title: string;
    accommodation?: any;
    transportation?: Transportation[];
    activities?: Activity[];
    eateries?: Activity[];
    miscellaneous?: string;
}

interface ItineraryCardProps {
    dayItinerary: DayItinerary;
}

// Reusable divider component to avoid repetition
const Divider: React.FC<BoxProps> = (props) => (
    <Box
        borderBottom={'1px solid #FFFFFF21'}
        mt={[4, 4, 8]}
        mb={[4, 4, 8]}
        {...props}
    />
);

const ItineraryCard: React.FC<ItineraryCardProps> = memo(({ dayItinerary }) => {
    const { isOpen, onToggle } = useDisclosure();
    
    // Memoize the icon to prevent unnecessary calculations
    const icon = useBreakpointValue({
        base: isOpen ? <MinusIcon boxSize={4} /> : <></>,
        md: <></>,
        lg: isOpen ? <></> : <ChevronDownIcon boxSize={4} />,
    });
    
    // Use useCallback for event handlers
    const handleToggle = useCallback((e: React.MouseEvent) => {
        e.stopPropagation();
        onToggle();
        // Prevent event bubbling if needed
    }, [onToggle]);

    return (
        <Stack bg={["#FFFFFF12", "#FFFFFF12", "none"]} mt={'4px'} w='full' borderRadius={'12px'} onClick={handleToggle} _hover={{ cursor: "pointer" }}>
            <Box
                display={["none", "none", "block"]}
                borderTop={'1px solid #FFFFFF21'}
                mt={[4, 0, 0]}
            />
            <HStack
                alignItems='center'
                justifyContent="space-between"
                mx={[3, 3, 0]} mt={[isOpen ? 4 : 3, isOpen ? 4 : 3, isOpen ? 4 : 2]}
                mb={[isOpen ? 0 : 3, isOpen ? 0 : 3, 0]}
                >

                <Badge
                    bg={isOpen ? ['#AE7CFF', '#AE7CFF', 'none'] : ['#FFFFFF21', '#FFFFFF21', 'none']}
                    color={["#FFFFFFD4", "#FFFFFFD4", "#FFFFFFED"]}
                    px={[4, 4, 0]}
                    py={2}
                    maxW={"80%"}
                    textTransform={"capitalize"}
                    rounded={['full', 'full', 'none']}
                    fontSize={["13px", "13px", "33px"]}
                >
                    <Text
                        fontFamily={'Poppins'}
                        isTruncated={!isOpen}
                        noOfLines={isOpen ? 2 : 1}
                        whiteSpace={isOpen ? 'normal' : 'nowrap'}
                    >
                        {`Day ${dayItinerary?.day} : ${dayItinerary?.title}`}
                    </Text>
                </Badge>
                <IconButton
                    aria-label={isOpen ? 'Collapse' : 'Expand'}
                    icon={icon}
                    bg={[isOpen ? '#FFFFFF0D' : 'none', 'none', 'none']}
                    color='#FFFFFFA1'
                    borderRadius='full'
                    onClick={handleToggle}
                    _hover={{ bg: 'none' }}
                />
            </HStack>
            <Collapse in={isOpen} animateOpacity>
                <Box px={[4, 4, 0]}>
                    <GenericItineraryCard
                        day={dayItinerary?.day}
                        accommodation={dayItinerary?.accommodation}
                    />
                    {dayItinerary?.transportation?.map((item, index) => (
                        <React.Fragment key={`transportation-${index}`}>
                            <Divider />
                            <GenericItineraryCard
                                title={item?.description}
                                locationFrom={item?.from}
                                locationTo={item?.to}
                                maps_route={item?.maps_route}
                                costINR={item?.costINR}
                            />
                        </React.Fragment>
                    ))}
                    
                    {dayItinerary?.activities && (
                        <GenericItineraryCard
                            activities={dayItinerary?.activities} 
                        />
                    )}
                    
                    {dayItinerary?.eateries && (
                        <React.Fragment>
                            <Divider />
                            <GenericItineraryCard
                                activities={dayItinerary?.eateries} 
                            />
                        </React.Fragment>
                    )}

                    {dayItinerary?.miscellaneous && (
                        <Box mb={2}>
                            <Divider />
                            <GenericItineraryCard
                                title='Miscellaneous'
                                details={dayItinerary?.miscellaneous}
                            />
                        </Box>
                    )}
                </Box>
            </Collapse>
        </Stack>
    );
});

export default ItineraryCard;