import { AppContext } from "../context/AppContext";
import useAxios from "../hooks/useAxios";
import { useCallback, useContext } from 'react';
import { transformMessage } from "../utilities/transformMessage";


const useQueryService = () => {
    const { fetchData } = useAxios();
    const context: any = useContext(AppContext);
    const { currChatId, setCurrChatId, setChatEntries, userDetail, setIsChatLoading, onBehalf, setOnBehalf, setTitle, chatEntries } = context;

    const fetchQueryResponse = useCallback(async (
        message?: any,
        onBeh?: any
    ) => {
        try {
            setIsChatLoading(true);
            const meessageId = window.crypto.randomUUID();
            const onBe = onBeh ?? onBehalf;
            console.log("onBe", onBe);
            setOnBehalf({});
            const mess = [{
                _id: meessageId,
                text: message,
                type: "text",
                author: "user",
                time: new Date().toISOString(),
                onBehalf: Object.keys(onBe || {}).length === 0 ? undefined : onBe
            }];
            const modifiedMess = mess.map((msg: any) => {
                if (Object.keys(onBe || {}).length === 0) {
                    return {
                        ...msg,
                        text: message
                    };
                } else {
                    return {
                        ...msg,
                        text: `${onBe["title"]} : ${message}`
                    };
                }
            });
            console.log("message", mess);
            if (chatEntries.length === 0) {
                setTitle(message);
            }
            await setChatEntries((prevChat: any) => [...prevChat, mess?.[0]]);
            await setChatEntries((prevChat: any) => [...prevChat, { text: "...", author: "assistant", type: "text" }]);



            const { data, statusCode } = await fetchData({
                method: "POST",
                url: `v2/conversation/query`,
                options: {
                    data: {
                        token: localStorage.getItem("token"),
                        user_id: userDetail?.user_id,
                        conversation_id: currChatId ?? "",
                        messages: modifiedMess ?? [],
                    },
                    headers: { "Content-Type": "application/json" },
                },
                auth: true,
            });
            if (statusCode === 200) {
                setCurrChatId(data?.conversation_id);
                const transformedMessage = transformMessage(data?.message);
                console.log(transformedMessage);

                await setChatEntries((prevChat: any) => {
                    const newChat = [...prevChat];
                    if (newChat.length > 0) {
                        newChat[newChat.length - 1] = { ...newChat[newChat.length - 1], ...transformedMessage };
                    } else {
                        newChat.push({ ...transformedMessage });
                    }
                    return newChat;
                });
                setIsChatLoading(false);
            }
        } catch (error) {
            setIsChatLoading(false);
            console.error("Fetch error:", error);
        }
        // eslint-disable-next-line
    }, [fetchData, setChatEntries]); // Add fetchData and setChatEntries to the dependency array

    return { fetchQueryResponse };
};

export default useQueryService;