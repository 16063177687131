import {
  VStack,
  Image,
  Heading,
  Box,
  Button,
  IconButton,
} from "@chakra-ui/react";
import Detail from "./Detail";
import { useNavigate } from "react-router-dom";
import useAxios from "../../hooks/useAxios";
import { useContext, useEffect, useState } from "react";
import ItineraryFooter from "../CreateItinary/ItineraryFooter";
import { AppContext } from "../../context/AppContext";
import * as amplitude from '@amplitude/analytics-browser';

const ProfileView: React.FC = () => {
  const [email, setEmail] = useState("");
  const [instagram, setInstagram] = useState("");
  const [phoneno, setPhoneNo] = useState("");
  const [travelerDetails, setTravelerDetails] = useState("");

  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editTravelerDetails, setEditTravelerDetails] = useState(false);
  const [editInstaDetails, setEditInstaDetails] = useState(false);

  const context: any = useContext(AppContext);
  const { userDetail, setUserDetail } = context;

  const { fetchData } = useAxios();

  useEffect(() => {
    const fetchUserDetail = async () => {
      const { data } = await fetchData({
        method: "GET",
        url: `user/profile`,
        options: {
          data: {},
          headers: { "Content-Type": "application/json" },
        },
        auth: true,
      });
      if (data) {
        setUserDetail(data);
        if (data.email) setEmail(data.email);
        if (data.phone_number) setPhoneNo(data.phone_number);
        if (data.traveler_details) setTravelerDetails(data.traveler_details);
        if (data.insta_id) setInstagram(data.insta_id);
      }
    };
    if (Object.keys(userDetail).length === 0) {
      fetchUserDetail();
    } else {
      setEmail(userDetail.email);
      setPhoneNo(userDetail.phone_number);
      setTravelerDetails(userDetail.traveler_details);
      setInstagram(userDetail.insta_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveUserDetail = async () => {
    const updatedData: {
      email?: string;
      phone_number?: string;
      traveler_details?: string;
      insta_id?: string;
    } = {};

    if (email !== userDetail.email) {
      updatedData.email = email;
    }
    if (phoneno !== userDetail.phone_number) {
      updatedData.phone_number = phoneno;
    }
    if (travelerDetails !== userDetail.traveler_details) {
      updatedData.traveler_details = travelerDetails;
    }
    if (instagram !== userDetail.insta_id) {
      updatedData.insta_id = instagram;
    }

    if (Object.keys(updatedData).length > 0) {
      const { data } = await fetchData({
        method: "PUT",
        url: `user/profile`,
        options: {
          data: updatedData,
          headers: { "Content-Type": "application/json" },
        },
        auth: true,
      });
      if (data) {
        setUserDetail(data);
        if (data.email) setEmail(data.email);
        if (data.phone_number) setPhoneNo(data.phone_number);
        if (data.traveler_details) setTravelerDetails(data.traveler_details);
        if (data.insta_id) setInstagram(data.insta_id);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps

  const handleSaveEmail = async () => {
    setEditEmail(false);
    await saveUserDetail();
    setUserDetail((prevDetails: any) => ({
      ...prevDetails,
      email: email,
    }));
  };

  const handleSavePhone = async () => {
    setEditPhone(false);
    await saveUserDetail();
    setUserDetail((prevDetails: any) => ({
      ...prevDetails,
      phone_number: phoneno,
    }));
  };

  const handleSaveInsta = async () => {
    setEditInstaDetails(false);
    await saveUserDetail();
    setUserDetail((prevDetails: any) => ({
      ...prevDetails,
      insta_id: instagram,
    }));
  };

  const handleSaveAboutMe = async () => {
    setEditTravelerDetails(false);
    await saveUserDetail();
    setUserDetail((prevDetails: any) => ({
      ...prevDetails,
      traveler_details: travelerDetails,
    }));
  };

  const router = useNavigate();
  return (
    <Box
      bg={'grey'}
    >
      <Box
        position="fixed"
        top="0"
        left="0"
        width="100vw"
        height="100dvh"
        overflowY={"auto"}
        bgColor="blackAlpha.900"
      // zIndex={1} // Ensures it appears above other content
      />
      <IconButton
        aria-label="Back"
        position="fixed"
        top="16px"
        left="16px"
        zIndex={9999}
        icon={<Image src="/icons/Back_Icon_White.svg" alt="back" />}
        colorScheme="gray"
        variant="link"
        size="lg"
        onClick={() => {
          router(`/`);
        }}
      />
      <VStack
        mt={"40%"}
        h={"100%"}
        spacing={0}
        overflowY="hidden"
        bg={'white'}
        overflowX={"hidden"}
        zIndex={10}
        height={"100%"}
        paddingBottom="30%"
      >
        <Image
          src={"/icons/user2.svg"}
          alt="user"
          boxSize="72px"
          zIndex={56}
          position={"absolute"}
          objectFit={"cover"}
          mt={-37}
          borderRadius="50%"
          p={3}
          bg="#2E2B31"
        />
        <Heading
          mt={"12%"}
          fontFamily="Poppins"
          fontSize="20px"
          fontWeight="600"
          lineHeight="20px"
          letterSpacing="-0.5px"
          textAlign="center"
          mb={"36px"}
          zIndex={5}
          color={"#FFFFFF"}
        >
          Traveller Profile
        </Heading>

        <Detail
          label="Email"
          value={email}
          buttonText={editEmail ? "Save" : "Edit"}
          editMode={editEmail}
          onEditClick={() =>
            editEmail ? handleSaveEmail() : setEditEmail(true)
          }
          onChange={(e) => {
            const value = typeof e === 'string' ? e : e.target.value;
            setEmail(value);
          }}
        />
        <Detail
          label="Phone"
          value={phoneno}
          buttonText={editPhone ? "Save" : "Edit"}
          editMode={editPhone}
          onEditClick={() =>
            editPhone ? handleSavePhone() : setEditPhone(true)
          }
          // Change from using e.target.value to using the string directly.
          onChange={(value) => {
            if (typeof value === 'string') {
              setPhoneNo(value);
            }
          }} />
        <Detail
          label="Instagram"
          value={instagram}
          buttonText={editInstaDetails ? "Save" : "Edit"}
          editMode={editInstaDetails}
          onEditClick={() =>
            editInstaDetails ? handleSaveInsta() : setEditInstaDetails(true)
          }
          onChange={(e) => {
            const value = typeof e === 'string' ? e : e.target.value;
            setInstagram(value);
          }}
        />
        <Detail
          label="Tell us what kind of a traveller you are"
          value={travelerDetails}
          buttonText={editTravelerDetails ? "Save" : "Edit"}
          editMode={editTravelerDetails}
          onEditClick={() =>
            editTravelerDetails
              ? handleSaveAboutMe()
              : setEditTravelerDetails(true)
          }
          // Replace the onChange callback for traveler details with a type guard:
          onChange={(e) => {
            const value = typeof e === 'string' ? e : e.target.value;
            amplitude.track('Traveler_Details_Edited', { travelerDetails: value });
            setTravelerDetails(value);
          }}
        />
        <Button
          mt={"12%"}
          w="74px"
          h="20px"
          variant="link"
          fontFamily="Poppins"
          fontSize="20px"
          fontWeight="600"
          lineHeight="20px"
          letterSpacing="-0.5px"
          textAlign="center"
          color="#AE7CFF"
          zIndex={5}
          onClick={() => {

            amplitude.track('button_clicked', { buttonName: 'Log-out' });
            amplitude.setUserId(undefined);
            localStorage.removeItem("token");
            router("/");
            window.location.reload();
          }}
        >
          Log Out
        </Button>
      </VStack>
      <ItineraryFooter />
    </Box>
  );
};

export default ProfileView;
