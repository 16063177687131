import { Box, Flex, Heading, HStack, Spinner, Text, VStack, Image, Divider } from "@chakra-ui/react"
import { useContext, useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { AppContext } from "../../context/AppContext";

interface OptionProps {
    entity: any;
    day?: string;
    handleClose: () => void;

}

const Option: React.FC<OptionProps> = ({
    entity,
    day,
    handleClose
}) => {
    const url = window.location.href;
    const id = url.split("/").pop();
    const { fetchData } = useAxios();
    const [loading, setLoading] = useState(false);
    const [entities, setEntites] = useState([]);
    const context: any = useContext(AppContext);
    const { setCurrItinerary } = context;


    const handleClick = async (item: any) => {

        const adjustedDay = typeof day === "string" ? parseInt(day) - 1 : (day ?? 1) - 1;
        setLoading(true);

        const { data } = await fetchData({
            method: "POST",
            url: `ai/v1/discrete_update_itinerary`,
            options: {
                data: {
                    itinerary_id: id,
                    day_idx: adjustedDay,
                    change_type: "accommodation",
                    item_idx: 0,
                    new_item: {
                        name: item?.name,
                        place_name: item?.place_name,
                        type: "accommodation",
                        city: item?.city,
                        country: item?.country,
                    }
                },
                headers: { "Content-Type": "application/json" },
            },
            auth: true,
        });
        if (data) {
            const itn = data?.plan;

            itn.id = data?._id;
            itn.conversation_id = data?.conversation_id;
            itn.new = true;
            await setCurrItinerary(itn);
            handleClose();
            setLoading(false);



        }
    }



    useEffect(() => {
        const fetchAccomData = async () => {
            setLoading(true);
            const { data } = await fetchData({
                method: "POST",
                url: `ai/v1/get_alternatives`,
                options: {
                    data: {
                        entity: entity,
                        itinerary_id: id
                    },
                    headers: { "Content-Type": "application/json" },
                },
                auth: true,
            });
            console.log("Response:", data);
            if (data) {
                setEntites(data.entities);
            }
            setLoading(false);
        };
        fetchAccomData();
        // eslint-disable-next-line react-hooks/exhaustive-deps                         
    }, []);





    return (

        <Box
            w={"100%"}
            h={"100%"}
            display="flex"
            justifyContent="center"
            bg="rgba(0, 0, 0, 0.91)"
            alignItems="center"
        >
            {loading ? (
                <Box w="100%" h="100%" position="relative">
                    <Flex
                        position="fixed"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="rgba(0, 0, 0, 0.91)"
                        zIndex="9999"
                        justifyContent="center"
                        alignItems="center"
                        backdropFilter="blur(5px)"
                    >
                        <Box textAlign="center">
                            <Spinner
                                size="xl"
                                color="purple.500"
                                thickness="4px"
                                speed="0.65s"
                            />
                            <Text mt={4} color="white">
                                {entities?.length > 0 ? "Updating Itinerary..." : "Loading options..."}
                            </Text>
                        </Box>
                    </Flex>

                </Box>
            ) : (
                <Box bg="rgba(0, 0, 0, 0.91)" w="100%" h="100%" py={9} px={4}>
                    <Text
                        fontFamily="Poppins"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="22px"
                        letterSpacing="0%"
                        color={"#FFFFFFED"}>
                        Alternatives
                    </Text>
                    <Divider my={2} borderColor="#FFFFFF0D" />
                    {entities?.length > 0 && entities?.map((entity: any, index: number) => (
                        <Box>
                            <HStack
                                key={index} // Add a key prop to each element in the list
                                spacing={4}
                                p={2}
                                w={'full'}
                                borderRadius="lg"
                                alignItems="flex-start"
                                justifyContent={"flex-start"}
                                border={"1px solidrgba(102, 99, 99, 0.77)"}
                                onClick={() => {
                                    handleClick(entity);
                                }}

                            >
                                <HStack justify="space-between" w="full" alignItems="flex-start">

                                    <HStack
                                        w={"95%"}
                                        alignItems={"flex-start"}
                                        spacing={6}
                                    >
                                        {/* <Box
                                        w="64px"
                                        h="64px"
                                    >
                                        <Image
                                            src={entity?.image}
                                            alt={entity?.name}
                                            objectFit={"cover"}
                                            w="100%"
                                            h="100%"
                                            borderRadius="8px"
                                        />
                                    </Box> */}

                                        <VStack
                                            flex={1}
                                            alignItems="flex-start"
                                            spacing={1}
                                        >
                                            <Heading
                                                fontFamily="Poppins"
                                                fontWeight="700"
                                                fontSize="14px"
                                                lineHeight="19px"
                                                letterSpacing="0%"
                                                color={"#FFFFFFED"}
                                            >
                                                {entity?.name}
                                            </Heading>
                                            <Text
                                                fontFamily="Poppins"
                                                fontWeight="500"
                                                fontSize="12px"
                                                lineHeight="17px"
                                                letterSpacing="0%"
                                                textDecoration="underline"
                                                textDecorationStyle="solid"
                                                textDecorationThickness="0%"
                                                color={'#FFFFFFA1'}
                                            >
                                                {[entity?.city, entity?.country].filter(Boolean).join(", ")}
                                            </Text>
                                        </VStack>
                                    </HStack>
                                </HStack>
                            </HStack>
                        </Box>
                    ))}
                </Box>
            )}
        </Box>
    )
};
export default Option;