import {
  // Flex,
  // Divider,
  // Box,
  // Heading,
  VStack,
  Text,
  Input,
  Image,
  // Container,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import MyCountryBucket from "../components/MyCountryBucket";
// import { pixels } from "../theme";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";

const MyBucketList = () => {
  const context = useContext(AppContext);
  const { likeSearches, likeEntities } = context;
  const [countries, setCountries] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const uniqueCountries = new Set();
    console.log("like Searches, ", likeSearches);
    console.log("like Entities, ", likeEntities);

    likeSearches.forEach((item) => {
      if (item.countries && Object.keys(item.countries).length > 0) {
        Object.keys(item.countries).forEach((countries) =>
          uniqueCountries.add(countries)
        );
      } else {
        uniqueCountries.add(undefined);
      }
    });

    console.log("uniqueContries ", uniqueCountries);

    likeEntities.forEach((item) => {
      uniqueCountries.add(item.country);
    });

    let sortedCountries = Array.from(uniqueCountries);

    const hasUndefined = sortedCountries.includes(undefined);

    sortedCountries = sortedCountries
      .filter((country) => country !== undefined)
      .sort();

    if (hasUndefined) {
      sortedCountries.push(undefined); // Fallback to end if no match found
    }

    setCountries(sortedCountries);

    console.log(
      "this is my sorted countries with undefined preserved: ",
      sortedCountries
    );
  }, [likeSearches, likeEntities]);

  const filteredItems = countries?.filter((country) => {
    if (country === undefined) {
      return "Miscellaneous".toLowerCase().includes(searchQuery.toLowerCase());
    }
    return country?.toLowerCase().includes(searchQuery.toLowerCase());
  });

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  return (
    
    <VStack w="full">
      <VStack
        position="fixed"
        top={[20, 40, 40]} // Adjust based on `TabList` height
        zIndex={3}
        px={[4, 0, 0]}
        w={["100%", "85%", "85%", "85%"]}
      >
        <InputGroup>
          <Input
            placeholder="Japan, India…"
            _placeholder={{ color: "#B0B0B0" }}
            bg="#242127"
            color="#FFFFFF"
            borderRadius="12px"
            border="1px solid #FFFFFF12"
            h="48px"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputLeftElement py="25px" >
            <Image
              boxSize="18px"
              src="/icons/search2.svg"
              alt="search"
              cursor="pointer"
            />
          </InputLeftElement>
        </InputGroup>
      </VStack>

      {/* Scrollable List */}
      <VStack
        alignItems={["center", "flex-start", "flex-start"]}
        mt={[20, "140px", "140px"]}
        w={"100%"}
      >
        {countries?.length > 0 ? (
          filteredItems?.length > 0 ? (
            filteredItems.map((country, index) => (
              <MyCountryBucket key={index} country={country} index={index} />
            ))
          ) : (
            <Text color="#9D9D9D" fontSize="16px">
              No countries found
            </Text>
          )
        ) : (
          <Text
            color="rgba(255, 255, 255, 0.45)"
            textAlign="center"
            fontFamily="Poppins"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="133%"
            mt="50%"
          >
            ✨ Your bucket list is looking a little empty! Start liking your
            favourite spots or send us a video on WhatsApp 📲 to save the places
            you can't wait to explore. 🌍
          </Text>
        )}
      </VStack>
    </VStack>
  );
};

export default MyBucketList;
