import {
	Box,
	// Flex,
	// Text,
	Heading,
	// IconButton,
	// useDisclosure,
	// ModalOverlay,
	// Modal,
	// ModalContent,
	// ModalBody,
} from '@chakra-ui/react';
// import TopHighlightItem from './TopHighlightItem';
// import { pixels } from '../../theme';
import { useContext, useEffect, useState } from 'react';
// import Carousel from 'react-multi-carousel';
import { AppContext } from '../../context/AppContext';
// import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
// import MapComponent from '../TrMapLeaf/TrMapLeaflet';
import { VideoHighlights } from '../InfluencerFlow/VideoHighlights';

// interface TopHighlightItemProps {
// 	image: string;
// 	city: string;
// 	country: string;
// 	url: string;
// 	best?: any;

// 	best_value: [string];
// 	title: string;
// 	description: string;
// 	tags?: any;
// 	entity_best_time_to_visit?: any;
// 	vibe?: any;

// 	location?: { lat: number; lng: number };
// }

// interface TopHighlightsProps {
// 	onOpenDetailsModal: () => void;
// }

// const CustomLeftArrow = ({ onClick }: any) => {
// 	return (
// 		<IconButton
// 			minW={'30px !important'}
// 			h='30px'
// 			borderRadius={'50%'}
// 			top={'15%'}
// 			left={'-18px'}
// 			transform={'translateY(-50%)'}
// 			position={'absolute'}
// 			aria-label='previous-button'
// 			onClick={onClick}
// 			icon={
// 				<ChevronLeftIcon
// 					width={pixels['13pixels']}
// 					height={pixels['13pixels']}
// 				/>
// 			}
// 			color={'white'}
// 			backgroundColor='rgba(57, 56, 59, 0.93)'
// 		/>
// 	);
// };

// const CustomRightArrow = ({ onClick }: any) => {
// 	return (
// 		<IconButton
// 			minW={'30px !important'}
// 			h='30px'
// 			borderRadius={'50%'}
// 			top={'15%'}
// 			right={{ 'base': 0, 'lg': '-19px', '2xl': '-19px' }}
// 			transform={'translateY(-50%)'}
// 			position={'absolute'}
// 			aria-label='next-button'
// 			onClick={onClick}
// 			icon={
// 				<ChevronRightIcon
// 					width={pixels['13pixels']}
// 					height={pixels['13pixels']}
// 				/>
// 			}
// 			color={'white'}
// 			backgroundColor='rgba(57, 56, 59, 0.93)'
// 		/>
// 	);
// };

const TopHighlights = () => {
	// const { isOpen, onOpen, onClose } = useDisclosure();
	const { currentVideoIndex, searchDetail, likeSearches }: any = useContext(AppContext);
	const [isLiked, setIsLiked] = useState(false);

	// const [highLightIndex, setHighlightIndex] = useState(0);

	// const responsive = {
	// 	superLargeDesktop: {
	// 		breakpoint: { max: 4000, min: 3000 },
	// 		items: 3,
	// 		partialVisibilityGutter: 100,
	// 	},
	// 	desktop: {
	// 		breakpoint: { max: 3000, min: 1024 },
	// 		items: 3,
	// 	},
	// 	tablet: {
	// 		breakpoint: { max: 1024, min: 464 },
	// 		items: 2,
	// 		paritialVisibilityGutter: 100,
	// 	},
	// 	mobile: {
	// 		breakpoint: { max: 464, min: 464 },
	// 		items: 3,
	// 	},
	// };

	// const openModal = (index: number = 0) => {
	// 	setHighlightIndex(index);
	// 	onOpen();
	// };

	useEffect(() => {
		setIsLiked(false);
		console.log('searchDetail', typeof (searchDetail['videos']));
		if (searchDetail) {
			for (let video of likeSearches) {
				if (video?.url === searchDetail?.videos?.[currentVideoIndex]?.video_url || video?.url?.includes(searchDetail?.videos?.[currentVideoIndex]?.video_id)) {
					setIsLiked(true);
				}
			}
		}
	}, [likeSearches, currentVideoIndex, searchDetail]);

	return (
			<Box px={'20px'} display={{ base: 'block', md: 'none', lg: 'none' }}>
				{searchDetail &&
					searchDetail?.videos?.length > 0 &&
					searchDetail?.videos[currentVideoIndex] &&
					searchDetail?.videos[currentVideoIndex].highlights?.length ? (
					<>
						<Heading
							fontFamily='Poppins'
							fontSize='20px'
							fontWeight='600'
							lineHeight='30px'
							letterSpacing='-0.10000001192092896px'
							textAlign='left'
							color={'white'}
							mb={'32px'}>
							Video Highlights
						</Heading>
						<VideoHighlights highlights={searchDetail?.videos[currentVideoIndex].highlights} />
					</>
				) : (
					<Heading
						fontFamily='Poppins'
						fontSize='20px'
						fontWeight='600'
						lineHeight='30px'
						letterSpacing='-0.0000001192092896px'
						textAlign='left'
						color={'#FFFFFFB3'}
						mb={'32px'}>
						{isLiked ? "Video highlights are being generated" : "Like the video to generate highlights"}
					</Heading>
				)}
			</Box>
	);
};

export default TopHighlights;
