import { Flex, VStack } from '@chakra-ui/react'
import { Box, Text, Heading } from '@chakra-ui/react';
import Header from '../components/TravelAdvisor/Header';
import ResortCard from '../components/TravelAdvisor/ResortCard';
// import Slider from 'react-slick';

import InputField from '../components/TravelAdvisor/InputField';
import { useNavigate } from 'react-router-dom';

const TravelAdvisor: React.FC = () => {
  const navigate = useNavigate();
  // const settings = {
  //   dots: false,
  //   infinite: true,
  //   speed: 200,
  //   slidesToShow: 1,
  //   centerMode: true,
  //   // centerPadding: '-20px',
  //   arrows: true,
  //   autoplay: true,
  //   autoplaySpeed: 4000,
  // };
  // 

  return (
    <Box
      width="100%"
      height="100vh"
      backgroundImage='/images/bg_images/Bg_4.png'
      position='relative'
      overflow='hidden'
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Box>
        <Header
          backIconSrc="/icons/Back_Icon_White.svg"
          chatIconSrc="/icons/chat_filled copy.svg"
          handleBackButton={() => { navigate(-1) }}
          chatButtonOnClick={() => {
            navigate('/navigate', {
              state: {
                index: 3
              }
            })
          }}
          bgColor="#2F2F32"
          borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />
        <Flex mb={'36px'}
          direction='column'
          align={"center"}>
          <Text color={"#FFFFFF"}
            fontSize={"23px"}
            fontWeight={700}>
            Your personal
          </Text>
          <Text
            bgGradient="linear-gradient(90deg, #9BA1F2 0%, #F9EEC5 100%)"
            bgClip={"text"}
            fontSize={"23px"}
            fontWeight={700}>
            Travel Advisor
          </Text>
          <Text fontWeight={500} fontSize={"11px"} color={"#FFFFFFA1"} mt={"2px"}>
            Start your journey with our specialised advisors
          </Text>
        </Flex>
        {/* <Slider {...settings} > */}
        <VStack>
          <ResortCard
            // title="Resort Rendezvous"
            // subtitle="specialises in"
            imageSrc="/images/f1.png"
            question1="Best staycation getaways near me"
            question2="Best beach resorts in Thailand"
          />
          {/* <ResortCard
            title="Family vacation with kids"
            subtitle="specialises in"
            imageSrc="/images/f2.png"
            question1="Must-Visit Theme Parks for Kids"
            question2="Toddler friendly trip to Italy"
          />
          <ResortCard
            title="Romantic Retreats"
            subtitle="specialises in"
            imageSrc="/images/f3.png"
            question1="Trending Honeymoon destinations"
            question2="weekend getaways for couples"
          /> */}
          {/* </Slider> */}
        </VStack>
        <Flex bg={'#FFFFFF'}
          zIndex={1000}
          direction={'column'}
          position="fixed"
          pt={"8%"}
          pb={"10%"}
          borderRadius="32px 32px 0px 0px"
          bottom={0}
          w={'full'}>
          <VStack align={'center'}

          >
            <Heading
              color={"#16161A"}
              fontSize={["xl", "xxl", "3xl", "4xl"]}
              letterSpacing={"-0.53px"}
              mb={'3%'}
            >
              Design your own trip!
            </Heading>
            <Box>
              <InputField
                placeholder='Ask here...' />
            </Box>

          </VStack>
        </Flex>
      </Box>
    </Box>

  );
}
export default TravelAdvisor;