import React, { useContext, useEffect, useState } from "react";
import { Input, InputGroup,
  //  InputRightElement,
    Image, Box, useDisclosure, InputLeftElement,
    //  HStack, Heading, Skeleton
     } from "@chakra-ui/react";
import * as amplitude from '@amplitude/analytics-browser';
import ModalWithBackdrop from "../ModalWithBackdrop";
import SigninComponent from "../SigninComponent";
import { AppContext } from "../../context/AppContext";

interface SearchBarProps {
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [isLoaded, setIsLoaded] = useState(false);
  const [showSuggestedBox, setShowSuggestedBox] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [placeholderText, setPlaceholderText] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const context: any = useContext(AppContext);
  const { prevVideoSearch } = context;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value); // Update the state with the input value
  };

  const handleSearch = () => {
    if (searchTerm.trim())
      onSearch(searchTerm); // Trigger the search when Enter is pressed or icon is clicked
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      amplitude.track('video_search_clicked', { type: "search_query", query_name: searchTerm, page: 'Homepage' });
      handleSearch();
    }
  };

  const suggestions = [
    'Japan travel suggestions',
    'New Zealand in December',
    'Top things to do in Thailand ',
    'Adventure activities in Switzerland',
  ];

  useEffect(() => {
    let currentSuggestionIndex = 0;
    let charIndex = 0;
    let isDeleting = false;
    let timeoutId: NodeJS.Timeout;

    const typeAnimation = () => {
      if (isFocused) {
        setPlaceholderText(""); // Clear placeholder when input is focused
        return; // Stop animation when input is focused
      }

      const currentText = suggestions[currentSuggestionIndex];

      if (!isDeleting && charIndex <= currentText.length) {
        setPlaceholderText(currentText.substring(0, charIndex));
        charIndex++;
      } else if (isDeleting && charIndex > 0) {
        setPlaceholderText(currentText.substring(0, charIndex));
        charIndex--;
      } else {
        isDeleting = !isDeleting;
        if (!isDeleting) {
          currentSuggestionIndex =
            (currentSuggestionIndex + 1) % suggestions.length;
        }
      }

      timeoutId = setTimeout(typeAnimation, isDeleting ? 100 : 150); // Faster deletion, slower typing
    };

    typeAnimation();

    return () => clearTimeout(timeoutId); // Cleanup timeout on unmount or isFocused change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  return (
    <Box w={'100%'}>
      <Box w={'100%'}>
        {/* <HStack>
          <HStack w="80%" justifyContent="flex-start">
            <Heading
              fontFamily="Poppins"
              fontSize="18px"
              fontWeight="700"
              lineHeight="23.94px"
              letterSpacing="0px"
              textAlign="left"
              color="#FFFFFF">
              Wondering where to go next? Let's find out!
            </Heading>
          </HStack>
          <HStack mt={2} w="40%" justifyContent="flex-end" pr={2} mb="-10.1px">
            <Skeleton isLoaded={isLoaded} fadeDuration={0} startColor="transparent" endColor="transparent">
              <Image
                src="/icons/lady.svg"
                alt="asset"
                boxSize="84"
                onLoad={() => setIsLoaded(true)}
              />
            </Skeleton>
          </HStack>

        </HStack> */}

        <InputGroup mt={1} w={'100%'}  onFocus={(e) => {
          if (localStorage.getItem('token')) {
            setShowSuggestedBox(true);
            setIsFocused(true); // Set focus state to true
          }
          else {
            (e.target as HTMLInputElement).blur();
            onOpen();
          }
        }} onBlur={() => {
          setTimeout(() => setShowSuggestedBox(false), 200);
          setIsFocused(false); // Set focus state to false
        }}
        onClick={(e) => e.stopPropagation()}
        >

          <InputLeftElement
            mt={'4px'}
            children={
              <Image
                boxSize={'17px'}
                src={'/icons/reel.png'}
                alt="search"
                cursor="pointer"
                onClick={handleSearch} // Trigger search on icon click
              />
            }
          />
          <Input
            py={'3%'}
            placeholder={placeholderText}
            _placeholder={{ color: "#FFFFFF54" }}
            bg="#242127"
            color="#FFFFFF"
            borderRadius="12px"
            border={'1px solid #FFFFFF12'}
            // boxShadow={'0px 1px 1px rgba(255, 255, 255, 0.32)'}
            h={'48px'}
            // pl={9}
            value={searchTerm} // Controlled input
            onChange={handleInputChange}
            onKeyDown={handleKeyDown} // Trigger search on Enter key press
          />
          {/* <InputRightElement
            py={'25px'}
            mt={'1px'}
            children={
              <Image
                boxSize={'18px'}
                src={'/icons/search2.svg'}
                alt="search"
                cursor="pointer"
                onClick={() => {
                  amplitude.track('video_search_clicked', { type: "search_query", query_name: searchTerm, page: 'Homepage' });
                  handleSearch()
                }} // Trigger search on icon click
              />
            }
          /> */}
        </InputGroup>
        {showSuggestedBox && prevVideoSearch?.length>0 && (
          <Box
            position={"relative"}
            bg="#242127"
            color="gray.200"
            zIndex={99999}
            p={2}
            borderRadius="12px"
            border="1px solid #FFFFFF12"
            maxH="auto"
            overflowY="auto"
          >
            {prevVideoSearch?.map((suggestion: string, index: number) => (
              <Box
                key={index}
                p={2}
                fontSize={'md'}
                onClick={() => {
                  amplitude.track('video_search_clicked', { type: "suggested_query", query_name: suggestion, page: 'Homepage' });
                  setSearchTerm(suggestion);
                  onSearch(suggestion);
                }}
              >
                {suggestion}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SigninComponent onClose={onClose} />
      </ModalWithBackdrop>
    </Box>
  );
};

export default SearchBar;