import {
    Box,
    Image,
    Flex,
    Heading,
    Text,
    Textarea,
    FormControl,
    Button,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import useAxios from "../../hooks/useAxios";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../context/AppContext";
import { useLoading } from "../../context/LoadingContext";

interface ContactUsProps {
    countryName: string;
}

const Query: React.FC<ContactUsProps> = ({
    countryName
}) => {
    const { setIsLoading } = useLoading();

    const { fetchData } = useAxios();
    const [tripDetails, setTripDetails] = useState("");
    const navigate = useNavigate();
    const context: any = useContext(AppContext);
    const { likeEntities, setItinerary } = context;

    const filterEntriesByCountry = (likeEntities: { country?: string, [key: string]: any }[], countryName: string) => {
        return likeEntities
            .filter(entry => entry.country === countryName)
            .map(({ image, ...rest }) => rest);
    };

    const filteredEntries = filterEntriesByCountry(likeEntities, countryName);
    const sendQuery = async (message: string) => {


        // console.log("vsv", JSON.stringify(filteredEntries));
        let body = {
            query: message,
            bucketList: filteredEntries,
        };

        try {
            setIsLoading(true);
            const { data, statusCode } = await fetchData({
                method: "POST",
                url: `ai/trip_planner`,
                options: {
                    data: body,
                    headers: { "Content-Type": "application/json" },
                },
            });
            if (statusCode === 200) {
                if (data.error) {
                    alert(data?.error);
                    setTripDetails(message);
                    setIsLoading(false);
                } else {
                    setItinerary((prevItinerary: any[] = []) => [...prevItinerary, data]);
                    navigate(`/itineraries/${data?.id}`, { state: { itinerary: data } });
                }
            }
            else {
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSend = async () => {
        if (!tripDetails.trim()) {
            alert("Please provide trip details before sending.");
            return;
        }
        else {
            const message = tripDetails;
            sendQuery(message);

            // navigate(`/itineraries/${data?.trip_itinerary?.id}`, { state: { itinerary: data?.trip_itinerary } });
            setTripDetails("");
        }
    };

    return (
        <Box
            width="100%"
            height="100vh"
            backgroundImage="/images/bg_images/Bg_4.png"
            position="relative"
            overflow="hidden"
            backgroundSize="cover"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            p={5}
        >
            <Flex mt={"10%"} mb={"10"} direction="column" gap={0} align={"center"}>
                <Flex direction="row" align={"center"} gap={4}>
                    <Image src="/icons/logo.svg" alt="logo" boxSize={"30px"} />
                    <Text
                        bgGradient="linear-gradient(90deg, #9BA1F2 0%, #F9EEC5 100%)"
                        bgClip={"text"}
                        fontSize={"26px"}
                        fontWeight={700}
                    >
                        Rahee AI
                    </Text>
                </Flex>
                <Text fontWeight={500} fontSize={"11px"} color={"#FFFFFFA1"} mt={"2px"}>
                    Start your journey with our specialised advisors
                </Text>

            </Flex>
            <Flex color={"#FFFFFFD0"} flexDir={"column"} gap={10}>
                <Flex flexDir={'column'} gap={3}>
                    <Heading fontSize={"18px"} mb={4} textAlign={'center'}>
                        Plan Your Perfect Trip with Us
                    </Heading>
                    <Text
                        fontWeight={500}
                        fontSize={"12px"}
                        color={"#FFFFFFD1"}
                        textAlign={"left"}
                    >
                        Example: Create a <b>5 day</b> itinerary to <b>Las Vegas</b> in <b>March</b>. I am planning to go with my <b>2 friends</b> for a luxury vacation. Our budget is <b>2,000,000 INR</b>.".
                    </Text>
                    <Text
                        fontWeight={500}
                        fontSize={"15px"}
                        color={"#FFFFFFD1"}
                        mt={"2px"}
                        textAlign={"left"}
                    >
                        Write your query to create a customised itinerary
                    </Text>
                    <FormControl>
                        <Textarea
                            fontSize={"13px"}
                            textAlign={"left"}
                            p={3}
                            color={"#FFFFFFC1"}
                            border={"1px solid grey"}
                            placeholder="Example: Create a 5 day itinerary to Las Vegas in March. I am planning to go with my 2 friends for a luxury vacation. Our budget is 2,000,000 INR."
                            value={tripDetails}
                            onChange={(e) => setTripDetails(e.target.value)}
                            _placeholder={{ fontSize: "12px" }}
                        />

                        <Button
                            fontSize={['15px', "19px", "23px"]}
                            mt={'4%'}
                            w={'40%'}
                            py={"20px"}
                            bg={"linear-gradient(90deg, #ACAFEB 0%, #E9E1CD 100%)"}
                            borderRadius={'100px'}
                            onClick={handleSend}
                        >
                            Generate
                        </Button>
                    </FormControl>
                </Flex>

            </Flex>

        </Box>
    );
};

export default Query;
