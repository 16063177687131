import { Heading, IconButton, VStack, Text, Image, SimpleGrid, Box, Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import SearchBar from "../components/SearchComponents/SearchBar";
import { useContext } from "react";
import { AppContext } from "../context/AppContext";
import useSmartNavigate from "../hooks/useSmartNavigate";

const Trending: React.FC = () => {
    const navigate = useSmartNavigate();
    const location = useLocation();

    const context: any = useContext(AppContext);
    const { trendingVideos, setSearchDetail, setCurrentVideoIndex } = context;


    const handleSearchInputBlur = async (query: any) => {
        localStorage.setItem('query', query);
        if (location.pathname === '/trending') {
            return navigate(`/video_search?query=${query}`);
        }
    };

    const handleCardClick = async (index: number) => {
        await setCurrentVideoIndex(index);
        await setSearchDetail({ "videos": trendingVideos });
        navigate('/video_search');
    };

    return (
        <Flex
            direction="column"
            bg="#000000"
            h="100vh"
            maxH="100vh"
            w="100%"
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            overflow="hidden"
        >
            <Box
                bg="linear-gradient(180deg, #252128 0%, rgba(36, 29, 50, 0) 100%)"
                position={"absolute"}
                top={0}
                left={0}
                right={0}
                bottom={0}
            />
            {/* Fixed Header */}
            <Box w="100%" px={3} zIndex={2}>
                <VStack alignItems={'flex-start'} justifyContent={'flex-start'} w={'100%'}>
                    <IconButton
                        mt={4}
                        ml={"-2"}
                        aria-label="Back"
                        icon={<Image
                            src="/icons/Back_Icon_White.svg"
                            alt="back"
                            filter={"invert(55%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(90%) contrast(95%)"}
                            boxSize={5}
                        />
                        }
                        variant="ghost"

                        onClick={() => navigate('/')}
                    />
                    <Heading
                        fontFamily="Poppins"
                        fontWeight="700"
                        fontSize="14px"
                        lineHeight="133%"
                        letterSpacing="0px"
                        color={'#FFFFFF'}
                    >
                        Find inspiration for your next vacation
                    </Heading>
                </VStack>
                <VStack w={'100%'} justifyContent={'center'} mt={"16px"}>
                    <SearchBar onSearch={(value?: any) => handleSearchInputBlur(value)} />
                    <VStack
                        mt={"5"}
                        alignItems={'flex-start'}
                        w={'100%'}
                    >
                        <Text
                            fontFamily="Poppins"
                            fontWeight="500"
                            fontSize="13px"
                            lineHeight="133%"
                            letterSpacing="0px"
                            color={'#FFFFFFA1'}
                        >
                            Trending videos shared by users like you
                        </Text>

                        <Box
                            mt={1}
                            w={'full'}
                            borderTop={'1px solid #FFFFFF54'}
                        />
                    </VStack>
                </VStack>
            </Box>

            {/* Scrollable Content Area */}
            <Box
                zIndex={2}
                flex="1"
                overflowY="auto"
                px={3}
                mt={3}
                css={{
                    '&::-webkit-scrollbar': {
                        width: '4px',
                    },
                    '&::-webkit-scrollbar-track': {
                        width: '6px',
                        background: '#0a0a0a',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#333',
                        borderRadius: '24px',
                    },
                    // Important: Explicitly prevent parent scrolling
                    'scrollbarWidth': 'thin',
                    'msOverflowStyle': 'none',
                }}
            >
                <SimpleGrid columns={[2, 2, 6]} spacing={'20px'} mx={1} pb={8}>
                    {trendingVideos?.map((video: any, index: number) => (
                        <VStack key={index}>
                            <Box position={'relative'}
                                w={"158px"}
                                onClick={() => {
                                    handleCardClick(index);
                                }}
                            >
                                <Image
                                    src={video?.preview_image}
                                    alt={video?.title}
                                    borderRadius={'18px'}
                                    h={'190px'}
                                    w={"158px"}
                                    objectFit={'cover'}
                                />
                                <Image
                                    src="/icons/play.svg"
                                    position={'absolute'}
                                    top={'50%'}
                                    left={'50%'}
                                    transform={'translate(-50%, -50%)'}
                                    w={'40px'}
                                    h={'40px'}
                                />
                            </Box>
                            <Text
                                fontFamily="Poppins"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="23px"
                                letterSpacing="0%"
                                verticalAlign="middle"
                                color={'#FFFFFFED'}
                                noOfLines={2}
                                mt={3}
                                w={"158px"}
                            >
                                {video?.title}
                            </Text>
                        </VStack>
                    ))}
                </SimpleGrid>
            </Box>
        </Flex>
    );
}

export default Trending;