import { Box, Flex, useDisclosure, useMediaQuery } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import useAuthRouteProtector from '../../hooks/useAuthRouteProtector';
import Header from '../Header';
import ModalWithBackdrop from '../ModalWithBackdrop';
import SigninComponent from '../SigninComponent';
import ShareSocialsModal from '../ShareSocialsModal';
import 'react-multi-carousel/lib/styles.css';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import useAxios from '../../hooks/useAxios';

function NewAppLayout() {
    const context: any = useContext(AppContext);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isAuthenticated, loading } = useAuthRouteProtector();
    const location = useLocation();
    const navigate = useNavigate();
    const { setLikeEntities, setLikeSearches, setCityImage, setItinerary, setTrendingVideos } = context;
    const { fetchData } = useAxios();
    const [showHeader, setShowHeader] = useState(true);
    const [once, setOnce] = useState(1)

    // Media query to check if the screen width is larger than 800px
    const [isLargerThan800] = useMediaQuery('(min-width: 800px)');

    useEffect(() => {
        // Hide header on mobile devices (screens smaller than 800px) and pages other than '/'
        const isMobileAndNotHomePage =
            !isLargerThan800 && location.pathname !== '/';
        setShowHeader(!isMobileAndNotHomePage);
    }, [location.pathname, isLargerThan800]);

    const getTrendingVideos = async () => {

        const { data, statusCode } = await fetchData({
            method: "POST",
            url: `get_trending_videos`,
            options: {
                data: {
                    // token: localStorage.getItem("token")
                },
                headers: { "Content-Type": "application/json" },
            },
            auth: false,
        });
        if (statusCode === 200 && data) {
            const filteredData = {
                videos: data.trending_videos.filter(
                    (video: any) => video.title && video.video_url
                ),
            };
            setTrendingVideos(filteredData?.videos)
            console.log("filteredData", filteredData);
        }
    };

    useEffect(() => {
        getTrendingVideos();
        // eslint-disable-next-line        
    }, []);


    useEffect(() => {
        const getUserLikeSearches = async () => {
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `user/getUserLikeSearches`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                const like_searches_data = data["data"];
                const like_searches = like_searches_data["like_searches"];
                const city_image = like_searches_data['city_image'];
                setLikeSearches(like_searches);
                setCityImage(city_image);
                return like_searches;
            } catch (error) {
                console.error("Error fetching user like searches:", error);
                return [];
            }
        };

        const getUserLikeEntities = async () => {
            try {
                const { data } = await fetchData({
                    method: "POST",
                    url: `user/getUserLikeEntities`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });

                const like_entities_data = data["data"];
                const like_entities = like_entities_data["like_entities"];
                const city_image = like_entities_data['city_image'];

                setCityImage((prevCityImage: any) => ({ ...prevCityImage, ...city_image }));
                setLikeEntities(like_entities);
                return like_entities;
            } catch (error) {
                console.error("Error fetching user like entities:", error);
                return [];
            }
        };
        const getItinerary = async () => {
            try {
                const { data, statusCode } = await fetchData({
                    method: "POST",
                    url: `ai/v1/get_saved_itineraries`,
                    options: {
                        data: { token: localStorage.getItem("token") },
                        headers: { "Content-Type": "application/json" },
                    },
                    auth: false,
                });
                if (statusCode === 200) {
                    const itineraries = data;
                    setItinerary(itineraries);
                }

            } catch (error) {
                console.error("Error fetching user like entities:", error);
                return [];
            }
        };




        if (localStorage.getItem('token') && once) {
            getUserLikeSearches();
            getUserLikeEntities();
            getItinerary();

            setOnce(0);
            console.log("logging.....")
        }


    }, [fetchData, setLikeEntities, setLikeSearches, setCityImage, setItinerary, once]);


    if (loading) {
        return <div>Loading...{isAuthenticated}</div>;
    }


    return (
        <Flex direction="column">
            <ModalWithBackdrop
                isOpen={isOpen}
                onClose={() => {
                    onClose();
                    navigate('/');
                }}
            >
                <SigninComponent onClose={onClose} />
            </ModalWithBackdrop>
            <ShareSocialsModal />
            {/* Conditionally render Header based on screen size */}
            {/* {isLargerThan800 && showHeader && (
                <Box zIndex="banner" position="fixed" w="100%" top={0}>
                    <Header onOpenLogin={onOpen} isAuthenticated={isAuthenticated} />
                </Box>
            )} */}
            <Outlet />
        </Flex>
    );
}

export default NewAppLayout;
