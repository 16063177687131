import {
  Box,
  Flex,
  Heading,
  HStack,
  SkeletonText,
  VStack,
  Text,
  Image,
  Button,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import { Swiper, SwiperSlide } from "swiper/react";
import Header from "../TravelAdvisor/Header";
import { formatDateTime } from "../../utilities/dateTimeFormat";
import InputField from "./InputField";
// import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useRef } from "react";
import { AppContext } from "../../context/AppContext";
import "swiper/css";
import "swiper/css/effect-cards";
import "./styles.css";
import * as amplitude from "@amplitude/analytics-browser";

// import required modules
import { EffectCards } from "swiper/modules";
import TripCard from "./TripCard";
import useTripService from "../../services/tripService";
import ModalWithBackdrop from "../ModalWithBackdrop";
import SelectItem from "./SelectItem";
import TravelForm from "./TravelForm";
import ChatCard from "../TravelAdvisor/ChatCard";
import { useLoading } from "../../context/LoadingContext";
import { fetchEntityDetails } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import useSmartNavigate from "../../hooks/useSmartNavigate";

const Conversation: React.FC = () => {
  // const { navigateSmart } = useNavigationHistory();
  const router = useNavigate();
  const navigate = useSmartNavigate();
  const { fetchConversationResponse } = useTripService();
  const context: any = useContext(AppContext);
  const { previousChat, isChatLoading, conversationId, title, itineraryQuery,
    setPreviousChatLoading,
    entitesDetail,
    setEntitesDetail,
    setInfluencerData,
    userDetail,
    setStryIndex,
    setTitle,
    setOnBehalf,
    setConversationId,
    setPreviousChat,
    setItineraryQuery,
    likeSearches,
    likeEntities,
  } =
    context;
  const bottomRef = useRef<HTMLDivElement>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { setIsLoading } = useLoading();
  const handleChatButton = () => {
    navigate("/navigate", {
      state: { index: 3 }
    });
  };

  useEffect(() => {
    // Scroll to bottom when previousChat change
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [previousChat]);

  const items = [
    "8 day trip to Turkey to celebrate my anniversary",
    "3 day trip to Paris for sightseeing",
    "Weekend getaway to Bali",
  ];

  const handleNewChatButton = () => {
    setItineraryQuery([])
    setPreviousChat([]);
    setTitle("");
    setConversationId("");
    setOnBehalf({});
    navigate("/conversation");
  };

  const checkVisibility = () => {
    const country =
      itineraryQuery[itineraryQuery?.length - 1]?.meta?.destination_country;
    const countryMatch = likeEntities?.some((likeEntity: any) => likeEntity.country === country);
    const searchMatch = likeSearches?.some((likeSearch: any) => country in likeSearch.countries);

    if (countryMatch || searchMatch) {
      return true;
    }
    return false;
  };

  const handleCardClick = async (
    clickedIndex: number,
    clickedTitle: string,
    allEntities: any[],
    entity: any
  ) => {
    setStryIndex(null);
    const secondLastMessage = previousChat[previousChat.length - 2]?.text;
    const lastMessage = previousChat[previousChat.length - 1]?.text;
    const contxt = {
      query: secondLastMessage,
      answer: lastMessage,
    };
    // Fetch entity details for the clicked title
    console.log("clickedTitle is:", clickedTitle);



    const curr = entitesDetail;

    if (curr.length > 0) {
      const allMatch = allEntities.every((entity: any, index: number) => {
        console.log("entity title ", entity.title);
        console.log("curr title index ", curr[index]?.title);

        return entity.title === curr[index]?.title;
      });
      console.log("Entity detail in chat is ", entitesDetail);

      if (allMatch) {
        console.log("matched");
        const initialEntityDetail = allEntities.find(
          (entity) => entity.title === clickedTitle
        );
        setPreviousChatLoading(true);
        navigate("/story-carousel", {
          state: {
            clickedIndex: clickedIndex,
            initialEntityDetail: initialEntityDetail,
            allEntities: allEntities,
            itinerary: true
          }
        });
      } else {
        console.log("not matched");
        setInfluencerData([]);
        setIsLoading(true);
        setEntitesDetail([]);
        try {
          console.log("userDetail not matched", userDetail);

          const initialEntityDetail = await fetchEntityDetails(
            clickedTitle,
            userDetail,
            entity?.city ?? "",
            entity?.country ?? "",
            contxt,
            entity?.highlight_id ?? "",
            entity?.place_metadata_id ?? "",
            entity?.travel_destination ?? ""
          );

          // console.log("response is:", JSON.stringify(initialEntityDetail.data));
          setPreviousChatLoading(true);
          navigate("/story-carousel", {
            state: {
              clickedIndex: clickedIndex,
              initialEntityDetail: initialEntityDetail.data.entity,
              allEntities: allEntities,
              itinerary: true
            }
          });
        } catch (error) {
          console.error("Error fetching entity details:", error);
        } finally {
          setIsLoading(false);
        }
      }
    } else {
      console.log("new one");
      setIsLoading(true);
      try {
        setInfluencerData([]);
        setEntitesDetail([]);
        console.log("userDetail new one", userDetail);

        const initialEntityDetail = await fetchEntityDetails(
          clickedTitle,
          userDetail,
          entity?.city ?? "",
          entity?.country ?? "",
          contxt,
          entity?.highlight_id ?? "",
          entity?.place_metadata_id ?? "",
          entity?.travel_destination ?? ""
        );

        // console.log("response is:", JSON.stringify(initialEntityDetail.data));
        setPreviousChatLoading(true);
        navigate("/story-carousel", {
          state: {
            clickedIndex: clickedIndex,
            initialEntityDetail: initialEntityDetail.data.entity,
            allEntities: allEntities,
            itinerary: true
          }
        });
      } catch (error) {
        console.error("Error fetching entity details:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <Flex bg={"#FFFFFF"} h="100%">
      {/* header and all */}
      <Box
        bg={"#FFFFFF"}
        position={"fixed"}
        top={0}
        left={0}
        w={"100%"}
        zIndex={5}
      >
        <Header
          backIconSrc="/icons/arrow_back.svg"
          chatIconSrc="/icons/chat_filled.svg"
          addButtonOnClick={handleNewChatButton}
          addIconSrc="/icons/plus_outline.svg"
          handleBackButton={() => {
            router(-1);
          }}
          chatButtonOnClick={handleChatButton}
          bgColor="#2F2F320D"
          borderColor="linear-gradient(135deg, #FF7E5F, #FEB47B)"
        />

        {/* Chat Topic Bar */}
        <Flex
          borderTop={"1px solid #3A3C4221"}
          mt={"5px"}
          pt={"8px"}
          pb={"7px"}
          pl={"18px"}
          boxShadow={"0px 7px 7px 0px #00000008"}
        >
          <Text
            fontSize={"11px"}
            fontFamily={"500"}
            letterSpacing={"-0.47px"}
            color={"#16181BA1"}
            noOfLines={1}
          >
            {title}
          </Text>
        </Flex>
      </Box>
      <Box bg={"#FFFFFF"} position={"fixed"} w="100%" h="100%">
        <Box
          flex={1}
          mt="18%"
          overflowY="auto"
          pt={4}
          pb={"13%"}
          // mb={"2%"}
          maxH={"82%"}
        // maxH="calc(100vh - 21vh)"
        >
          <Box mt={"20px"}>
            {previousChat?.map((entry: any, index: number) => (
              <Flex
                justifyContent={
                  entry?.author === "user" ? "flex-end" : "flex-start"
                }
                key={index} // Added key here
                mb={2} // Margin between entries
              >
                {isChatLoading &&
                  entry?.author === "assistant" &&
                  entry?.text === "..." ? (
                  <SkeletonText
                    height="40px"
                    width="40%"
                    borderRadius="8px"
                    p={"10px"}
                    pb={"50px"}
                    noOfLines={3}
                  />
                ) : (
                  <Flex direction={"column"} maxW={"100vw"} overflow={"hidden"}>
                    {/* For the Text */}

                    <Flex
                      mr={"12px"}
                      ml={"12px"}
                      // flexDir="column"
                      justifyContent={
                        entry.author === "user" ? "flex-end" : "flex-start"
                      }
                    >
                      <Box
                        borderRadius={"8px"}
                        maxW={"227px"}
                        mx={1}
                        bg={
                          entry.author === "assistant" ? "#E8E9FF" : "#E0F0EA"
                        }
                      >
                        {entry.onBehalf && (
                          <HStack
                            borderRadius={"9px"}
                            bg={"#F9FAFC"}
                            h="72px"
                            w={"100%"}
                          >
                            <Image
                              src={entry.onBehalf.imageUrl}
                              alt={entry.onBehalf.title}
                              borderRadius={"12px 0px 0px 12px"}
                              objectFit={"cover"}
                              h={"72px"}
                              w={"35%"}
                            />
                            <Box py={"32px"} color={"#424242"} w={"65%"}>
                              <VStack
                                spacing={0}
                                alignItems={"flex-start"}
                                ml={"2%"}
                                mt={0}
                              >
                                <Heading
                                  fontSize="12px"
                                  letterSpacing={"-0.5px"}
                                  fontWeight={700}
                                  noOfLines={2}
                                >
                                  {entry.onBehalf.title}
                                </Heading>
                                <Heading
                                  fontSize={"11px"}
                                  letterSpacing={"-0.5px"}
                                  fontWeight={400}
                                >
                                  {entry.onBehalf.subtitle}
                                </Heading>
                              </VStack>
                            </Box>
                          </HStack>
                        )}
                        {entry?.text && (
                          <>
                            <Text
                              fontFamily={"Poppins"}
                              color={"#16181B"}
                              fontSize={"14px"}
                              py={"10px"}
                              px={"12px"}
                              lineHeight={"19px"}
                              fontWeight={700}
                            // textAlign={"right"}
                            >
                              {entry?.text}
                            </Text>

                          </>
                        )}
                        {/* {entry?.metadata && (
                          Object?.keys(entry?.metadata)?.length !== 0 && entry?.author === "user" && (
                            <Box>
                              {entry?.metadata?.fields_input && Object?.entries(entry?.metadata?.fields_input)?.map(([key, value]) => {
                                if (key === "type_of_activities" && (value as any)?.selected) {
                                  return (
                                    <Box key={key} px={"12px"} w="100%">
                                      <Text
                                        fontFamily="Poppins"
                                        color="#16181B"
                                        fontSize="12px"
                                        fontWeight={700}
                                        textTransform="uppercase"
                                        letterSpacing="0.5px"
                                        mb={1}
                                      >
                                        {key?.charAt(0)?.toUpperCase() + key?.slice(1)?.replace(/_/g, " ")}:
                                      </Text>
                                      {((value as { selected: string[] })?.selected || [])?.map((activity: string) => (
                                        <Text
                                          key={activity}
                                          fontFamily="Poppins"
                                          color="#16181B"
                                          fontSize="14px"
                                          fontWeight={500}
                                          lineHeight="1.4"
                                          pb={1}
                                        >
                                          {activity}
                                        </Text>
                                      ))}
                                    </Box>
                                  );
                                } else {
                                  return (
                                    <Box key={key} px={"12px"} w="100%">
                                      <Text
                                        fontFamily="Poppins"
                                        color="#16181B"
                                        fontSize="12px"
                                        fontWeight={700}
                                        textTransform="uppercase"
                                        letterSpacing="0.5px"
                                        mb={1}
                                      >
                                        {key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ")}:
                                      </Text>
                                      <Text
                                        fontFamily="Poppins"
                                        color="#16181B"
                                        fontSize="14px"
                                        fontWeight={500}
                                        lineHeight="1.4"
                                        pb={1}
                                      >
                                        {value as string}

                                      </Text>
                                    </Box>
                                  );
                                }
                              })}
                            </Box>
                          )
                        )} */}
                        {entry?.query_completed && (
                          <>
                            <Box w={"100%"} borderRadius={"8px"} mb={3}>
                              {/* <Divider borderColor="white" mt={1} /> */}
                              <VStack w={"100%"} spacing={1} px={4}>
                                {(likeEntities?.length > 0 || likeSearches?.length > 0) && checkVisibility() && (
                                  <Button
                                    bg={"#16181B"}
                                    mt={"20pixels"}
                                    w={"100%"}
                                    onClick={() => {
                                      onOpen();
                                    }}
                                  >
                                    <Text
                                      fontFamily="Poppins"
                                      fontWeight="600"
                                      fontSize="14px"
                                      lineHeight="17px"
                                      letterSpacing="-0.47px"
                                      color={"#FFFFFF"}
                                    >
                                      Select Items
                                    </Text>
                                  </Button>)}
                                <Button
                                  w={"100%"}
                                  bg={"#16181B"}
                                  mt={"20pixels"}
                                  onClick={() => {
                                    amplitude.track("button_clicked", {
                                      buttonName: "Generate",
                                      itinerary_query:
                                        itineraryQuery[
                                        itineraryQuery?.length - 1
                                        ],
                                    });
                                    fetchConversationResponse("Confirm", true);
                                  }}
                                >
                                  <Text
                                    fontFamily="Poppins"
                                    fontWeight="600"
                                    fontSize="14px"
                                    lineHeight="17px"
                                    letterSpacing="-0.47px"
                                    color={"#FFFFFF"}
                                  >
                                    Generate
                                  </Text>
                                </Button>
                              </VStack>
                            </Box>
                          </>
                        )}
                      </Box>
                    </Flex>
                    {entry?.type === "itinerary" && (
                      <Box py="12px" ml={"10%"} w={"100%"} minW={"80dvw"}>
                        <Swiper
                          effect={"cards"}
                          grabCursor={true}
                          modules={[EffectCards]}
                          className="mySwiper"
                          style={{ backgroundColor: "transparent !important" }}
                        >
                          {entry?.itineraries?.map(
                            (entity: any, index: number) => (
                              <SwiperSlide
                                key={index}
                                style={{
                                  background: "transparent !important",
                                  boxShadow: "none !important",
                                }}
                              >
                                <TripCard
                                  image={entity?.plan?.trip_itinerary?.image}
                                  tripName={entity?.plan?.trip_itinerary?.title}
                                  description={
                                    entity?.plan?.trip_itinerary?.description
                                  }
                                  date={entity?.plan?.trip_itinerary?.dates}
                                  price={
                                    entity?.plan?.budget_breakdown?.totalINR
                                  }
                                  onCardClick={() => {
                                    console.log("Card ", entry);
                                    entity.plan.message_id = entry?._id;
                                    entity.plan.conversation_id =
                                      conversationId;
                                    navigate(
                                      `/itineraries/${entity?.plan?.id}`,

                                      {
                                        state: {
                                          itinerary: entity?.plan
                                        }
                                      }
                                    );
                                  }}
                                />
                              </SwiperSlide>
                            )
                          )}
                        </Swiper>
                      </Box>
                    )}
                    {entry?.type && entry?.type === "entity" && (
                      <Box pt="12px" pb={"12px"} w={"100%"} minW={"80dvw"}>
                        <Swiper
                          slidesPerView={"auto"}
                          loop={true}
                          breakpoints={{
                            320: {
                              slidesPerView: 1.2,
                            },
                            350: {
                              slidesPerView: 1.3,
                            },
                            360: {
                              slidesPerView: 1.35,
                            },
                            375: {
                              slidesPerView: 1.4,
                            },
                            400: {
                              slidesPerView: 1.5,
                            },
                            425: {
                              slidesPerView: 1.6,
                            },
                            480: {
                              slidesPerView: 1.7,
                            },
                            540: {
                              slidesPerView: 2.1,
                            },
                            640: {
                              slidesPerView: 3.2,
                            },
                            768: {
                              slidesPerView: 2.2,
                            },
                            1024: {
                              slidesPerView: 2.8,
                            },
                            1280: {
                              slidesPerView: 3.2,
                            },
                            1536: {
                              slidesPerView: 3.6,
                            },
                            1920: {
                              slidesPerView: 4,
                            },
                          }}
                        >
                          {entry.entities?.map((entity: any, index: number) => (
                            <SwiperSlide key={index}>
                              <ChatCard
                                imageSrc={entity.image}
                                altText=""
                                city={entity.city}
                                country={entity.country}
                                title={entity.title.replace(/<[^>]+>/g, "")}
                                buttonText="View Details"
                                onCardClick={() =>
                                  handleCardClick(
                                    index,
                                    entity.title,
                                    entry.entities?.map((h: any) => ({
                                      title: h.title,
                                      images: h.image,
                                      city: h?.city ?? "",
                                      country: h?.country ?? "",
                                      _id: entry?._id,
                                      conversation_id: conversationId,
                                      place_metadata_id:
                                        h?.place_metadata_id ?? "",
                                      search_query: h?.search_query ?? "",
                                      travel_destination:
                                        h?.travel_destination ?? "",
                                    })) || [],
                                    entity
                                  )
                                }
                              />
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </Box>
                    )}
                    <Flex
                      justifyContent={
                        entry?.author === "user" ? "flex-end" : "flex-start"
                      }
                    >
                      <Text
                        color={"#B5B5B5"}
                        fontSize={"11px"}
                        mt={entry?.type === "itinerary" ? "-4px" : "10px"}
                        mx={1}
                        mr={"14px"}
                        ml={"14px"}
                        letterSpacing={"-0.47px"}
                      >
                        {formatDateTime(entry?.time)}
                      </Text>


                    </Flex>

                    {entry?.query_completed === false && <TravelForm message_id={entry?._id} />}
                    {entry?.text?.includes("Recommendations generated! Optimising routes to create a perfect trip plan now") && (
                      <SkeletonText
                        height="40px"
                        width="100%"
                        borderRadius="8px"
                        p={"10px"}
                        pb={"50px"}
                        noOfLines={3}
                      />)}
                  </Flex>
                )}
              </Flex>
            ))}
            <div ref={bottomRef} />
          </Box>
          {previousChat?.length === 0 && (
            <Flex
              mt={"14px"}
              h="70vh"
              justifyContent="center"
              alignItems="center"
            >
              <Image boxSize={"300px"} src={"/images/Food.svg"} alt="Icon" />
            </Flex>
          )}
        </Box>
      </Box>

      {/* Input Field */}
      <Box position={"fixed"} bottom={"10px"} bg={"white"} py={3} w={"full"}>
        {previousChat.length === 0 && (
          <VStack mb={2} alignItems={"flex-start"} spacing={2} pl={3}>
            <Text
              fontFamily="Poppins"
              fontWeight="600"
              fontSize="14px"
              lineHeight="18.62px"
              letterSpacing="-0.63px"
              textAlign="left"
              color={" #16181BA1"}
            >
              Get started with these...
            </Text>
            <Flex w="100%" overflow="hidden" mb={4}>
              <Swiper slidesPerView="auto" spaceBetween={8}>
                {items?.map((item, index) => (
                  <SwiperSlide key={index} style={{ width: "auto" }}>
                    <VStack
                      bg="#D1FEEB"
                      borderRadius="4px"
                      px={4}
                      py={2}
                      maxW={"200px"}
                      minH={"60px"}
                      cursor="pointer"
                      justifyContent={"center"}
                      onClick={() => fetchConversationResponse(item)}
                    >
                      <Text
                        fontFamily="Poppins"
                        fontWeight="500"
                        fontSize="12px"
                        letterSpacing="-0.5px"
                        color="#16181B"
                        // whiteSpace="nowrap"
                        noOfLines={3}
                      >
                        {item}
                      </Text>
                    </VStack>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Flex>
          </VStack>
        )}
        <Box pl={3} pr={3}>
          <InputField />
        </Box>
      </Box>
      <ModalWithBackdrop isOpen={isOpen} onClose={onClose}>
        <SelectItem handleClose={onClose} />
      </ModalWithBackdrop>
    </Flex>
  );
};
export default Conversation;
