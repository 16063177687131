import { VStack, Text, Button, Flex, Editable, EditablePreview, EditableTextarea } from "@chakra-ui/react";
import PhoneInput from 'react-phone-number-input';

interface DetailProps {
  label: string;
  value: string;
  buttonText: string;
  editMode?: boolean;
  onEditClick: () => void;
  onChange?: (value: React.ChangeEvent<HTMLInputElement> | string) => void;
}

const Detail: React.FC<DetailProps> = ({ label, value, buttonText, editMode, onEditClick, onChange }) => {
  return (
    <VStack
      borderRadius="12px"
      mx="30px"
      py="18px"
      px="30px"
      w="360px"
      mb={"10px"}
      minH={'73px'}
      border="1px solid #FFFFFF12"
      alignItems="space-between"
      maxW="100vw"
      zIndex={5}
    >
      <Flex justifyContent="space-between" width="100%">
        <Flex flexDir="column" flex="1">
          <Text
            fontFamily="Poppins"
            fontSize="13px"
            fontWeight="500"
            lineHeight="20px"
            letterSpacing="-0.465063214302063px"
            textAlign="left"
            color="#FFFFFF54"
          >
            {label}
          </Text>
          {editMode ? (
            label.toLowerCase() === "phone" ? (
              <PhoneInput
                style={{
                  border: 'none',
                  height: '44px',
                }}
                placeholder=''
                value={value}
                onChange={(newPhone: string) => {
                  if (onChange) onChange(newPhone);
                }}
                defaultCountry='IN'
              />
            ) : (
              <Editable defaultValue={value?.length === 0 && label === "Tell us what kind of a traveller you are" ? "" : value} onSubmit={(nextValue) => onChange && onChange(nextValue)}>
                <EditablePreview

                  alignItems="flex-start"
                  width="full"
                  fontFamily="Poppins"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="17px"
                  letterSpacing="-0.47px"
                  textAlign="left"
                  color="black"
                  pl={2}
                  bgColor="white"
                  border="none"
                  mt={'5px'}
                  minH={'30px'}
                />
                <EditableTextarea
                  alignItems="flex-start"
                  width="full"
                  fontFamily="Poppins"
                  fontSize="12px"
                  fontWeight="500"
                  lineHeight="17px"
                  letterSpacing="-0.47px"
                  textAlign="left"
                  color="black"
                  pl={2}
                  bgColor="white"
                  border="none"
                  mt={'5px'}
                  minH={'30px'}
                // placeholder={label === "Tell us what kind of a traveller you are" ? 'Solo traveller, enjoy hiking in nature. My ideal trip is full of local culture and foodie experiences' : undefined}
                />
              </Editable>
            )
          ) : (
            <Text
              fontFamily="Poppins"
              fontSize="13px"

              fontWeight="500"
              lineHeight="20px"
              letterSpacing="-0.47px"
              textAlign="left"
              color={label === "Tell us what kind of a traveller you are" && !value
                ? "#bcbcbced" : "#FFFFFFED"}
              maxW={"250px"}
              mt={'5px'}
              isTruncated={label !== "Tell us what kind of a traveller you are"}
            >
              {label === "Tell us what kind of a traveller you are" && !value
                ? "Love exploring big cities—cool cafes, museums, and nightlife. I usually travel solo or with close friends; prefer unique stays over fancy hotels"
                : value}
            </Text>
          )}
        </Flex>
        <Button
          w="23px"
          h="17px"
          variant="link"
          fontFamily="Poppins"
          fontSize="13px"
          fontWeight="500"
          lineHeight="17px"
          letterSpacing="-0.465063214302063px"
          textAlign="left"
          color="#AE7CFF"
          onClick={onEditClick}
        >
          {buttonText}
        </Button>
      </Flex>
    </VStack>
  );
};

export default Detail;