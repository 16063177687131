import React, { createContext, useContext } from "react";
import { useMediaQuery } from "@chakra-ui/react";

// Context type definition
interface MobileOnlyContextType {
  isMobile: boolean;
}

// Create a context
const MobileOnlyContext = createContext<MobileOnlyContextType | undefined>(undefined);

// Provider for the context
export const MobileOnlyProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <MobileOnlyContext.Provider value={{ isMobile }}>
      {children}
    </MobileOnlyContext.Provider>
  );
};

// Custom hook to access the context
export const useMobileOnly = () => {
  const context = useContext(MobileOnlyContext);
  if (!context) {
    throw new Error("useMobileOnly must be used within a MobileOnlyProvider");
  }
  return context;
};
